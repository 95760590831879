
import { calcLevelRezilio } from './calc_level_rezilio';


export const rezilio_data = {

    rezilio: {
        label: "Rezilio",
        levelType: "REZILIO",
        levelFn: calcLevelRezilio,
        exercises: {

            rezilio_erzesek1: {
                label: "Érzések",
                level: 1,
                eda: true,
                type: "FeedbackSlider",
                params: {
                    text: "Hogy érzed magad most?",
                    textLeft: "Teljesen ellazult vagyok",
                    textRight: "Kifejezetten feszült vagyok",

                },

            },

            rezilio_darts_gyakorlas: {
                label: "Darts játék - gyakorlás",
                level: 2,
                eda: true,
                type: "WaveBasic",
                params: {
                    duration: 900000,
                    backgroundImage: "/media/rezilio/bamboo.jpg",
                    // backgroundSound: "/media/rezilio/intro.mp3",
                    continueOnZero: true,
                    hideWaves: true,
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },

            },

            rezilio_darts_verseny: {
                label: "Darts verseny",
                level: 3,
                eda: true,
                type: "WaveBasic",
                params: {
                    duration: 900000,
                    backgroundImage: "/media/rezilio/bamboo.jpg",
                    // backgroundSound: "/media/rezilio/intro.mp3",
                    continueOnZero: true,
                    hideWaves: true,
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },

            },

            // rezilio_bevezetes: {
            //     label: "Bevezetés",
            //     level: 2,
            //     eda: true,
            //     type: "WaveBasic",
            //     params: {
            //         duration: 68000,
            //         backgroundImage: "/media/rezilio/bamboo.jpg",
            //         backgroundSound: "/media/rezilio/intro.mp3",
            //         continueOnZero: true,
            //         hideWaves: true,
            //         point_mult: 1,
            //         l_incr: 1,
            //         l_decr: 1,
            //         r_incr: 1,
            //         r_decr: 1
            //     },

            // },

            rezilio_relax: {
                label: "Relaxációs gyakorlat",
                level: 4,
                eda: true,
                type: "WaveBasic",
                params: {
                    duration: 270000,
                    backgroundImage: "/media/rezilio/hands.jpg",
                    backgroundSound: "/media/rezilio/relaxacio.mp3",
                    continueOnZero: true,
                    hideWaves: true,
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },

            },

            rezilio_erzesek2a: {
                label: "Érzések 2a",
                level: 5,
                eda: true,
                type: "FeedbackSlider",
                params: {
                    text: "Hogy érzed magad most?",
                    textLeft: "Teljesen ellazult vagyok",
                    textRight: "Kifejezetten feszült vagyok",

                },
            },

            rezilio_erzesek2b: {
                label: "Érzések 2b",
                level: 6,
                eda: true,
                type: "FeedbackSlider",
                params: {
                    text: "Mennyire volt hasznos ez a gyakorlat az érzelmi állapotod szabályozásához?",
                    textLeft: "Egyáltalán  nem volt hasznos",
                    textRight: "Nagyon hasznos volt",

                },

            },

            rezilio_erzesek2c: {
                label: "Érzések 2c",
                level: 7,
                eda: true,
                type: "FeedbackSlider",
                params: {
                    text: "Mennyire valószínű, hogy használnád ezt a  gyakorlatot az érzelmi állapotod szabályozásához máskor is?",
                    textLeft: "Egyáltalán nem valószínű",
                    textRight: "Nagyon valószínű",

                },

            },

            rezilio_ebertudat: {
                label: "Éber tudat gyakorlat",
                level: 8,
                eda: true,
                type: "WaveBasic",
                params: {
                    duration: 152000,
                    backgroundImage: "/media/rezilio/bamboo.jpg",
                    backgroundSound: "/media/rezilio/mindfulness_1.mp3",
                    continueOnZero: true,
                    hideWaves: true,
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },

            },


            rezilio_erzesek3a: {
                label: "Érzések 3a",
                level: 9,
                eda: true,
                type: "FeedbackSlider",
                params: {
                    text: "Hogy érzed magad most?",
                    textLeft: "Teljesen ellazult vagyok",
                    textRight: "Kifejezetten feszült vagyok",

                },

            },

            rezilio_erzesek3b: {
                label: "Érzések 3b",
                level: 10,
                eda: true,
                type: "FeedbackSlider",
                params: {
                    text: "Mennyire volt hasznos ez a gyakorlat az érzelmi állapotod szabályozásához?",
                    textLeft: "Egyáltalán  nem volt hasznos",
                    textRight: "Nagyon hasznos volt",

                },

            },

            rezilio_erzesek3c: {
                label: "Érzések 3c",
                level: 11,
                eda: true,
                type: "FeedbackSlider",
                params: {
                    text: "Mennyire valószínű, hogy használnád ezt az érzelmi állapotod szabályozásához máskor is?",
                    textLeft: "Egyáltalán nem valószínű",
                    textRight: "Nagyon valószínű",

                },

            },


            // rezilio_erzesek3d: {
            //     label: "Érzések 3d",
            //     levelType: "REZILIO",
            //     level: 11,
            //     eda: true,
            //     type: "FeedbackSlider",
            //     params: {
            //         text: "Most arra kérünk, hogy értékeld azt, hogy milyen intenzív fájdalomra  számítasz, miután a kezed néhány percig jég hideg vízbe (2 Celsius fokos) merítetted?",
            //         textLeft: "Egyáltalán nincs fájdalom",
            //         textRight: "Legrosszabb elképzelhető fájdalom",

            //     },

            // },


            // rezilio_jegesviz: {
            //     label: "Jeges víz teszt",
            //     levelType: "REZILIO",
            //     level: 12,
            //     eda: true,
            //     type: "VideoEda",
            //     params: {
            //         url: "/media/rezilio/new_cpt_instruction.mp4",
            //         point_mult: 1,
            //         l_incr: 2,
            //         l_decr: 1,
            //         r_incr: 2,
            //         r_decr: 1,
            //         hideWaves: true,

            //     },

            // },


            rezilio_darts_visszavago: {
                label: "Darts visszavágó",
                level: 12,
                eda: true,
                type: "WaveBasic",
                params: {
                    duration: 900000,
                    backgroundImage: "/media/rezilio/bamboo.jpg",
                    // backgroundSound: "/media/rezilio/intro.mp3",
                    continueOnZero: true,
                    hideWaves: true,
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },

            },



        }

    }

}