import React from 'react'
import Wave from 'react-wavify'
// import { Howl } from 'howler'
import { CalcScore } from '../scripts/calc_score';
import { CalcSessionStats } from '../scripts/calc_sessionstats';

// import Backdrop from '@mui/material/Backdrop';
import Fab from '@mui/material/Fab';
// import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import NavigationIcon from '@mui/icons-material/Navigation';
import WaveResultCard from '../WaveResultCard';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography'
import { CircularProgress } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { MyContext } from '../MyContext';
import { Grid } from '@mui/material';

class WaveKeywords extends React.Component {
    static contextType = MyContext;

    constructor(props) {
        super(props);

        this.col1 = [178, 137, 239, 0.7];

        this.color1 = this.rgba(this.col1);

        let params = props.params;

        //if (process.env.NODE_ENV === 'development') this.test = true;
        this.simrelax = 100;
        //this.test = true;

        this.decr_ended_ts = false;

        this.calc_score = new CalcScore(params);
        this.calc_stats = new CalcSessionStats(); // TODO +_++++++++++++++++++++++++++++++++++++++++++

        this.eda = [];

        this.scores = [];
        this.events = [];

        this.direction = "down";

        this.n = 0;
        this.stage = 0;

        this.t = 10;
        this.start = Date.now();
        this.end = 0;

        this.maxs = 0;
        this.maxTs = Date.now();
        this.downStart = Date.now();

        this.keywords = [];
        this.keyword_n = 0;
        this.keyword_id = 0;
        this.lastKeyword = Date.now();

        this.state = {
            showResult: false,
            amplitude: 0,
            askFeedback: false,
            textAreaValue: "",
            keywordsLoaded: false
        }

        if (params.duration) {
            const dur = params.duration;

            this.timeout = setTimeout(() => {
                this.ended();
            }, dur);
        }

        if (params.backgroundImage) {
            this.bgImg = params.backgroundImage;
        }

        let t0 = 0;
        for (let s of props.sessions) {
            if (s.type !== "Keywords") continue;

            //console.log("KEYWORD SESSIONS", s);

            if (s.ts < t0) continue;

            this.keywords = s.data.keywords;
            this.keyword_n = this.keywords.length;
            t0 = this.ts;

        }

        console.log("Keywords:", this.keywords,  this.keyword_n);


    }

    componentDidMount() {

    }

    finished() {
        // on finish, we do not send data, because we already called store_cb in "ended"
        this.props.finished_cb({}, false);
    }


    async ended() {
        if (this.endedCalled) return;
        this.endedCalled = true;

        clearTimeout(this.timeout);

        console.log("Ended");

        // let sessionData = {
        //     eda: this.eda,
        //     scores: this.scores,
        // };

        // let sessionId = await this.props.store_cb(sessionData);

        //console.log("**************************** Session ID:", sessionId);

        //Now read back
        // await fetch

        this.setState({ askFeedback: true, ended: true });
        //this.setState({ showResult: true, sessionData: sessionData, ended: true });

    }

    async save() {
        this.setState({ saving: true });

        let sessionData = {
            eda: this.eda,
            scores: this.scores,
            events: this.events,
            feedback: this.state.textAreaValue,
        };

        let sessionId = await this.props.store_cb(sessionData);

        console.log("**************************** Session ID:", sessionId);

        //Now read back
        // await fetch

        this.setState({ showResult: true, askFeedback: false, sessionData: sessionData, saving: false });

    }

    onEda(gsr, acc) {
        if (this.state.ended) {
            return;
        }

        let ts = Date.now();

        this.eda.push([ts, gsr])

        let sl, sr;

        [sl, sr] = this.calc_score.calc_one(ts, gsr);

        this.calc_stats.calc_one(ts, sl, sr);

        let s = Math.max(sl, sr); // this is max! Harder than min...

        if (this.test) {
            s = this.simrelax;

            //console.log("simrelax", this.simrelax);
        }


        this.scores.push([ts, sl, sr]);

        let now = Date.now();

        if (s > this.maxs) {
            this.maxs = s;
            this.maxTs = now;

            //this.maxs = 0;
        }
        if (s < this.mins) {
            this.mins = s;
            this.minTs = now;

            //this.mins = 1000;
        }

        if (s > 150) s = 150;

        if (s <= 0) {
            s = 0;
        }




        if (s <= 30 && now - this.lastKeyword > 5000) {

            if (this.keyword_id >= this.keyword_n) {
                this.msg = "";

                console.log("Last reached");

                if (s === 0) this.ended();
            } else {

                //console.log("KEYWORD", this.keyword_id, this.keywords[this.keyword_id]);

                this.events.push([ts, "keyword"]);

                this.msg = <div>
                    < div style={{ fontSize: '10vw', color: "black" }}>
                        {this.keywords[this.keyword_id]}
                    </div >
                </div>

                //this.keyword_id = (this.keyword_id + 1) % this.keyword_n;
                this.keyword_id += 1;


                this.lastKeyword = now;

            }

        }

        if (now - this.lastKeyword > 2000) this.msg = "";


        this.setState({ amplitude: s });

        // console.log("amp", s);

    }

    rgba(c) {
        return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${c[3]})`
    }

    siminc() {
        this.simrelax += 5;
    }

    simdec() {
        this.simrelax -= 5;
        if (this.simrelax < 0) this.simrelax = 0;
    }

    handleTextChange(event) {
        this.setState({ textAreaValue: event.target.value });

    }

    async feedback() {
        await this.save();
        this.setState({ askFeedback: false });
    }

    render() {

        if (this.state.saving) {
            return (
                <div>
                    <p>Saving...</p>
                    <CircularProgress />
                </div>
            );
        }

        if (this.state.askFeedback) {
            return (
                <Box maxWidth='90%' margin={3} sx={{ flexDirection: 'column' }}>

                    <br></br>

                    <Typography>
                        If you wish, you can enter your thoughts here:
                    </Typography>

                    <br></br>

                    <TextareaAutosize
                        aria-label="minimum height"
                        minRows={5}

                        //placeholder="If you wish, you can enter your thoughts here..."
                        value={this.state.textAreaValue}
                        onChange={this.handleTextChange.bind(this)}
                        style={{ width: '90%' }}
                    />

                    <br></br>

                    <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.feedback.bind(this)}>Continue</Button>

                </Box>
            );
        }

        if (this.state.showResult) {

            //let final_score = this.calc_score.calc_final_score(this.scores);


            //let total = Math.floor(this.state.final_score.total);

            //let rank = this.calcRank(this.state.hist, total);

            //console.log("Show RESULT", this.state.sessionData);

            let ses = {
                type: 'WaveKeywords',
                exercise: this.props.exercise,
                campaign: this.props.campaign,
                campaign: this.props.campaign,
                data: this.state.sessionData,
                params: this.props.params,
                ts: Date.now(),
                device: this.context.deviceName,
                user: this.context.user
            }


            return (
                <div>
                    {/* <p>
                        Congratulations! You gained experience in controlling your thoughts and emotions,
                        and collected some relax points as well!
                    </p> */}

                    <Box maxWidth='90%' margin={3}>

                        <WaveResultCard session={ses}></WaveResultCard>

                        <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.finished.bind(this)}>SAVE SESSION</Button>

                    </Box>
                </div>
            );
        }



        const style = {
            margin: 0,
            top: 'auto',
            right: 20,
            bottom: 20,
            left: 'auto',
            position: 'fixed',
        };

        let bgImg = this.bgImg;

        let showWave = this.props.params.showWave;

        // console.log("SHOWAVE:", showWave);


        return (


            <div>

                < Fab variant="contained" color="primary" >
                    <CheckCircleIcon onClick={this.ended.bind(this)} />
                </Fab >

                {
                    this.test &&
                    <Fab variant="contained" color="primary" >
                        <NavigationIcon onClick={this.siminc.bind(this)} />
                    </Fab>
                }
                {
                    this.test &&
                    <Fab variant="contained" color="primary" >
                        <NavigationIcon onClick={this.simdec.bind(this)} />
                    </Fab>
                }


                <div style={{ position: 'absolute', left: '0px', top: 0, zIndex: '-10', height: "100%", width: "100%" }}>
                    <img
                        src={bgImg}
                        style={{ height: "100%", width: "100%", objectFit: "cover" }}
                    />
                </div>


                {/* < div style={{ fontSize: '20vw', position: 'absolute', left: '0px', top: '40%', zIndex: '-1', height: "100%", width: "100%" }}>

                    {this.msg}
                </div > */}

                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '100vh', zIndex: '-1', }}
                >

                    <Grid item xs={3}>
                        {this.msg}
                    </Grid>

                </Grid>

                {showWave && < Wave fill={this.color1}
                    paused={false}
                    options={{
                        height: 50,
                        amplitude: this.state.amplitude,
                        speed: 0.15,
                        points: 4
                    }}
                    style={{ position: 'absolute', left: '0px', top: '50%', zIndex: '-3', height: "50%" }}
                ></Wave >
                }


            </div >


        );

    };
}

export default WaveKeywords;