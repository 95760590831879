


import { instruction_ctw_data } from './instruction_ctw_data.js';
import { instruction_kids_ctw_data } from './instruction_kids_ctw_data.js';
import { instruction_audiomodule_data } from './instruction_audiomodule_data.js';
import { instruction_chill_data } from './instruction_chill_data.js';
import { instruction_rezilio_data } from './instruction_rezilio_data.js';
import { instruction_rezilia_data } from './instruction_rezilia_data.js';



export default function instruction_data() {

    var all_data = Object.assign({}, instruction_ctw_data, instruction_chill_data, instruction_audiomodule_data, 
        instruction_kids_ctw_data, instruction_rezilio_data, instruction_rezilia_data);

    //console.log("CTW data", kids_ctw_data);

    return all_data;

}