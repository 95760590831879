import Typography from '@mui/material/Typography';


export const instruction_audiomodule_data = {

    'module_1': {
        hu: (
            <div>
                <br></br>
                <Typography variant="body1" align='center' color="white" sx={{ backgroundColor: "green" }}>
                    MTA-ELTE Adaptáció Kutatócsoport szorongás-csökkentés tréning
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">

                    <i>A program során különböző önismereti gyakorlatokkal ismerkedhetsz meg, melyek hatékonyságát
                        növeli a hozzátartozó önreflexiós kérdések megválaszolása. Miután ezzel a résszel megvagy, végezd el
                        a relaxációs gyakorlatokat, lehetőleg úgy, hogy legalább néhány óra elteljen egy-egy ilyen hanganyag
                        meghallgatása között. Ez segít abban, hogy a tanultak testi-lelki szinten be tudjanak épülni.</i>
                    <br></br>
                    <br></br>
                    <b><u>Összefoglalás:</u></b>
                    <br></br>
                    <br></br>

                    A stressz és szorongás életünk természetes velejárói, segítenek minket a kihívásokhoz
                    való alkalmazkodásban, önmagukban nem káros dolgok. Túlzott ideig, vagy túlzott
                    mértékben azonban káros hatással lehetnek mind fizikai, mind lelki egészségünkre és
                    jóllétünkre.
                    <br></br>
                    <br></br>
                    A stressz a szervezet ún. szimpatikus idegrendszeri válaszát aktiválja, melynek jelei
                    köthetők a szorongáskor megfigyelt élettani jelenségekhez: ilyen például a felgyorsult
                    szívritmus, a szapora légzés, az izgatottság, remegés, szédülés, hányinger, és az
                    émelygés.
                    <br></br>
                    <br></br>
                    Az, hogy kin miképp jelenik meg leginkább a szorongás, az emberről emberre változhat.
                    A tűrőképességünket úgy érdemes elképzelni, mint egy tartályt. A tartályt telítik a külső-
                    belső stresszorok: például az aktuális élethelyzetünk kihívásai, kapcsolati nehézségek,
                    anyagi bizonytalanság, betegség, a ki nem elégített szükségleteink, korábbi traumák
                    hatása. A tartály méretét pedig növelik erőforrásaink: például ha jó a fizikai egészségünk,
                    minőségi kapcsolataink vannak, stabil az egzisztenciánk, bízunk képességeinkben, stb.
                    <br></br>
                    <br></br>
                    Mindezek mentén az érzelmeink - és ezáltal a szorongás - kezelésében az egyik
                    legfontosabb, hogy életmódunk támogassa mentális egészségünket. Ebben három
                    faktornak van leginkább jelentősége, ezek az alvás, az étrend és a mozgás.
                    A bélflóránk szoros összefüggésben van mentális egészségünkkel. Kerüljük a cukros,
                    feldolgozott ételeket, a magas szénhidráttartalmú dolgokat. Fogyasszunk helyette olyan
                    ételeket, melyek magnéziumban (pl. leveles zöldek, hüvelyesek, olajos magvak,
                    teljeskiőrlésű gabonák), cinkben (pl. máj, kesudió, tojás, marha) Omega-zsírsavakban (pl.
                    halak és olajos magvak), B-vitaminban (pl. avokádó, mandula) és antioxidánsban
                    gazdagok. Építsünk be valamilyen mozgást az életünkbe, mert a rendszeres testmozgás
                    mind a szorongás, mind pedig a depresszió tüneteit nagymértékben tudják csökkenteni!
                    <br></br>
                    <br></br>
                    <b>Önreflexiós kérdések:</b>
                    <ul>
                        <li>Fedezd fel a szorongás jeleit magadon! Gondolj vissza egy olyan szituációra,
                            amikor viszonylag magas fokú szorongást éltél meg! Mik voltak az első jelek,
                            amiket észrevettél? Mely testi tüneteket, vagy mentális folyamatot (ilyen például a
                            rágódás).</li>
                        <li>Jelölj ki magadnak egy-egy egyszerű célt az alvással, étrendeddel és sportolási
                            szokásaiddal kapcsolatban, és írd le ezeket. Például: tévé helyett csendre alszom
                            el/ csak hetente kétszer eszek cukrosat/hetente kétszer mozgok fél órát). Maradj
                            reális: olyan célt jelölj ki magadnak, ami valóban beépíthető jelenleg az életedbe,
                            nem kell, hogy ez valami nagy változás legyen! Ezek a szokások már önmagukban
                            segíthetnek abban, hogy kevesebb szorongást és depressziós tünetet élj át!</li>
                        <li>Mit tapasztaltál a relaxációs gyakorlatok során?</li>
                    </ul>

                    <i>Az önreflexiós kérdésekre adott rövid válaszaidat (max. 3-5 mondat) töltsd fel a Canvas
                        felületre! Egy héten egy alkalom hiánytalan teljesítése és a reflexió feltöltése szükséges,
                        légy figyelemmel a Canvason látott határidőre!</i>
                </Typography>
            </div>
        ),

    },

    'module_2': {
        hu: (<div>
            <br></br>
            <Typography variant="body1" align='center' color="white" sx={{ backgroundColor: "green" }}>
                MTA-ELTE Adaptáció Kutatócsoport szorongás-csökkentés tréning
            </Typography>

            <br></br>
            <Typography variant="body1" align='justify' color="text.primary">

                <i>A program során különböző önismereti gyakorlatokkal ismerkedhetsz meg, melyek hatékonyságát
                    növeli a hozzátartozó önreflexiós kérdések megválaszolása. Miután ezzel a résszel megvagy, végezd el
                    a relaxációs gyakorlatokat, lehetőleg úgy, hogy legalább néhány óra elteljen egy-egy ilyen hanganyag
                    meghallgatása között. Ez segít abban, hogy a tanultak testi-lelki szinten be tudjanak épülni.</i>
                <br></br>
                <br></br>
                <b><u>Összefoglalás:</u></b>
                <br></br>
                <br></br>

                A <b>megküzdési stratégiák</b> két nagy csoportja a <b>problémaközpontú</b> és az
                érzelemfókuszú megküzdés. Az előbbi akkor hatékony, ha van esélyünk
                befolyásolni a fennálló vagy fenyegető stresszhelyzetet. Ilyenkor pontosan
                felmérjük a helyzeteket, megoldási módokat dolgozunk ki, majd azok közül
                kiválasztunk egyet, amivel próbálkozunk. Például, ha attól félünk, hogy betegek
                leszünk, elkezdünk egészségesen étkezni, sportolni, vagy rendszeresen kezet
                fertőtlenítünk.
                <br></br>
                <br></br>
                <b>Az érzelemfókuszú megküzdés</b> akkor célszerű, ha magán a helyzeten nem
                változtathatunk. Ilyenkor a cél enyhíteni a stresszkeltő helyzethez kapcsolódó
                érzelmi reakciót, illetve megváltoztatni a helyzet értelmezését. Például egy
                kapcsolat elvesztése esetén nem tudjuk megváltoztatni a tényt, hogy az véget ért,
                de kereshetjük barátaink támogatását, új hobbikba kezdhetünk. Azt is
                megvizsgálhatjuk, hogy hogyan segített az adott kapcsolódás minket abban, hogy
                jobb emberekké váljunk, hogy megtanuljunk valamit, vagyis megtalálhatjuk azt,
                hogyan teremtett értéket az életünkben, amit tovább viszünk, annak ellenére, hogy
                nem tartott örökké.
                <br></br>
                <br></br>
                <b>Tanult tehetetlenség</b> akkor alakul ki, ha valamilyen élethelyzetben megszoktuk,
                hogy hiába cselekszünk, az úgysem eredményre vezető, a szenvedés
                elkerülhetetlen. Ilyenkor hajlamosak leszünk már akkor sem próbálkozni, ha a
                körülmények közben megváltoztak, és lenne módunk változtatni a helyzeten. Ez
                fenntartja az ördögi kört.
                <br></br>
                <br></br>
                A humor, az altruizmus (önzetlen segítés másokon), a szublimáció (pl. az érzelmek
                művészeti alkotásba történő átcsatornázása) olyan <b>énvédő mechanizmusok</b>,
                melyekkel adaptív formában vezethetjük le a bennünk keletkező feszültséget.
                <br></br>
                <br></br>
                A <b>stresszmenedzsment</b> egy fontos része a <b>megfelelő időbeosztás</b> elkészítése,
                ebben nagy segítségedre lehet a sürgős-fontos mátrix (lásd lentebb), vagy to-do
                listák készítése.
                <br></br>
                <br></br>
                <b>A sürgős/fontos mátrix:</b>
                <br></br>
            </Typography>

            <table>
                <tr>
                    <td style={{ verticalAlign: "top", width: "50%", border: "1px solid black" }}>
                        <Typography variant="h6" align='center' gutterBottom={true} color="text.primary">
                            Sürgős és fontos
                        </Typography>
                        <Typography variant="body2" align='left' color="text.primary">
                            „Kihívások”: Ezekkel kezdjük a napunkat, ezek legyenek a prioritásaink!
                        </Typography>
                    </td>
                    <td style={{ verticalAlign: "top", border: "1px solid black" }}>
                        <Typography variant="h6" align='center' gutterBottom={true} color="text.primary">
                            Nem sürgős, de fontos
                        </Typography>
                        <Typography variant="body2" align='left' color="text.primary">
                            „Célok”: Tervezd meg őket, állíts részhatáridőket, mérd fel, mire lesz szükséged
                            majd hozzájuk.
                        </Typography>
                    </td>
                </tr>
                <tr>
                    <td style={{ verticalAlign: "top", border: "1px solid black" }}>
                        <Typography variant="h6" align='center' gutterBottom={true} color="text.primary">
                            Sürgős de nem fontos
                        </Typography>
                        <Typography variant="body2" align='left' color="text.primary">
                            „Megszakítások”: Ha tudod, delegáld őket. Sürgősségük miatt szintén helyezz
                            hangsúlyt arra, hogy be tudd építeni a napodba. Ezekkel a teendőkkel akkor is
                            foglalkozhatsz, amikor fáradtabb vagy. Ide tartozhatnak az e- mailek, a szervezés, a
                            telefonhívások vagy egyéb apróságok.
                        </Typography>
                    </td>
                    <td style={{ verticalAlign: "top", border: "1px solid black" }}>
                        <Typography variant="h6" align='center' gutterBottom={true} color="text.primary">
                            Nem sürgős és nem is fontos
                        </Typography>
                        <Typography variant="body2" align='left' color="text.primary">
                            „Időrablók”: Igyekezz elhagyni őket. Vajon tényleg szükséged van ennyi céltalan
                            dologra, tényleg az életed részét kell, hogy képezze? Az internet céltalan
                            böngészése, telefonozás például gyakran egy törekvés arra, hogy megnyugtassuk
                            magunkat és pihenjünk. Ehelyett iktass be inkább minőségi pihenést, amikor
                            valóban nem érnek ingerek, amikor van ideje „unatkozni” az agyadnak.
                        </Typography>
                    </td>
                </tr>
            </table>


            <Typography variant="body1" align='justify' color="text.primary">

                <br></br>

                Éppen ennyire fontos lehet mérlegelni azt is, hogy vajon az, amivel az időmet
                töltöm, összhangban áll magasabb céljaimmal, értékeimmel? Vajon olyan dolgokra
                próbálom motiválni magam, amik egyáltalán nem is érdekelnek, vagy pontosan
                tudom, hogy mi a cselekvéseim mélyebb értelme. Ebben segít <b>az „Életem kertje”
                    gyakorlat.</b>

                <br></br>
                <br></br>
                <b>Önreflexiós kérdések:</b>
                <ul>
                    <li>Hogyan szoktál általában megküzdeni a stresszel? Milyen fejlett énvédő
                        mechanizmust (szublimáció, humor, altruizmus), vagy megküzdési stratégiát (pl.
                        barátokkal többet beszélgetni, naplózni, felkészülni egy feladatra) építhetnél be az
                        életedbe, ami segítene abban, hogy jobban érezd magad?</li>
                    <li><b>Életem kertje:</b> Az utolsó pont és a hanganyag mentén gondolkodj el azon, hogy
                        milyen életterületnek, tevékenységnek szenteled jelenleg legtöbb idődet és
                        energiádat (pl. sok céltalan telefonozás, barátok, buli, tanulás). Határozz meg egy
                        területet, amire több energiát szeretnél fordítani a jövőben (pl. több időt szánni a
                        testem egészségére/önfejlesztésre/családra/barátokra).</li>
                    <li>Mit tapasztaltál a relaxációs gyakorlatok során?</li>
                </ul>

                <i>Az önreflexiós kérdésekre adott rövid válaszaidat (max. 3-5 mondat) töltsd fel a Canvas
                    felületre! Egy héten egy alkalom hiánytalan teljesítése és a reflexió feltöltése szükséges,
                    légy figyelemmel a Canvason látott határidőre!</i>
            </Typography>
        </div>
        ),
    },

    'module_3': {
        hu: (<div>
            <br></br>
            <Typography variant="body1" align='center' color="white" sx={{ backgroundColor: "green" }}>
                MTA-ELTE Adaptáció Kutatócsoport szorongás-csökkentés tréning
            </Typography>

            <br></br>
            <Typography variant="body1" align='justify' color="text.primary">

                <i>A program során különböző önismereti gyakorlatokkal ismerkedhetsz meg, melyek hatékonyságát
                    növeli a hozzátartozó önreflexiós kérdések megválaszolása. Miután ezzel a résszel megvagy, végezd el
                    a relaxációs gyakorlatokat, lehetőleg úgy, hogy legalább néhány óra elteljen egy-egy ilyen hanganyag
                    meghallgatása között. Ez segít abban, hogy a tanultak testi-lelki szinten be tudjanak épülni.</i>
                <br></br>
                <br></br>
                <b>A következmények hatása a viselkedésünkre</b>
                <br></br>
                <br></br>
                A viselkedésterápia alapelmélete szerint szokásaink a mentén alakulnak, hogy az
                adott viselkedés milyen következménnyel jár az életünkben. Ezek lehetnek külső és
                belső megerősítések, vagy negatív megerősítések, büntetés is. Ahogy az állatoknál
                is működik az, hogy <b>ha szeretnénk valamit tanítani nekik, akkor jutalmaznunk kell
                    őket a kívánt viselkedés megjelenésekor, hasonlóan kondicionálható a mi agyunk
                    is.</b>
                <br></br>
                <br></br>
                Ezeket használhatjuk a pozitív formában, például jutalmazhatjuk magunkat,
                kellemes élményeket kapcsolhatunk ahhoz, ha a kijelölt célunk mentén teszünk
                meg akár egészen apró lépéseket is. Például minden megtanult fejezet után nézünk
                egy részt a kedvenc sorozatunkból, vagy a nehéz vizsgára készülés után
                megengedjük magunknak azt a könyvet vagy ruhadarabot, amivel már régóta
                szemeztünk.
                <br></br>
                <br></br>
                Legfontosabb ereje a szorongás fennmaradásában azonban az <b>elkerülésnek</b> van.
                Amikor szorongunk, az agyunk azt üzeni: „Ezt még nem ismerem, ezért veszélyes
                lehet, lehet, hogy nem vagyok biztonságban”. Ideális esetben átlendülünk ezen az
                állapoton, és a meglévő negatív érzések mellett is belemegyünk egy helyzetbe,
                hogy megbizonyosodhattunk róla, <b>akkor is, ha valami még ismeretlen, lehet
                    biztonságos,</b> képesek vagyunk megbirkózni vele, sőt, akár pozitív dolgok is
                származhatnak belőle.
                <br></br>
                <br></br>
                Ha azonban valamilyen helyzetet tartósan elkerülünk, akkor valójában a <b>rövidtávú
                    előny mentén áldozzuk be a hosszútávú lehetőségeket.</b> Vagyis azért, hogy most
                könnyű legyen, hogy most ne kelljen szembenézzünk esetleges kellemetlen
                érzésekkel (megerősítő hatás!), valójában egy ördögi körbe helyezzük magunkat,
                hiszen így az agyunk meg sem tudja cáfolni ezt a mintát, ezzel elvághat minket új
                lehetőségektől, önbizalmunk erősödésétől, szorongásunk tartós csökkenésétől.
                <br></br>
                <br></br>
                Különösen nehéz lehet ezekből kilépni, ha a <b>családunkban</b> mintát láttunk arra, hogy
                valamiért ez a „jó” út, ahol elkerülhetőnek tűnik a fájdalom.
                <br></br>
                <br></br>
                Például, ha szociálisan szorongók vagyunk, akkor lehet, hogy az adott társasági
                esemény elkerülésével megkíméljük magunkat az ott kialakuló fokozódó
                szorongástól, az esetleges „beégéstől”, szégyenérzettől. Hosszútávon azonban <b>így
                    a fejünkben lévő valóságnak lehetősége sincs megkérdőjeleződni,</b> önbizalmunk
                ilyen téren nem tudfog növekedni, és csak azt a valóságot erősítjük az elkerüléssel
                a fejünkben: „Igen, emberekkel lenni tényleg nem jó, jobb, ha egyedül vagyunk.”
                <br></br>
                <br></br>
                Hasonlóan fenntarthatjuk valami iránti fóbiánkat, félelmünket akkor, ha elkerüljük
                azt, hogy ki legyünk téve az adott helyzetnek, mellyel egyre inkább beszűkülhet
                életterünk. Vagyis a szorongásunk tartós csökkenéséhez és önbizalmunk
                növeléséhez a legtöbbet azzal tehetjük, ha megvizsgáljuk, milyen területeken,
                helyzetekben irányítja az elkerülés, a negatív érzelmektől való félelem az életünket,
                és elkezdünk új megoldásokat választani.
                <br></br>
                <br></br>
                Les Brown mondta: „Csinálj könnyű dolgokat, és az élet nehéz lesz. Csinálj nehéz
                dolgokat, és könnyű életed lesz.”

                <br></br>
                <br></br>
                <b>Önreflexiós kérdések:</b>
                <ul>
                    <li>Életed mely területein, milyen helyzetekben alkalmazod az elkerülést? Mi ennek a
                        rövidtávú előnye számodra, és milyen lehetőségektől vághat el téged hosszútávon?
                        Milyen érzelem jelenne meg, ha legközelebb nem kerülnéd el az adott helyzetet?</li>
                    <li>A következő feladat pedig az érzelmek elfogadásában, jelentudatos szemlélésében
                        segít. Hallgasd meg a hanganyagot, és gondolkodj el az üzenetén! Téged mely
                        érzelmek látogatnak gyakrabban, és melyek azok, amelyeket legkevésbé látsz jó
                        szemmel? Hogyan változna az életed, ha az érzelmeket csak vendégeknek
                        tekintenéd, amelyek csak jönnek és mennek?</li>
                    <li>Mit tapasztaltál a relaxációs gyakorlatok során?</li>
                </ul>

                <i>Az önreflexiós kérdésekre adott rövid válaszaidat (max. 3-5 mondat) töltsd fel a Canvas
                    felületre! Egy héten egy alkalom hiánytalan teljesítése és a reflexió feltöltése szükséges,
                    légy figyelemmel a Canvason látott határidőre!</i>

            </Typography>
        </div>
        ),
    },

    'module_4': {
        hu: (<div>
            <br></br>
            <Typography variant="body1" align='center' color="white" sx={{ backgroundColor: "green" }}>
                MTA-ELTE Adaptáció Kutatócsoport szorongás-csökkentés tréning
            </Typography>

            <br></br>
            <Typography variant="body1" align='justify' color="text.primary">

                <i>A program során különböző önismereti gyakorlatokkal ismerkedhetsz meg, melyek hatékonyságát
                    növeli a hozzátartozó önreflexiós kérdések megválaszolása. Miután ezzel a résszel megvagy, végezd el
                    a relaxációs gyakorlatokat, lehetőleg úgy, hogy legalább néhány óra elteljen egy-egy ilyen hanganyag
                    meghallgatása között. Ez segít abban, hogy a tanultak testi-lelki szinten be tudjanak épülni.</i>
                <br></br>
                <br></br>
                <b><u>Összefoglalás:</u></b>
                <br></br>
                <br></br>
                A kognitív terápia elmélete szerint nem azért érezzük magunkat valahogy, amiért
                valami történik velünk, hanem azért, <b>ahogyan gondolkodunk arról, ami történt
                    velünk.</b>
                <br></br>
                <br></br>
                Az erős negatív érzelmek gyakran ún. <b>„gondolkodási csapdákat”</b> jelezhetnek. Ezek
                közös jellemzője, hogy a gondolatok elszakadnak a valóság reális észlelésétől.
                <br></br>
                <br></br>
                Például:
                <br></br>
                <br></br>
                (1) Mindent vagy semmit gondolkodás (Például: Egy rossz jegy azt jelenti, hogy ‘rossz
                tanuló’ vagy);
                <br></br>
                (2) Katasztrofizálás (A legrosszabb kimenetelt képzeled el);
                <br></br>
                (3) Negatív szemüvegen keresztül látás (Figyelmen kívül hagyod a pozitív részeket);
                <br></br>
                (4) Elmeolvasás (Azt feltételed, hogy tudod, mi van másokban, mit gondolnak rólad);
                <br></br>
                (5) Változásra várás (Azt várod, hogy más változtasson a viselkedésén ahhoz, hogy te
                boldog legyél);
                <br></br>
                (6) Jóslás (A múltból kiindulva prediktálod a jövőt -&gt; „Egyedül fogok meghalni, mert eddig
                volt, aki elhagyott”)
                <br></br>
                (7) Címkézés (Nem az adott szituációt nevezed meg, hanem abból kiindulva minősíted
                magad „buta”, „értéktelen”, stb.)
                <br></br>
                (8) Általánosítás („Senki nem szeret” &lt;-&gt; Valakivel összevesztem)
                <br></br>
                (9) Hibáztatás (Másokra tolod a felelősséget)
                <br></br>
                (10) „Kellene/Muszáj” gondolkodás („Muszáj ezt csinálnom” helyett „Ezt választom,
                mert…”.)
                <br></br>
                <br></br>
                Az egyik legfontosabb módszer a <b>kognitív újrastrukturálás</b> használata.
                <br></br>
                <br></br>
                1. lépés: Amikor erős negatív érzelmet tapasztalsz, állj meg egy pillanatra.
                <br></br>
                2. lépés: Azonosítsd a kiváltó okokat, triggereket. Mi történt valójában, objektíven?
                <br></br>
                3. lépés: Mi járt a fejedben, amitől rosszul érezted magad? Mire gondoltál? Felfedezel
                ezekben a gondolatokban valamilyen gondolkodási csapdát? (Nyugodtan menj végig a
                fenti listán!)
                <br></br>
                4. lépés: Milyen érzelmek jelentek meg benned ezen gondolatok hatására? Értékeld is az
                adott érzelem intenzitását 1-10-es skálán! (pl. szomorúság, 5-ös erősséggel)
                <br></br>
                5. lépés: Keress a 3. pontban meghatározott gondolatok helyett alternatív gondolatokat,
                értelmezéseket! Mi lehetne egy reálisabb megközelítése a helyzetnek? Nem kell, hogy
                pozitív legyél, elég, ha reális vagy!
                <br></br>
                6. lépés: Figyeld meg, változott-e a benned lévő negatív érzelem erőssége!
                <br></br>
                <br></br>
                <b>A szorongáslétra módszere:</b>
                <br></br>
                <br></br>
                A cél a szorongásaink legyőzésekor nem az, hogy azonnal a legnagyobb
                félelmünknek tegyünk ki magunkat, hiszen ez sokszor olyan akadálynak tűnhet,
                amiben el sem tudjuk képzelni magunkat. Ilyenkor érdemes a szorongáslétrát
                használnunk, mely az alábbi módon működik:
                <br></br>
                <br></br>
                Jelöld ki azt a <b>célt</b>, amit végül majd el szeretnél érni! Ez lesz a létra legfelső
                lépcsőfoka. Például vezetéstől való félelem esetén lehet az, hogy autópályán
                vezess, többszáz kilométert is tudj utazni. Majd kezdj el apró lépéseken
                gondolkodni, melyek hozzásegíthetnek ehhez a célhoz. Mi lenne a <b>legkisebb lépés</b>,
                amit tehetnél azért, hogy legyőzd ezt a félelmedet? Például az előbbi példa mentén,
                ha azon kapod magad, hogy már a parkolóhelyről kiállás szorongással jár, akkor
                lehet ez az első lépés, a létra első foka. Olyat keress, ami jár <b>némi feszültséggel, de
                    elképzelhetőnek tartod, hogy megbirkózz vele!</b> Majd állítsd fel a létra köztes, egyre
                nehezedő lépéseit: például menni egy kört a ház körül az autóval, kicsi utcákban
                vezetni, úgy vezetni, hogy mások is vannak veled, stb.
                <br></br>
                <br></br>
                A cél addig gyakorolni <b>egy-egy lépcsőfokot, amíg elmúlik, vagy jelentős
                    mértékben csökken a szorongásod,</b> majd lépni a következőre. Előtte nyugodtan
                vizualizálhatod relaxált állapotban is az adott helyzetet, ha úgy érzed, nagyon
                szorongást kelt benned a következő lépcsőfok gyakorlatban történő meglépése.
                Például a progresszív izomrelaxáció végrehajtása után elképzeled azt, ami
                szorongást vált ki, majd ha fokozódik a szorongás, akkor újra feszíted az izmaid
                néhány másodpercig, vagy valamilyen kellemes emléket is felidézhetsz addig, amíg
                megnyugszol. Mindezzel tanítod a testednek azt, hogy az adott helyzetben nyugodt
                tudjon maradni.

                <br></br>
                <br></br>
                <b>Önreflexiós kérdések:</b>
                <ul>
                    <li>A <b>kognitív újrastrukturálás</b> gyakorlat végrehajtása: gondolj vissza a legutóbbi
                        helyzetre, amikor erős negatív érzelmet tapasztaltál, majd haladj végig a lépéseken,
                        leírva azokat! Ha az könnyebb számodra, akkor akár a napokban is megfigyelheted,
                        hogy mikor keletkezik benned negatív érzelem, akkor végrehajtva a gyakorlatot.</li>

                    <li>A <b>szorongáslétrád</b> felállítása: állíts fel egy célt, mely most még jelentős szorongást
                        idéz elő benned (pl. előadni egy nagyobb közönség számára)! Mi lehet a cél
                        irányába vezető <b>első lépés</b>, melyet megtehetsz (pl. prezentálni valamit a
                        családod/barátaid/szerelmed előtt)? Írd le ezt, majd gyakorold minél többször,
                        hiszen a cselekvés a szorongás ellentéte! Amint az adott helyzet már nagyjából
                        komfortos számodra, léphetsz tovább a következőre. Ha túl nehéznek érzed, állíts
                        fel egy még kisebb lépést, vagy gyakorold egy relaxációban elképzelve, hogy
                        végrehajtod az adott dolgot! Ha úgy éreznéd, hogy kezd erősödni benned a
                        szorongás, akkor idézz fel magadban egy kellemes élményt, biztonságot adó képet.</li>

                    <li>Mit tapasztaltál a relaxációs gyakorlatok során?</li>
                </ul>

                <i>Az önreflexiós kérdésekre adott rövid válaszaidat (max. 3-5 mondat) töltsd fel a Canvas
                    felületre! Egy héten egy alkalom hiánytalan teljesítése és a reflexió feltöltése szükséges,
                    légy figyelemmel a Canvason látott határidőre!</i>


            </Typography>
        </div>
        ),
    },

    'module_5': {
        hu: (<div>
            <br></br>
            <Typography variant="body1" align='center' color="white" sx={{ backgroundColor: "green" }}>
                MTA-ELTE Adaptáció Kutatócsoport szorongás-csökkentés tréning
            </Typography>


            <br></br>
            <Typography variant="body1" align='justify' color="text.primary">

                Az előző alkalommal felállított <b>szorongáslétrádat</b> vedd elő újra, és vizsgáld meg,
                hogyan haladsz vele. <b>Melyik lépésnél tartasz most?</b> Tedd meg gyakorlatban a
                következő lépést, és ha szükséged van rá, először relaxálj egyet, majd képzeld el
                magad előtt, ahogy végrehajtod az adott cselekvést. Ne feledd, ha úgy érzed,
                hogy már elképzeléskor fokozódik a szorongásod, újra megfeszítheted néhány
                másodpercen keresztül az izmaidat (akár az egész testedet), vagy felidézhetsz
                minél érzékletesebben valamilyen kellemes emléket, amíg megnyugszol. Az
                alábbiakban találsz még néhány eszközt, amelyet szintén gyakorolhatsz a
                szorongáslétrád megmászásakor is, vagy bármikor, amikor szükségét érzed.
                <br></br>
                <br></br>
                <b>Néhány gyakorlat, amit még a szorongásod csökkentéséhez használhatsz:</b>
                <br></br>
                <br></br>
                Mindezeket úgy érdemes elképzelni, mint egy szerszámosládát: érdemes minél
                többet kipróbálnod, kísérletezned velük, hogy mikor melyik válik be számodra, de
                egyiket sem szükséges (folyton) alkalmaznod. Minél több technikád van
                érzelmeid szabályozására, annál nagyobb magabiztossággal fogod venni az élet
                kihívásait.
                <br></br>
                <br></br>
                <b>Jelentudatos figyelem:</b> Ne feledd, a gondolatok csak gondolatok, az érzelmek
                pedig csak érzelmek. Mind-mind múló állapotok, melyek nem veszélyesek rád,
                „csak látogatók”. Gyakorolhatod az érzelmeid és gondolataid szemlélését, mintha
                elúsztatnád őket egy folyón, vagy felhők képében haladnának tova az elmédben.
                <br></br>
                <br></br>
                Most szánj egy kis időt arra, hogy visszanézz az elmúlt hetekre, és mindarra, amit
                a szorongásról tanultál. Dicsérd meg magad az erőfeszítésekért, és bátran
                kombináld, alakítsd a tanult eszközöket és szemléletet a számodra
                legmegfelelőbb módon!
                <br></br>
                <br></br>
                Köszönjük, hogy velünk tartottál, és reméljük, hasznos volt számodra! Ha úgy
                érzed, bármelyik rész kevésbé mélyült el benned, nyugodtan tekintsd át újra az
                adott modult, hogy minél többet fejlődhess a kurzus során.


                <br></br>
                <br></br>
                <b>Önreflexiós kérdések:</b>
                <ul>
                    <li>Hol tartasz most a szorongáslétrádon? Mi lesz a következő lépés, és milyen volt az
                        eddigi lépés(ek) megtétele?</li>

                    <li>Mely eszköz/szemlélet volt számodra a leghatékonyabb? Miben fejlődtél az
                        intervenció során? Hogyan folytathatod ezt az utat később is?</li>

                    <li>Van valamilyen javaslatod a programot illetően? Hogyan segíthettünk volna még
                        többet, mire lennél még kíváncsi?</li>
                </ul>

                <i>Az önreflexiós kérdésekre adott rövid válaszaidat (max. 3-5 mondat) töltsd fel a Canvas
                    felületre! Egy héten egy alkalom hiánytalan teljesítése és a reflexió feltöltése szükséges,
                    légy figyelemmel a Canvason látott határidőre!</i>

            </Typography>
        </div>
        ),
    },
}
