import { render } from '@testing-library/react';
import React, { useState, useEffect } from 'react';


import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';

import CircularProgress from '@mui/material/Button';
import { Navigate } from "react-router-dom";
import Slide from '@mui/material/Slide';
import Zoom from '@mui/material/Zoom';
import Grid from '@mui/material/Grid';

import Box from '@mui/material/Box';

const HomePage = props => {

  console.log("Exercise --------------------------------------------", props);

  //courses = courses();

  const styles = {
    card: {
      // Provide some spacing between cards
      margin: 16,

      // Use flex layout with column direction for components in the card
      // (CardContent and CardActions)
      display: "flex",
      flexDirection: "column",

      // Justify the content so that CardContent will always be at the top of the card,
      // and CardActions will be at the bottom
      justifyContent: "space-between"
    },
    media: {
      height: 200,
      margin: "auto",
      width: 250,
      objectFit: "scale-down"
    }
  };

  return (
    <Box maxWidth='90%' margin={3}>
      <Grid container spacing={2}  >

        <Grid item xs={12}>

          <Card elevation="0" >
            <CardContent>
              <Typography variant="h3">
                Evolving technology for human sciences
              </Typography>
              <Typography variant="body2" color="text.secondary">

              </Typography>
            </CardContent>
          </Card>
        </Grid>


        <Grid item xs={12} md={6} lg={4} xl={3}>
          <Zoom direction="right" in="true" style={{ transitionDelay: '500ms' }}>
            <Card elevation="0" >
              
              <CardContent>
                <Typography variant="h4">
                  Obimon EDA
                </Typography>
              </CardContent>

              <CardMedia
                component="img"
                image="./eda.png"
                style={styles.media}
              />

              <CardContent>
                <Typography variant="h6" color="text.secondary">
                  A wearable electrodermal activity sensor with Bluetooth connectivity.
                </Typography>
              </CardContent>
            </Card>
          </Zoom>
        </Grid>

        <Grid item xs={12} md={6} lg={4} xl={3}>
          <Zoom direction="right" in="true" style={{ transitionDelay: '700ms' }} >
            <Card elevation="0" >

              <CardContent>
                <Typography variant="h4">
                  Obimon Play
                </Typography>
              </CardContent>

              <CardMedia
                component="img"
                image="./play.png"
                style={styles.media}
              />

              <CardContent>
                <Typography variant="h6" color="text.secondary">
                  A social-emotional, cognitive and motor skills assessment system during play activities.
                </Typography>
              </CardContent>
            </Card>
          </Zoom>
        </Grid>

        <Grid item xs={12} md={6} lg={4} xl={3}>
          <Zoom direction="right" in="true" style={{ transitionDelay: '900ms' }} >
            <Card elevation="0" >

              <CardContent>
                <Typography variant="h4" >
                  Obimon MONA
                </Typography>
              </CardContent>


              <CardMedia
                component="img"
                image="./mona.png"
                style={styles.media}

              />

              <CardContent>
                <Typography variant="h6" color="text.secondary">
                  A non intrusive social interaction analysis
                  system.
                </Typography>
              </CardContent>
            </Card>
          </Zoom>
        </Grid>

        <Grid item xs={12} md={6} lg={4} xl={3}>
          <Zoom direction="right" in="true" style={{ transitionDelay: '1100ms' }} >
            <Card elevation="0" >

              <CardContent>
                <Typography variant="h4" component="div">
                  Obimon PROX
                </Typography>
              </CardContent>

              <CardMedia
                component="img"
                image="./prox.png"
                style={styles.media}
              />

              <CardContent>
                <Typography variant="h6" color="text.secondary">
                  A non-camera based high-precision personal space measuring device.
                </Typography>
              </CardContent>
            </Card>
          </Zoom>
        </Grid>


        {/* FOOT */}

        {/* <Grid item xs={4}>

        <Card elevation="2" >

          <CardContent>
            <Typography variant="h6" color="text.secondary">
              Obimon Systems Ltd.
            </Typography>
          </CardContent>

        </Card>

        </Grid> */}

        <Grid item xs={12}>
          <Card elevation="0" >

            <CardContent>
              <Typography variant="h6" color="text.secondary">
                Obimon Systems Ltd.
              </Typography>
              <Typography variant="body1" color="text.secondary">
                info@obimon.com
              </Typography>
            </CardContent>

          </Card>
        </Grid>

      </Grid>

    </Box>
  )


}

export default HomePage;
