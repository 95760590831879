

const house_scale = 0.7;
const house_area = [[250, 650]]

export default function features() {
    return {

        mid: {
            trees: {
                area: [[313, 365], [708, 341], [1151, 364], [1144, 436], [742, 471],]
            },
        },

        fg: [
            {
                id: 'house',
                img: 'haz_ures.webp',
                scale: house_scale,
                area: house_area,

                sessions: 1
            },

            {
                id: 'house_logs',
                img: 'haz_part_farakas.png',
                scale: house_scale,
                area: house_area,

                sessions: 2
            },

            {
                id: 'house_bench',
                img: 'haz_part_kertipad.png',
                scale: house_scale,
                area: house_area,

                sessions: 3
            },

            {
                id: 'house_shrub',
                img: 'haz_part_noveny.png',
                scale: house_scale,
                area: house_area,

                sessions: 4,

            },

            {
                id: 'house_flower1',
                img: 'haz_part_virag1.png',
                scale: house_scale,
                area: house_area,

                sessions: 5,
            },


            {
                id: 'house_flower2',
                img: 'haz_part_virag2.png',
                scale: house_scale,
                area: house_area,

                sessions: 5,
            },

            {
                id: 'house_flower3',
                img: 'haz_part_virag3.png',
                scale: house_scale,
                area: house_area,

                sessions: 5,
            },

            {
                id: 'dog',
                img: 'kutya1.png',
                layer: 'fg',
                scale: 0.6,
                area: [[309, 689],],

                sessions: 7,
            },


            {
                id: 'kutya2',
                img: 'kutya2.png',
                scale: 0.6,
                area: [[476, 660],],
                sessions: 7,

            },


            {
                id: 'talicska1',
                img: 'talicska1.png',
                scale: 0.6,
                area: [[79, 715],],
                sessions: 9,

            },


            {
                id: 'gyik',
                img: 'gyik.png',
                scale: 0.6,
                area: [[452, 693],],
                sessions: 11,

            },

            {
                id: 'mokus',
                img: 'mokus.png',
                scale: 0.6,
                area: [[393, 708],],
                sessions: 11,

            },


            {
                id: 'kosar',
                img: 'kosar.png',
                scale: 0.7,
                area: [[660, 631],],
                sessions: 13,

            },


            {
                id: 'horse2',
                img: 'lo2_3D.png',
                scale: 1.1,
                area: [[652, 539],],
                sessions: 14,

            },

            {
                id: 'horse3',
                img: 'lo3_3D.png',
                scale: 1.2,
                area: [[743, 546],],

                sessions: 16,

            },


            {
                id: 'horse',
                img: 'lo.png',
                scale: 0.8,
                area: [[900, 630],],

                sessions: 18,

            },


            {
                id: 'foal',
                img: 'csiko.png',
                scale: 0.4,
                area: [[765, 624],],

                sessions: 18,
            },

            {
                id: 'butterfly1',
                img: 'bogar_3D01_lepke.png',
                scale: 0.6,
                area: [[712, 49], [1195, 47], [1200, 113], [709, 121],],

                sessions: 20,

            },

            {
                id: 'bug1',
                img: 'bogar_3D02_saska.png',
                scale: 1,
                area: [[1136, 680],],
                sessions: 20,

            },

            {
                id: 'bug2',
                img: 'bogar_3D03_szarvasbogar.png',
                scale: 1,
                area: [[1225, 670],],

                sessions: 20,

            },

            {
                id: 'bug3',
                img: 'bogar1.png',
                scale: 1,
                area: [[1171, 680],],

                sessions: 20,

            },


            {
                id: 'disztokok1',
                img: 'disztokok1.png',
                scale: 0.8,
                area: [[963, 690],],
                sessions: 24,

            },

            {
                id: 'disztokok2',
                img: 'disztokok2.png',
                scale: 1,
                area: [[616, 617],],
                sessions: 24,

            },

            {
                id: 'lud1',
                img: 'lud1.png',
                scale: 0.6,
                area: [[1057, 469],],
                sessions: 26,

            },

            {
                id: 'menyet',
                img: 'menyet.png',
                scale: 1,
                area: [[399, 451],],
                sessions: 28,

            },


            {
                id: 'oz',
                img: 'oz.png',
                scale: 0.6,
                area: [[48, 406], [94, 414], [97, 452], [44, 454],],
                sessions: 30,

            },

            {
                id: 'szarvas',
                img: 'szarvas.png',
                scale: 0.6,
                area: [[205, 350], [240, 337], [269, 359], [220, 374],],
                sessions: 32,

            },

            {
                id: 'vadkacsa',
                img: 'vadkacsa.png',
                scale: 1,
                area: [[1068, 611],],
                sessions: 34,

            },

            {
                id: 'virag2_ket_cserep',
                img: 'virag2_ket_cserep.png',
                scale: 0.7,
                area: [[378, 625],],
                sessions: 36,

            },

            {
                id: 'virag3_vodorben',
                img: 'virag3_vodorben.png',
                scale: 0.6,
                area: [[208, 697],],
                sessions: 38,

            },


            {
                id: 'bagoly',
                img: 'bagoly.png',
                scale: 0.6,
                area: [[258, 150],],
                // sessions: 20,
                relax: 1000,
                decr: 500,
                sustain: 500,
                trees: 6
            },

            // tree: 6,
            // relax: 1000,
            // decr: 500,
            // sustain: 500,

        ],
    }
}

