import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography'
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { MyContext } from '../MyContext';


class WaveBasic extends React.Component {
    static contextType = MyContext;


    constructor(props) {
        super(props);

        this.keyRefs = [];

        this.nkeys = 7;
        this.minKeywords = props.params.params.minKeywords;

        console.log("minKeywords:", this.minKeywords);

        for (let i = 0; i < this.nkeys; i++) {
            this.keyRefs.push(React.createRef());
        }


        let params = props.params;

        console.log("PPP", props);

        let t0 = 0;
        let text = "";
        for (let s of props.sessions) {
            if (s.type !== "Keywords") continue;

            // console.log("KEYWORD SESSIONS", s);

            if (s.ts < t0) continue;

            this.keywords = s.data.keywords;
            this.keyword_n = this.keywords.length;
            text = s.data.text;
            t0 = this.ts;
        }

        // console.log("Keywords:", this.keywords,  this.keyword_n, text);


        this.state = {
            askFeedback: false,
            textAreaValue: text
        }


    }

    finished() {
        // on finish, we do not send data, because we already called store_cb in "ended"
        this.props.finished_cb({}, false);
    }


    handleTextChange(event) {
        this.setState({ textAreaValue: event.target.value });


    }

    async feedback() {
        //console.log("PUSHED", this.keyRefs[0].current.value);

        let r = [];

        for(let k of this.keyRefs) {
            let v = k.current.value;
            
            v = v.trim();

            if(v === "") continue;

            r.push(v);
        }
        //console.log(this.state.textAreaValue, r);

        if(r.length<this.minKeywords) {
            alert("Please enter at least "+this.minKeywords+" keywords!");
            return;
        }

        let ret = {
            text: this.state.textAreaValue,
            keywords: r
        }

        await this.props.store_cb(ret);
        await this.props.finished_cb({}, false);
    }

    render() {

        let s = [];
        for (let i = 0; i < this.nkeys; i++) {

            let k="";
            if(i<=this.keyword_n) {
                k = this.keywords[i];
            }

            s.push(
                <div>
                    <br></br>
                    <input key={i} ref={this.keyRefs[i]} defaultValue={k}/>

                </div>
            );
        }

        //  defaultValue="s"

        return (
            <Box maxWidth='90%' margin={3} sx={{ flexDirection: 'column' }}>

                <br></br>

                <Typography>
                    Please enter your thoughts here:
                </Typography>

                <br></br>

                <TextareaAutosize
                    aria-label="minimum height"
                    minRows={5}

                    //placeholder="If you wish, you can enter your thoughts here..."
                    value={this.state.textAreaValue}
                    onChange={this.handleTextChange.bind(this)}
                    style={{ width: '90%' }}
                />

                <br></br>
                <br></br>

                <Typography>
                    Enter keywords below (at least {this.minKeywords}):
                </Typography>

                {/* <InputUnstyled placeholder="Type something..." ref={this.key1} /> */}
                {s}

                <br></br>

                <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.feedback.bind(this)}>SUBMIT</Button>

            </Box>
        );

    };
}

export default WaveBasic;