import { CalcSessionStats } from './calc_sessionstats';
import { getWeek } from './getWeek';
// import exercise_data from './exercise_data';
import campaign_data from './campaign_data';


export function calcWeeklyCTW(sessions, levelType) {
    console.log("calcWeeklyStats", levelType);

    let now = new Date();

    let thisWeek = getWeek(now);
    let thisYear = now.getFullYear();

    //console.log("thisweek", thisWeek);

    let wstat = {};
    let allstat = {};

    allstat.relaxpts = 0;
    allstat.decr = 0;
    allstat.sustain = 0;
    allstat.numsessions = 0;
    allstat.tree = 0;

    wstat.relaxpts = 0;
    wstat.decr = 0;
    wstat.sustain = 0;
    wstat.numsessions = 0;
    wstat.tree = 0;

    // let exdata = exercise_data();
    let campdata = campaign_data();

    for (let s of sessions) {
        // if (s.type !== 'Waves2Phase' && s.type !== 'WaveBasic' && s.type !== 'Tetris'
        //     && s.type !== "WavesSubliminal" && s.type !== "WaveUpdown" && s.type !== "WaveReinforce") continue;

        // if (!s.data) continue;
        // if (!s.data.eda) continue;
        // if (s.data.eda.length < 10) continue;

        //console.log(s.exercise, exdata[s.exercise]);
        if(! s.campaign ) continue;
        if(campdata[s.campaign].levelType != levelType) continue;

        if (!s.data.scores) continue;
        if (s.data.scores.length < 10) continue;


        let ts = s.ts;
        var date = new Date(ts);

        let w = getWeek(date);
        let y = date.getFullYear()

        //console.log("SESS", ts, y, w, thisWeek);

        //console.log("SESS", s);

        // console.log("====== exdata ", ex.params);

        let calcSessionStats = new CalcSessionStats();
        let sessionStats = calcSessionStats.calc_stats(s);


        let wid = y * 52 + w;
        let thiswid = thisYear * 52 + thisWeek;

        wid = thiswid - wid;

        // console.log("App SESSION STATS", wid, sessionStats);

        if (sessionStats.relaxpts) {
            if(wid === 0) wstat.relaxpts += sessionStats.relaxpts;
            allstat.relaxpts += sessionStats.relaxpts;
        }
        if (sessionStats.decr) {
            if(wid === 0) wstat.decr += sessionStats.decr;
            allstat.decr += sessionStats.decr;
        }
        if (sessionStats.sustain) {
            if(wid === 0) wstat.sustain += sessionStats.sustain;
            allstat.sustain += sessionStats.sustain;
        }

        if(wid === 0) wstat.numsessions += 1;
        allstat.numsessions += 1;

        if (s.data.creature) {
            let creature = s.data.creature;
            if (creature.type === "tree") {
                if (creature.data.size && creature.data.size >= 50) {
                    if(wid === 0) wstat.tree += 1;
                    allstat.tree += 1;
                }
            }

            if(creature.type==="flower") {
                if(creature.data.size >= 50) {
                    if(wid === 0) wstat.tree += 1;
                    allstat.tree += 1;
                }
            }
        }

        //var date = new Date(s.);
        //if(s.type != 'Waves2Phase') console.log("W2P++");

    }

    // console.log("App STATS", allstat, wstat);

    return {
        allstat: allstat,
        wstat: wstat
    };
}
