

import React from 'react';
import { Paper } from '@mui/material';
import features from '../scripts/features';

import { MyContext } from '../MyContext';

export default class Scenery extends React.Component {
  static contextType = MyContext;

  constructor(props) {
    super(props);

    this.canvas = React.createRef()
    this.bgcanvas = React.createRef()
    this.fgcanvas = React.createRef()
    this.newcanvas = React.createRef()

    this.features = features();

    this.cw = 1280;
    this.ch = 720;

    this.state = {
    }
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async saveScenery(features) {
    if(features.length==0) return;

    console.log("BACKEND saveScenery", features);

    let resp = await fetch('/api/savemyscenery', {
      method: 'POST',
      body: JSON.stringify({
        features: features
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    //let d = await resp();

    //console.log("SAVE MyScenery:", resp);
  }

  async saveSceneryImage() {
    let bgcanvas = this.bgcanvas.current;
    // let bgctx = bgcanvas.getContext('2d') //// 3 - access node using .current
    if(bgcanvas === null) return;

    let canvas = this.canvas.current;
    if(canvas === null) return;

    // let ctx = canvas.getContext('2d') //// 3 - access node using .current

    let newcanvas = this.newcanvas.current;
    let newctx = newcanvas.getContext('2d') //// 3 - access node using .current

    // let w = bgcanvas.width;
    // let h = bgcanvas.height;

    let u1 = bgcanvas.toDataURL();
    let u2 = canvas.toDataURL();

    //newctx.putImageData(i1,0,0);
    var i1 = new Image();
    i1.src = u1;
    await i1.decode();

    var i2 = new Image();
    i2.src = u2;
    await i2.decode();

    newctx.drawImage(i1,0,0);
    newctx.drawImage(i2,0,0);

    var dataURL = newcanvas.toDataURL('image/webp', 0.5);

    console.log("BACKEND saveSceneryImage");

    let resp = await fetch('/api/savemysceneryimage', {
      method: 'POST',
      body: JSON.stringify({
        img: dataURL
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    //let d = await resp();

    //console.log("SAVE MyScenery:", resp);
  }


  async getScenery() {
    console.log("BACKEND GET getScenery");

    let resp = await fetch('/api/getmyscenery', {
      method: 'POST',
      // body: JSON.stringify(),
      // headers: {
      //   'Content-Type': 'application/json'
      // }
    })

    let d = await resp.json();

    // console.log("getScenery resp", d);

    if(d && d.features) {
      // console.log("MyScenery features", d);
      this.myscenery = d.features;
    } else {
      this.myscenery = {};
    }
  }

  async getCreatures() {
    console.log("BACKEND getCreatures");

    let resp = await fetch('/api/getCreatures', {
      method: 'POST',
      // body: JSON.stringify({ creatureId: this.treeId }),
      // headers: {
      //   'Content-Type': 'application/json'
      // }
    })

    let d = await resp.json();

    this.trees = [];
    this.flowers = [];

    //console.log("Fetch result", d);
    // let canvas = this.canvas.current;
    // let ctx = canvas.getContext('2d') //// 3 - access node using .current

    for (let c of d) {
      if (!c.data) continue;

      if (c.type == "tree") {
        if (c.data.size < 50) continue;

        // console.log("Creature:", c);

        //console.log("DRAW:", c);

        var image = new Image();
        image.src = c.img
        await image.decode();

        let pos = false;

        if (!this.myscenery) this.myscenery = {};

        if (c._id in this.myscenery) {
          pos = this.myscenery[c._id].pos;
        } else {
          this.myscenery[c._id] = {
            type: 'tree',
            pos: pos
          }
        }

        let newtree = false;
        if (!this.myscenery[c._id].pos) {
          this.myscenery[c._id].pos = this.getTreePos();
          newtree = true;
          console.log("NNNNNNNEW")
        }

        this.trees.push({
          img: image,
          pos: this.myscenery[c._id].pos,
          new: newtree
        });
      }

      if (c.type == "flower") {
        if (c.data.size < 30) continue;

        // console.log("Creature:", c);

        //console.log("DRAW:", c);

        var image = new Image();
        image.src = c.img
        await image.decode();

        let pos = false;

        if (!this.myscenery) this.myscenery = {};

        if (c._id in this.myscenery) {
          pos = this.myscenery[c._id].pos;
        } else {
          this.myscenery[c._id] = {
            type: 'flower',
            pos: pos
          }
        }

        let newflower = false;
        if (!this.myscenery[c._id].pos) {
          this.myscenery[c._id].pos = this.getFlowerPos();
          newflower = true;
          console.log("NNNNNNNEW")
        }
        //this.myscenery[c._id].pos = this.getFlowerPos();

        this.flowers.push({
          img: image,
          pos: this.myscenery[c._id].pos,
          new: newflower
        });
      

      }
      // }

    }

    // console.log("MYSCENERY: ", this.myscenery);

    //this.treeImg = d.img;
  }

  getDistanceFromExistingTrees(pos) {
    //let [x,y]=pos;

    let mindist = 100000;
    for (let t of this.trees) {
      let p = t.pos;

      //let dist = Math.sqrt((pos[0] - p[0]) ** 2 + (pos[1] - p[1]) ** 2);
      let dist = Math.abs((pos[0] - p[0])); // just x coord
      //console.log("dist", dist);

      if (dist < mindist) mindist = dist;
    }

    return mindist;

  }

  getTreePos() {

    let bestp = false;
    let maxdist = 0;

    // get some random positions
    for (let i = 0; i < 100; i++) {
      let pos = this.getPositionInArea(this.features.mid.trees.area);

      let dist = this.getDistanceFromExistingTrees(pos);
      //console.log("DIST", dist);

      // suitable?
      if (dist > 100) return pos;

      if (dist > maxdist) {
        maxdist = dist;
        bestp = pos;
      }
    }

    return bestp;
  }


  getFlowerPosOLD() {
    let x, y;

    let d = Math.random()*20-10;
    let m = 30;

    if(Math.random()<0.5) {
      if(Math.random()<0.5) x = m+d;
      else x = this.cw - m+d
  
      y = Math.random() * (this.ch/2-2*m) + m+d;  

    } else {
      //if(Math.random()<0.5) y = m+d;
      //else y = this.ch - m+d;
      y = m+d;

      x = Math.random() * (this.cw-2*m) + m+d;  

    }


    return [x, y];

  }
  
  getFlowerPos() {

    let area = [[15,239],[256,240],[251,321],[113,329],[15,359],[15,359],];
    let pos = this.getPositionInArea(area);

    return pos;

  }
  
  getTreePosOLD() {

    let bestp = false;
    let maxdist = 0;

    // get some random positions
    for (let i = 0; i < 10; i++) {
      let pos = this.getPositionInArea(this.features.mid.trees.area);

      let dist = this.getDistanceFromExistingTrees(pos);
      //console.log("DIST", dist);

      // suitable?
      if (dist > 100) return pos;

      if (dist > maxdist) {
        maxdist = dist;
        bestp = pos;
      }
    }

    return bestp;
  }


  async drawFlowers() {
    if (!this.flowers) return;
    // console.log("drawFlowers", this.flowers);

    this.flowers.sort((t1, t2) => {
      return t1.pos[1] - t2.pos[1]; // sort by y coordinate for drawing
    })

    for (let t of this.flowers) {
      await this.drawOneFlower(t);

    }

  }

  async drawOneFlower(tree) {

    // console.log("DRAW FLOWER", tree);

    // let bgcanvas = this.bgcanvas.current;
    // if(bgcanvas === none) return;
    // let bgctx = bgcanvas.getContext('2d') //// 3 - access node using .current

    // let fgcanvas = this.fgcanvas.current;
    // let fgctx = fgcanvas.getContext('2d') //// 3 - access node using .current

    let canvas = this.canvas.current;
    if(canvas === null) return;

    let ctx = canvas.getContext('2d')
    if(ctx === null) return;


    // let x = Math.random() * 1280;
    // let y = Math.random() * 100 + 400;
    let size = 50;

    let [x, y] = tree.pos;
    let image = tree.img;


    // ctx.beginPath();
    // ctx.arc(x, y, 10, 0, 2*3.14);
    // ctx.fill();

    //flowers are approx 50 pixels in radius

    let scale = size / image.height * 2/3;

    let tx = x - image.width / 2 * scale;
    let ty = y - image.height /2 * scale;

    ctx.strokeStyle = "darkgreen";
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x, y+10);
    ctx.stroke();


    if(tree.new) await this.animate(image, image.width, image.height, tx, ty, image.width * scale, image.height * scale);
    ctx.drawImage(image, 0, 0, image.width, image.height, tx, ty, image.width * scale, image.height * scale);

    // ctx.beginPath();
    // ctx.arc(x, y, 5, 0, 2 * 3.14);
    // ctx.fill();
  }

  async drawTrees() {
    if (!this.trees) return;
    // console.log("drawTrees", this.trees);

    this.trees.sort((t1, t2) => {
      return t1.pos[1] - t2.pos[1]; // sort by y coordinate for drawing
    })

    for (let t of this.trees) {
      await this.drawOneTree(t);

    }

  }

  async drawOneTree(tree) {

    // console.log("DRAW TREE", tree);

    let bgcanvas = this.bgcanvas.current;
    if(bgcanvas === null) return;

    let bgctx = bgcanvas.getContext('2d') //// 3 - access node using .current
    if(bgctx === null) return;

    let fgcanvas = this.fgcanvas.current;
    if(fgcanvas === null) return;

    // let fgctx = fgcanvas.getContext('2d') //// 3 - access node using .current


    // let x = Math.random() * 1280;
    // let y = Math.random() * 100 + 400;
    let treeSize = 400;

    let [x, y] = tree.pos;
    let image = tree.img;


    // ctx.beginPath();
    // ctx.arc(x, y, 10, 0, 2*3.14);
    // ctx.fill();

    let scale = treeSize / image.height;

    let tx = x - image.width / 2 * scale;
    let ty = y - image.height * 2 / 3 * scale;

    if(tree.new) await this.animate(image, image.width, image.height, tx, ty, image.width * scale, image.height * scale);
    bgctx.drawImage(image, 0, 0, image.width, image.height, tx, ty, image.width * scale, image.height * scale);

    // ANIMATE
    // let ii = 10;
    // for (let i = 0; i <= ii; i += 1) {
    //   await this.sleep(10);

    //   let a = 1 - i / ii;

    //   fgctx.clearRect(0, 0, fgcanvas.width, fgcanvas.height);
    //   //fgctx.filter = 'invert('+a+')';

    //   let b = ii - i
    //   fgctx.filter = 'blur(' + b + 'px) invert(' + a + ')';

    //   fgctx.drawImage(image, 0, 0, image.width, image.height, tx, ty, image.width * scale, image.height * scale);

    //   if (i === ii) {
    //     ctx.drawImage(image, 0, 0, image.width, image.height, tx, ty, image.width * scale, image.height * scale);
    //   }

    // }


    

    //console.log("w", image.height);
  }

  async animate(image, w, h, tx, ty, iw, ih) {
    let newcanvas = this.newcanvas.current;
    let newctx = newcanvas.getContext('2d') //// 3 - access node using .current

    //newctx.drawImage(image, 0, 0, w, h, tx, ty, iw, ih);
    //let idata = newctx.getImage(0, 0, newcanvas.width, newcanvas.height);

    // ANIMATE
    let ii = 10;
    for (let i = 0; i <= ii; i += 1) {
      await this.sleep(50);

      let a = 1 - i / ii ;

      newctx.clearRect(0, 0, newcanvas.width, newcanvas.height);
      //fgctx.filter = 'invert('+a+')';

      let b = ii - i;
      b *= 10;

      let m = (ii-i)/ii;
      
      // newctx.filter = 'blur(' + b + 'px) invert(' + a + ')';
      newctx.filter = 'invert(' + a + ')';

      //newctx.drawImage(image, 0,0, w, h, tx-w*m/2, ty-w*m/2, iw*(1+m), ih*(1+m));
      newctx.drawImage(image, 0,0, w, h, tx, ty, iw, ih);
      //newctx.drawImage(idata,0,0);

      if (i === ii) {
        //newctx.drawImage(image, 0, 0, image.width, image.height, tx, ty, image.width * scale, image.height * scale);
      }

    }

    newctx.filter = '';
    newctx.clearRect(0, 0, newcanvas.width, newcanvas.height);

  }

  async drawFeatures() {
    // let bgcanvas = this.bgcanvas.current;
    let canvas = this.canvas.current;
    if(canvas === null) return;

    // console.log("drawFeatures");
    // console.log("this.myscenery", this.myscenery);

    for (let f of this.features.fg) {
      //console.log("features:", f.id);

      if (!this.myscenery) this.myscenery = {};
      if (!(f.id in this.myscenery)) continue;

      let newfeature = false;

      if (!this.myscenery[f.id].pos) {
        this.myscenery[f.id].pos = this.getPositionInArea(f.area);
        newfeature=true;
        console.log("NNNNNNNEW FEATURE")
      }

      var image = new Image();
      image.src = './media/features/' + f.img;
      await image.decode();

      let scale = f.scale;

      let ctx = canvas.getContext('2d')
      if(ctx === null) return;

      // for (let i = 0; i < 5000; i++) {
      //   let [x, y] = this.getPositionInArea(f.area);
      //   ctx.beginPath();
      //   ctx.arc(x, y, 1, 0, 2 * 3.14);
      //   ctx.fill();
      // }


      let [x, y] = this.myscenery[f.id].pos;
      let tx = x - image.width / 2 * scale;
      let ty = y - image.height * scale;

      if(newfeature) {
        await this.animate(image, image.width, image.height, tx, ty, image.width * scale, image.height * scale)
      }
      ctx.drawImage(image, 0, 0, image.width, image.height, tx, ty, image.width * scale, image.height * scale);


    }

  }

  getPositionInArea(area) {
    let xa = 0;
    let ya = 0;

    let n = area.length;

    //console.log("Points on area", n);

    for (let [xi, yi] of area) {
      xa += xi;
      ya += yi;
    }
    xa /= n;
    ya /= n;

    //console.log("center: ", xa, ya);

    // get random point on perimeter
    let i = Math.floor(Math.random() * n);
    let [xr1, yr1] = area[i];
    let [xr2, yr2] = area[(i + 1) % n];

    let r = Math.random();
    let xr = xr1 + r * (xr2 - xr1);
    let yr = yr1 + r * (yr2 - yr1);

    // get final point
    r = Math.random();
    let x = xr - r * (xr - xa);
    let y = yr - r * (yr - ya);

    return [x, y];
  }

  componentDidMount() {
    // console.log("SCENERY )))))))))))))))))))))");

    let canvas = this.canvas.current;
    let bgcanvas = this.bgcanvas.current;
    let fgcanvas = this.fgcanvas.current;
    let newcanvas = this.newcanvas.current;

    this.width = canvas.width = this.cw;
    this.height = canvas.height =this.ch;

    newcanvas.width = bgcanvas.width = fgcanvas.width = this.width;
    newcanvas.height = bgcanvas.height = fgcanvas.height = this.height;

    //console.log("CANVAS", canvas.style.width, canvas.style.height);
    //console.log("bgcanvas", bgcanvas);

    this.drawScenery();

    window.addEventListener('resize', this.handleResize.bind(this));
    this.handleResize();

    newcanvas.addEventListener('mousedown', this.mouseClick.bind(this));


  }

  mouseClick(e) {
    return;
    
    let newcanvas = this.newcanvas.current;
    let ctx = newcanvas.getContext('2d') //// 3 - access node using .current

    const rect = newcanvas.getBoundingClientRect()

    let x = Math.floor((e.clientX - rect.left) / this.scale);
    let y = Math.floor((e.clientY - rect.top) / this.scale);

    console.log("x: " + x + " y: " + y, rect)


    ctx.beginPath();
    ctx.arc(x, y, 5, 0, 2 * 3.14);
    ctx.fill();

    if (!this.area) this.area = [];

    if (x < 10 && y < 10) this.area = [];
    else this.area.push([x, y]);

    let s = '[';
    for ([x, y] of this.area) {
      s += '[' + x + ',' + y + '],';
    }
    s += ']';

    console.log(s);
  }

  handleResize() {
    let canvas = this.canvas.current;
    let bgcanvas = this.bgcanvas.current;
    let fgcanvas = this.fgcanvas.current;
    let newcanvas = this.newcanvas.current;

    if (canvas) {

      //console.log("resize");

      let scale1 = (window.innerHeight-80) / this.ch;
      let scale2 = window.innerWidth / this.cw;

      // console.log("scales", scale1, scale2);

      this.scale = scale1;
      if (scale2 < this.scale) this.scale = scale2;

      canvas.style.height = Math.floor(canvas.height * this.scale) + "px";
      canvas.style.width = Math.floor(canvas.width * this.scale) + "px";

      newcanvas.style.height = bgcanvas.style.height = fgcanvas.style.height = canvas.style.height;
      newcanvas.style.width = bgcanvas.style.width = fgcanvas.style.width = canvas.style.width;

    }
  }

  async drawScenery() {

    //let ctx = canvas.getContext('2d') //// 3 - access node using .current

    //ctx.beginPath();
    //ctx.save();

    // this.img = new Image();
    // this.img.onload = this.backgroundLoaded.bind(this);
    // this.img.src = './media/scenery/scenery1.png';

    let canvas = this.canvas.current;
    let bgcanvas = this.bgcanvas.current;


    var image = new Image();
    image.src = './media/features/scene_bg.webp';
    await image.decode();

    let ctx = bgcanvas.getContext('2d')
    ctx.drawImage(image, 0, 0);

    image = new Image();
    image.src = './media/features/scene_fg.webp';
    await image.decode();

    ctx = canvas.getContext('2d')
    if(ctx === null) return;
    ctx.drawImage(image, 0, 0);


    // await this.saveScenery(
    //   {
    //     dog: { type: 'asset' },
    //     house: { type: 'asset' },
    //     horse: { type: 'asset' },
    //     house_logs: { type: 'asset' },
    //     house_bench: { type: 'asset' },
    //     house_flower2: { type: 'asset' },
    //     foal: { type: 'asset' },
    //     horse2: { type: 'asset' },
    //     horse3: { type: 'asset' },
        
    //     bug1: { type: 'asset' },
    //     bug2: { type: 'asset' },
    //     bug3: { type: 'asset' },

    //     butterfly1: { type: 'asset' },
    //     butterfly2: { type: 'asset' },
    //     butterfly3: { type: 'asset' },

    //   });

    await this.getScenery();
    await this.getCreatures();

    await this.drawFeatures();
    await this.drawTrees();
    await this.drawFlowers();

    await this.saveScenery(this.myscenery);

    await this.saveSceneryImage(); // for caching


    // reload!
    // this.context.reload();


    //ctx.restore();
  }

  render() {
    //console.log("render");



    return (
      //<Paper sx={{ width: 1, height: 1 }} margin='auto'>

      /* <div style={{
                  width:'100%'
                }}>
                  <canvas ref={this.canvas}>
                    <p>Add suitable fallback here.</p>
                  </canvas>
                </div> */

      <div>
        <div style={{
          position: "fixed", zIndex: -3,
          width: "100%", top: 70, left: 0
        }}>
          <div style={{
            marginLeft: "auto", marginRight: "auto"
          }}>
            <canvas ref={this.bgcanvas}>
              <p>Add suitable fallback here.</p>
            </canvas>
          </div>
        </div>

        <div style={{
          position: "fixed", zIndex: -2,
          width: "100%", top: 70, left: 0
        }}>
          <div style={{
            marginLeft: "auto", marginRight: "auto"
          }}>
            <canvas ref={this.canvas}>
              <p>Add suitable fallback here.</p>
            </canvas>
          </div>
        </div>

        <div style={{
          position: "fixed", zIndex: -1,
          width: "100%", top: 70, left: 0
        }}>
          <div style={{
            marginLeft: "auto", marginRight: "auto"
          }}>
            <canvas ref={this.fgcanvas}>
              <p>Add suitable fallback here.</p>
            </canvas>
          </div>
        </div>

        <div style={{
          position: "fixed", zIndex: 0,
          width: "100%", top: 70, left: 0
        }}>
          <div style={{
            marginLeft: "auto", marginRight: "auto"
          }}>
            <canvas ref={this.newcanvas}>
              <p>Add suitable fallback here.</p>
            </canvas>
          </div>
        </div>


      </div>

      //</Paper>
    );

  }
}
