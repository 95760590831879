import React from 'react'
import Wave from 'react-wavify'
// import wt from 'discrete-wavelets';
import { Howl } from 'howler'
import { CalcScore } from '../scripts/calc_score';
import { CalcSessionStats } from '../scripts/calc_sessionstats';

import Backdrop from '@mui/material/Backdrop';
// import BackdropUnstyled from '@mui/base/BackdropUnstyled';
import Fab from '@mui/material/Fab';
// import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CircularProgress, Typography } from '@mui/material';

// import AddIcon from '@mui/icons-material/Add';
// import EditIcon from '@mui/icons-material/Edit';
// import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
// import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
// import { ConstructionOutlined, ThirtyFpsSelectOutlined } from '@mui/icons-material';
// import WaveResultPlot from '../WaveResultPlot';
import WaveResultCard from '../WaveResultCard';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

// import ScoreBox from '../ScoreBox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import { CardContent } from '@mui/material';

// import { session } from 'passport';
import { MyContext } from '../MyContext';

class ImageHabituation extends React.Component {
    static contextType = MyContext;

    constructor(props) {
        super(props);

        this.canvas = React.createRef()

        this.col1 = [178, 137, 239, 0.7];
        this.col2 = [150, 97, 255, 0.5];

        this.color1 = this.rgba(this.col1);
        this.color2 = this.rgba(this.col2);

        this.disturbData = [];

        let params = props.params;

        this.onTime = this.props.params.onTime;

        if (params.backgroundSound) {
            this.bgPlayer = new Howl({
                src: [props.params.backgroundSound],
                html5: true,
                loop: true
            })
        }

        this.players = []
        this.fired = []

        this.imgId = this.bgImgId = -1;


        if (process.env.NODE_ENV === 'development') this.test = true;
        this.simscore = 100;

        this.decr_ended_ts = false;



        this.calc_score = new CalcScore(params);
        this.calc_stats = new CalcSessionStats();


        if ("disturb" in params) {
            for (let i = 0; i < params.disturb.length; i++) {

                let loop = false;
                if ("loop" in params.disturb[i]) {
                    loop = params.disturb[i].loop;
                }

                let volume = 1;
                if ("volume" in params.disturb[i]) {
                    volume = params.disturb[i].volume;
                }

                console.log("loop = ", loop);

                let player = new Howl({
                    src: [params.disturb[i].sound],
                    html5: true,
                    loop: loop,
                    volume: volume
                })

                this.players.push(player);
                this.fired.push(false);
            }
        }



        this.eda = [];

        this.scores = [];

        this.sclResult = 0;
        this.scrResult = 0;

        this.n = 0;


        this.t = 10;
        this.scl = 0;
        this.scr = 0;
        this.start = Date.now();
        this.end = 0;

        this.state = {
            sclScore: 100,
            scrScore: 100,
            showResult: false,
            askFeedback: false,
            textAreaValue: "",
            bImg: params.backgroundImage,
            animalType: "",
            animalTypeSelected: false
        }

        if (params.duration) {
            const dur = params.duration;
            console.log("Waves2Phase duration: " + dur);

            this.timeout = setTimeout(() => {
                this.ended();
            }, dur);
        }

    }

    async componentDidMount() {

        console.log("mounted");
        if (this.bgPlayer) this.bgPlayer.play();





    }

    mutePlayers() {
        if (this.bgPlayer) this.bgPlayer.stop();

        for (let i = 0; i < this.players.length; i++) {
            this.players[i].stop();
        }

    }

    componentWillUnmount() {
        console.log("ImageHabituation componentWillUnmount()");

        this.mutePlayers();

    }


    finished() {
        console.log("ImageHabituation finish()");
        // on finish, we do not send data, because we already called store_cb in "ended"
        this.props.finished_cb({}, false);


    }

    async save() {
        this.setState({ saving: true });

        let sessionData = {
            eda: this.eda,
            scores: this.scores,
            feedback: this.state.textAreaValue,
        };

        if (this.disturbData) sessionData.disturbData = this.disturbData;

        let sessionId = await this.props.store_cb(sessionData);

        console.log("**************************** Session ID:", sessionId);

        //Now read back
        // await fetch

        this.setState({ showResult: true, askFeedback: false, sessionData: sessionData, saving: false });

    }


    async ended() {
        if (this.endedCalled) return;
        this.endedCalled = true;

        this.mutePlayers();
        clearTimeout(this.timeout);

        this.setState({ askFeedback: true, ended: true });
        console.log("Ended");


    }

    drawSubl() {
        console.log("drawSubl");

        let canvas = this.canvas.current;

        let scale1 = window.innerHeight / 480;
        let scale2 = window.innerWidth / 640;

        let scale = scale2;
        if(scale2>scale1) scale = scale1;

            // update canvas size
        this.width = canvas.width = 640;
        this.height = canvas.height = 480;
        this.ctx = canvas.getContext('2d')


        canvas.style.height = Math.floor(canvas.height * scale) + "px";
        canvas.style.width = Math.floor(canvas.width * scale) + "px";

        //this.ctx.fillStyle = "#FF0000";
        //this.ctx.fillRect(0, 0, this.width, this.height);


        this.ctx.drawImage(this.image, 0, 0);

        if(this.onTime)
        setTimeout(() => {
            this.clearSubl();
        }, this.onTime);


    }

    clearSubl() {
        console.log("XXXX clearSubl");

        let canvas = this.canvas.current;

        if(canvas) {
            this.ctx.clearRect(0, 0, canvas.width, canvas.height);
            //this.ctx.drawImage(this.bgImage, 0, 0);
        }

    }

    async prepareImg() {


        this.image = new Image();
        //this.image.src = './media/spider1.png';
        this.image.src = this.props.params.images[this.state.animalType];

        console.log("image src:", this.image.src);

        await this.image.decode();

        console.log("Prepare IMG OK:", this.image.width, this.image.height);

        // this.bgImage = new Image();
        //this.bgImage.src = './media/spider1.png';
        //this.bgImage.src = this.props.params.bgImages[this.bgImgId];;
        //await this.bgImage.decode();

    }

    onEda(gsr, acc) {
        if (this.state.ended) {
            return;
        }


        let ts = Date.now();

        if (!this.lastts) {
            this.lastts = ts;
            return;
        }

        if (!this.lastSubl) this.lastSubl = ts;

        if (this.decr_ended_ts && ts - this.lastSubl > this.props.params.offTime) {
            //if(ts - this.lastSubl > 10000) {

            console.log("====================== DRAW IMAGE");
            // window.requestAnimationFrame(this.drawSubl.bind(this));
            this.drawSubl();

            this.lastSubl = ts;
        }


        // if (ts - this.start < 6000) {
        //     this.setState({ backdropLabel: "Your job is to calm your waves. Focus on the waves and do NOT close your eyes. You can finish the session by pressing the button above" });
        // } else {
        //     if ((!this.calc_stats.decr) && this.state.backdropLabel) this.setState({ backdropLabel: false });
        // }

        // if (ts - this.start < 1000 && (!this.firstTextShown)) {
        //     this.firstTextShown = true;
        //     this.setState({ backdropLabel: "Focus on the waves. To finish press the button above." });
        //     setTimeout(() => { this.setState({ backdropLabel: false }) }, 6000);

        // }


        this.eda.push([ts, gsr])

        let [sl, sr] = this.calc_score.calc_one(ts, gsr);

        if (this.test) {
            sl = this.simscore;
            sr = sl;

            //console.log("SIMSCORE", this.simscore);
        }

        this.calc_stats.calc_one(ts, sl, sr);
        this.scores.push([ts, sl, sr]);

        this.setState({ sclScore: sl, scrScore: sr });


        let score = Math.max(sl, sr);

        if(score<0) {
            score=0;
        }

        if((!this.zeroReached) && score === 0) {
            this.zeroReached = ts;
        }

        if(this.zeroReached && ts - this.zeroReached > 1000) {
            console.log("Zero reached....");
            this.ended();
        }

        // when we passed the decr state ====================
        if (this.calc_stats.decr) {
            if (!this.decr_ended_ts) this.decr_ended_ts = ts;
            //score = 70;

            let y = 100 - score;
            if (y < 0) y = 0;

            // between 0 and 100

            // let a = 0.1;
            // if (this.test) a = 1;

            let d = y / 100;
            d /= 2;
            d += 0.5;

            let col1_b = [255 * d, 152 * d, 0 * d, 0.7];
            let col2_b = [183 * d, 77 * d, 0 * d, .5];


            // blend the two colors
            if (ts - this.decr_ended_ts < 6000) {
                let u = (ts - this.decr_ended_ts) / 6000;
                this.color1 = this.fade(this.col1, col1_b, u);
                this.color2 = this.fade(this.col2, col2_b, u);

                console.log("FADE", u, this.col1, col1_b, this.color1);

                if (!this.sustainTextShown) {
                    this.sustainTextShown = true;
                    this.setState({ backdropLabel: "Excellent! Now try to keep your waves calm as long as you want." });
                    setTimeout(() => { this.setState({ backdropLabel: false }) }, 6000);
                }


            } else {
                this.color1 = this.rgba(col1_b);
                this.color2 = this.rgba(col2_b);

                this.setState({ showLabel2: false });

            }

            //this.color1 = this.rgb(178*d, 137*d, 239*d);
            //this.color2 = this.rgba(150*d, 97*d, 255*d, .8);


        }

        if ("disturb" in this.props.params) {

            let ndist = this.props.params.disturb.length;

            let ii = -1;

            for (let i = 0; i < ndist; i++) {

                let level = this.props.params.disturb[i].level;
                let hist = this.props.params.disturb[i].hist;

                if (this.fired[i]) {
                    if (Date.now() - this.fired[i] > 20000) {
                        this.fired[i] = false;
                    }
                }

                if (this.fired[i] === false) {
                    if (score < level && score > level - hist) {
                        ii = i;
                        console.log("DD: ", ii);
                    }
                }
            }

            if (ii >= 0) {
                console.log("DISTURB:", ii);
                this.players[ii].play();
                this.disturbData.push([Date.now(), ii]);
                this.fired[ii] = Date.now();
            }
        }

        // if ("difficulty" in this.props.params) {
        //     let params = this.props.sectionData.params;

        //     for (let i = 0; i < this.props.sectionData.difficulty.length; i++) {

        //         if (score < this.props.sectionData.difficulty[i].level) {
        //             params = this.props.sectionData.difficulty[i].params;
        //         }

        //     }

        //     console.log("DIFFICULTY", JSON.stringify(params));
        //     this.calc_score.set_params(params);
        // }

        this.lastts = ts;

    }

    lerp = function (a, b, u) {
        return (1 - u) * a + u * b;
    };

    fade = function (start, end, u) {

        var r = Math.round(this.lerp(start[0], end[0], u));
        var g = Math.round(this.lerp(start[1], end[1], u));
        var b = Math.round(this.lerp(start[2], end[2], u));
        var a = this.lerp(start[3], end[3], u);

        //console.log("CCFADE ",start[3], end[3], u, a);

        return `rgba(${r}, ${g}, ${b}, ${a})`
    };

    rgba(c) {
        return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${c[3]})`
    }

    siminc() {
        this.simscore += 5;
    }

    simdec() {
        this.simscore -= 5;
        if (this.simscore < 0) this.simscore = 0;
    }

    handleTextChange(event) {
        this.setState({ textAreaValue: event.target.value });

    }

    handleAnimalTypeChange(event) {
        console.log("handleAnimalTypeChange", event);
        this.setState({ animalType: event.target.value });
    }

    async feedback() {
        await this.save();
        this.setState({ askFeedback: false });
    }

    handleAnimalButton() {
        this.setState({ animalTypeSelected: true });

        this.prepareImg();

    }

    render() {

        
        if (this.state.saving) {
            return (
                <div>
                    <p>Saving...</p>
                    <CircularProgress />
                </div>
            );
        }

        
        if (this.state.animalTypeSelected === false) {
            // console. log("XXX", this.props.params.images);
            let disabled = true;
            if(this.state.animalType!=='') disabled = false;

            let s = [];
            for(let animal in this.props.params.images) {
                // console.log("AA", animal);
                s.push(
                    <MenuItem value={animal}>{animal}</MenuItem>
                )
            }
            

            return (
                <div>
                <br></br>
                <FormControl >
                    <InputLabel id="demo-simple-select-label">Please select</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.animalType}
                        label=""
                        onChange={this.handleAnimalTypeChange.bind(this)}
                    >
                        {s}

                    </Select>

                    <Button sx={{ m: 5 }} disabled={disabled} variant='contained' color='primary' onClick={this.handleAnimalButton.bind(this)}>Continue</Button>

                </FormControl>
                </div>
            )
        }

        if (this.state.askFeedback) {
            return (
                <Box maxWidth='90%' margin={3} sx={{ flexDirection: 'column' }}>

                    <br></br>

                    <Typography>
                        If you wish, you can enter your thoughts here:
                    </Typography>

                    <br></br>

                    <TextareaAutosize
                        aria-label="minimum height"
                        minRows={5}

                        //placeholder="If you wish, you can enter your thoughts here..."
                        value={this.state.textAreaValue}
                        onChange={this.handleTextChange.bind(this)}
                        style={{ width: '90%' }}
                    />

                    <br></br>

                    <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.feedback.bind(this)}>Continue</Button>

                </Box>
            );
        }

        if (this.state.showResult) {

            //let final_score = this.calc_score.calc_final_score(this.scores);


            //let total = Math.floor(this.state.final_score.total);

            //let rank = this.calcRank(this.state.hist, total);

            //console.log("Show RESULT", this.state.sessionData);

            let ses = {
                exercise: this.props.exercise,
                campaign: this.props.campaign,
                data: this.state.sessionData,
                ts: Date.now(),
                device: this.context.deviceName,
                user: this.context.user
            }


            return (
                <Box maxWidth='90%' margin={3} sx={{ flexDirection: 'column' }}>

                    <WaveResultCard session={ses}></WaveResultCard>

                    <br></br>

                    <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.finished.bind(this)}>SAVE SESSION</Button>

                </Box>
            );
        }


        let a1 = this.state.sclScore;
        let a2 = this.state.scrScore;

        if (a1 > 100) a1 = 100;
        if (a2 > 100) a2 = 100;

        // const style = {
        //     margin: 0,
        //     top: 'auto',
        //     right: 20,
        //     bottom: 20,
        //     left: 'auto',
        //     position: 'fixed',
        // };

        let h = "100%";
        h = "0";

        return (


            <div>

                {this.state.bImg &&
                    <div style={{ position: 'absolute', left: '0px', top: 0, zIndex: '-10', height: "100%", width: "100%" }}>
                        <img
                            src={this.state.bImg}
                            style={{ height: "100%", width: "100%", objectFit: "cover" }}
                        />
                    </div>

                }

                {/* <div>
                    <div style={{
                        position: "fixed",
                        zIndex: -5, width: "100%", top: 0, left: 0
                    }}>
                        <div style={{
                            marginLeft: "auto", marginRight: "auto"
                        }}>
                            <canvas ref={this.canvas}>
                                <p>Add suitable fallback here.</p>
                            </canvas>
                        </div>
                    </div>
                </div> */}

                <div>
                    <div style={{
                        position: 'absolute', left: '0px', top: 0, zIndex: '-2', height: "90%", width: "90%"
                    }}>
                        <div style={{
                            height: "90%", width: "90%", objectFit: "cover"
                        }}>
                            <canvas ref={this.canvas}>
                                <p>Add suitable fallback here.</p>
                            </canvas>
                        </div>
                    </div>
                </div>


                {this.state.backdropLabel &&
                    <Backdrop
                        sx={{
                            p: 1, color: 'black', zIndex: 0, background: '#fff0',
                            textShadow: 'white 0.1em 0.1em 0.2em, white -0.1em -0.1em 0.2em'
                        }}
                        // open={this.state.backdropLabel}
                        open={true}
                        transitionDuration="5000"
                    >
                        {this.state.backdropLabel}
                    </Backdrop>}

                <Wave fill={this.color1}
                    paused={false}
                    options={{
                        height: 50,
                        amplitude: a1,
                        speed: 0.15,
                        points: 2
                    }}
                    style={{ position: 'absolute', left: '0px', top: '50%', zIndex: '-4', height: "50%" }}
                ></Wave>

                <Wave fill={this.color2}
                    paused={false}
                    options={{
                        height: 50,
                        amplitude: a2,
                        speed: 0.15,
                        points: 10
                    }}
                    style={{ position: 'absolute', left: '0px', top: '50%', zIndex: '-3', height: "50%" }}
                ></Wave>

                <Fab variant="contained" color="primary" >
                    <CheckCircleIcon onClick={this.ended.bind(this)} />
                </Fab>

                {this.test &&
                    <Fab variant="contained" color="primary" >
                        <NavigationIcon onClick={this.siminc.bind(this)} />
                    </Fab>}
                {this.test &&
                    <Fab variant="contained" color="primary" >
                        <NavigationIcon onClick={this.simdec.bind(this)} />
                    </Fab>}


            </div>


        );

    };
}

export default ImageHabituation;