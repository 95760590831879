

export default function adjust_timesteps(a) {
    let t0 = 0;
    let mindt = 1e9;
    let maxdt = -1e9;
    let i=0;

    // Check for minimum tsdiff
    for (let [ts, v] of a) {
      if (t0 == 0) t0 = ts;

      // corrected ts
      let ts_virt = t0 + 250 * i;

      let dt = ts - ts_virt;

      // console.log("a XXX dt", dt);

      if (dt < mindt) mindt = dt;
      if (dt > maxdt) maxdt = dt;

      i++;

    }
    console.log("ADJTS mindt", mindt, "maxdt", maxdt);

    let acorr=[];
    i=0;
    for (let [ts, v] of a) {
        let ts_virt = (t0+mindt) + 250 * i;

        acorr.push([ts_virt, v]);

        i++;
    }

    return acorr;
}
