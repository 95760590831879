import Typography from '@mui/material/Typography';

export const instruction_ctw_data = {
    ctw_basic: {
        en: (
            <div>
                <Typography variant="body1" align='justify' color="text.primary">
                    CTW stands for Calm The Waves. Waves reflect your arousal/stress
                    level as measured by the EDA device (Electrodermal Activity device).
                    <br></br>
                    <br></br>
                    In this exercise, you are going to see a relaxing picture and a wave
                    reflecting your current arousal level. Your task is to watch the wave
                    and relax. Try to calm your wave. Occasional or even prolonged
                    periods of increased wave amplitudes are perfectly normal. Do not
                    fight it. Focus, and take your time.
                    <br></br>
                    <br></br>
                    Before the exercise, you will be asked to put on your Obimon EDA device. If
                    you do not know how, read the instruction, or ask for help.
                    <br></br>
                    <br></br>
                    <b>One more thing:</b> The more exercises you do, the more points you will
                    earn. Every time you decrease your stress level, you will be awarded
                    with so-called <b>relax points</b>. You can also track your progress on your
                    personal <b>country scenery</b>. When you reach certain milestones, you
                    will populate this scenery with objects e.g., a house, trees, and
                    animals.
                    <br></br>
                    <br></br>
                    <b>Happy growing!</b>
                    <br></br>
                    <br></br>
                    The exercise lasts for a maximum of 3 minutes, but you are free to finish at any
                    time. Just press the tick button on the top.
                    <br></br>
                    <br></br>
                </Typography>

                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                        src='media/features/csiko.png'
                        style={{ width: "200px", objectFit: "cover" }}
                    ></img>
                </div>
            </div>
        ),
        hu: (
            <div>
                <Typography variant="h6" align='justify' color="text.primary">
                    Hullámszelidítés alapfokon
                </Typography>

                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    A szervezet izgalmi (arousal) szintje jól jelzi az egyén érzelmi állapotát, az aktuális stressz mértékét.
                    Ezt a bőrről elvezetett elektrodermális aktivitás alapján tudjuk mérni.
                    <br></br>
                    <br></br>
                    A CWT (Calm The Waves) jelentése "szelídítsd meg a hullámaidat". Ebben a gyakorlatban egy megnyugtató háttérképet és egy hullámot fogsz látni a képernyőn, mely az aktuális
                    izgalmi szintedet jeleníti meg. A feladatod, hogy figyeld a hullámzást és relaxálj. Próbáld meg kisimítani a hullámot!
                    Könnyen előfordulhat, hogy időről időre erőteljesebb lesz a hullámzás. Ez teljesen helyénvaló, és akár hosszabb ideig
                    is jellemző lehet. Fontos, hogy ne harcolj ezzel, csak próbáld meg elengedni magad, figyelj és relaxálj.
                    <br></br>
                    <br></br>
                    A gyakorlat előtt fel kell helyezni és csatlakoztatni kell a bőr-feszültséget mérő Obimon EDA készüléket!
                    Amennyiben bizonytalan vagy a felhelyezés, vagy csatlakoztatás mikéntjével kapcsolatban, kérlek olvasd el az útmutatót,
                    vagy kérj segítséget!
                    <br></br>
                    <br></br>
                    <b>Még egy dolog:</b> minél többet gyakorolsz, annál több pontot gyűjtesz. Ezek a <b>relax pontok</b> azt jelképezik,
                    hogy mennyire tudod elengedni magad, amikor úgy döntesz! Egy-egy mérföldkőnél házat vagy állatokat kaphatsz.
                    Figyeld meg, ahogy egyre több részlettel <b>gazdagodik a tájképed</b>, hiszen egyre biztosabban tudsz már bánni az érzelmeiddel.
                    <br></br>
                    <br></br>
                    <b>Boldog gyarapodást!</b>
                    <br></br>
                    <br></br>
                    A gyakorlat maximum 3 percig tart, de Te bármikor megszakíthatod a képernyő tetején lévő gomb megnyomásával.
                    <br></br>
                    <br></br>
                </Typography>

                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                        src='media/features/csiko.png'
                        style={{ width: "200px", objectFit: "cover" }}
                    ></img>
                </div>
            </div>
        ),
    },


    ctw_0: {
        en: (

            <div>
                <Typography variant="body1" align='justify' color="text.primary">
                    This intermediate level exercise is a little bit more challenging than
                    the beginner CTW. To provide a more detailed insight into your state
                    of mind, your will be presented with two independent waves, instead
                    of just one. They represent two distinct aspects of your arousal. One
                    wave reflects gradual changes of your overall stress level. The other
                    wave is an indicator of small emotional bursts. These bursts may be
                    triggered by your thoughts or external stimuli.
                    <br />
                    <br />
                    Try to calm both waves!
                    <br />
                    <br />
                    When your waves subdue below a certain level, you will be rewarded
                    with a tree growing slowly from the ground. Try to keep your waves
                    steady and the tree will mature. It will grow leaves and fruits
                    eventually.
                    <br></br>
                    <br></br>
                    As you will see, each attempt at managing your emotions is different.
                    Thus, every tree is unique.
                    <br></br>
                    <br></br>
                    This task is challenging and will probably need several days of exercises
                    to master.
                    <br />
                    <br />
                    <b>Trees symbolize your hard work on this difficult but rewarding journey. </b>
                    <br />
                    <br />
                    As this exercise is much more complex than the basic level, your progress will be
                    evaluated with three distinct types of points. Besides <b>relax points</b>,
                    you can also earn <b>control points</b> and <b>balance points</b>:
                    <br></br>
                    <br></br>
                    <ul>
                        <li><b>Relax points</b> are awarded throughout the sessions every time you decrease your stress level.</li>
                        <li><b>Control points</b> measure how fast you can drive your waves down. </li>
                        <li><b>Balance points</b> are gained for keeping your waves calm once you have managed driving them down.</li>
                    </ul>
                    You can stop the exercise at any time. Recommended duration 5 minutes.
                </Typography>
            </div>
        ),
        hu: (
            <div>
                <Typography variant="h6" align='justify' color="text.primary">
                    Hullámszelídítés középfokon
                </Typography>

                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Ez a gyakorlat egy kicsit nagyobb kihívás, mint az, amit már alapfokon már elsajátítottál. Az aktuális
                    lelkiállapotodat itt már nem egy, hanem két hullámmal jellemeztük, melyek az izgalmi szint két
                    fontos összetevőjét jelenítik meg. Az egyik az általános stressz szint lassú változásait, míg a másik az
                    apró érzelmi kilengéseket jelzi vissza. Ezeket külső ingerek, de akár a saját gondolataid is
                    előidézhetik.
                    <br></br>
                    <br></br>
                    Próbáld meg mindkét hullámot lecsendesíteni!
                    <br></br>
                    <br></br>
                    Ha sikerül megszelídíteni a hullámokat, egy kis fácska kezd majd el kinőni a földből, szépen lassan.
                    Próbáld meg szinten tartani a már nyugodt érzelmi hullámaidat, és nézd, ahogy a kis facsemetéd
                    leveleket hajt és gyümölcsöt hoz.
                    <br></br>
                    <br></br>
                    Láthatod majd, hogy mindig máshogy sikerül majd úrrá lenni az érzelmeiden. Éppen ezért lesz
                    minden fa más és más.
                    <br></br>
                    <br></br>
                    Mivel ez egy nehéz feladat, így várhatóan több napig is kell majd gyakorolnod, hogy igazán jól
                    menjen.
                    <br></br>
                    <br></br>
                    <b>A fák, amiket növesztesz, fáradságos munkádat szimbolizálják a rögös úton, melyen végig mész.</b>
                    <br></br>
                    <br></br>
                    Mivel ez a gyakorlat sokkal összetetteb, mint az előző, itt már három különböző jutalom pontot is
                    tudsz majd gyűjteni. A <b>relax pontokon</b> kívül ún. <b>kontroll (control) pontokat</b> és <b>egyensúlyi (balance)
                        pontokat</b> is gyűjthetsz:
                    <br></br>
                    <br></br>
                    <lu>
                        <li><b>Relax pontokat</b> az egész gyakorlat alatt kaphatsz akkor, amikor lefelé viszed a stressz szintedet.</li>
                        <li>A <b>control pontok</b> azt mutatják meg, hogy milyen gyorsan tudod levinni az érzelmi hullámaidat.</li>
                        <li>A <b>balance pontokat</b> pedig azért kapod, ha sikeres vagy abban, hogy szelíden tartsd a hullámokat,
                            miután sikeresen lecsendesítetted őket.</li>
                    </lu>
                    <br></br>
                    A gyakorlatot bármikor megszakíthatod. Javasolt időtartam 5 perc.
                    <br></br>
                    <br></br>
                </Typography>
            </div>
        ),
    },


    ctw_sounds: {
        en: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    Stay focused!
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">
                    Remember when you finally felt relaxed, and then, a sudden noise
                    simply knocked you out of this wonderful state of mind? This
                    exercise will help you to
                    maintain your relaxed state, despite external
                    sounds.
                    <br></br>
                    <br></br>
                    The first step along this way is to eliminate effects of external
                    disturbances instantly as they try to interfere with your focus. Most
                    people react with a temporary arousal to any external sound. Arousal
                    is your mind's way to prepare for you for action. But since you hold
                    the control for your own emotional state, it is you, who can easily put
                    your focus back on track and restrain those waves!
                    <br />
                    <br />
                    <b>Observe how it feels when you take control.</b>
                    <br />
                    <br />
                    You can stop the exercise at any time. Recommended duration 5 minutes.
                </Typography>
            </div>
        ),
        hu: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    Összpontosíts!
                </Typography>
                <br></br>

                <Typography variant="h6" align='justify' color="text.primary">
                    Hullám-szelídítés hanghatások mellett (középfok)
                </Typography>

                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Emlékszel arra, amikor végre teljesen ellazultál, majd egy váratlan hanginger kibillentett ebből a
                    csodálatos lelkiállapotból? Ez a gyakorlat abban segít neked, hogy meg tudd tartani a nyugalmat
                    akkor is, amikor külső ingerek érnek.
                    <br></br>
                    <br></br>
                    Az első lépés ezen az úton az, hogy kiszűrd a külvilágból érkező zajokat azonnal, amikor azok
                    megzavarnának a koncentrálásban. A legtöbb ember izgalmi szintjét egy pillanatra megemelik a
                    külső hanghatások. Azonban mivel az érzelmi állapotod kulcsa nálad van, te vagy az, aki vissza tudod
                    terelni kisimult medrükbe a rakoncátlankodó hullámokat, amikor csak akarod.
                    <br></br>
                    <br></br>
                    <b>Figyeld meg milyen érzés amikor te irányítasz!</b>
                    <br></br>
                    <br></br>
                    A gyakorlatot bármikor megszakíthatod. Javasolt időtartam 5 perc.
                </Typography>
            </div>
        )
    },

    ctw_1: {
        en: (
            <div>
                <Typography variant="body1" align='justify' color="text.primary">
                    You have reached a level of mastery, where your stress management
                    will thrive! You might even find it easier to calm down in your
                    everyday life, even when circumstances are stressful. To keep, and to
                    grow this ability try the CTW advanced exercise. This is similar
                    to CTW intermediate, but much more challenging. Waves here are
                    more sensitive to changes in your arousal level, and thus, you will
                    need more emotional self-control to keep your waves down.
                    <br />
                    <br />
                    <b>You can do it! </b>
                    <br />
                    <br />
                    You can stop the exercise at any time. Recommended duration 5 minutes.
                </Typography>
            </div>
        ),
        hu: (
            <div>
                <Typography variant="h6" align='justify' color="text.primary">
                    Hullámszelídítés felsőfokon
                </Typography>
                <br></br>

                <Typography variant="body1" align='justify' color="text.primary">
                    Az érzelmeid irányítását már mester-szinten végzed, és ezáltal tudsz csak igazán fejlődni! Talán azt is
                    tapasztaltad, hogy a hullámszelídítésben alkalmazott módszereid néha a hétköznapi stresszes
                    helyzetekben is jól használhatók. Próbáld ki a hullámszelídítés felső fokú gyakorlatait és gyakorolj,
                    hogy érzelmeid irányításának képességét tovább fejleszd. Ez a gyakorlat nagyon hasonlít a korábbi
                    középszintű feladathoz, de nagyobb kihívást jelent. A hullámok ebben a feladatban sokkal
                    érzékenyebbek az arousal szintedben bekövetkező apró változásokra. Így magasabb fokú érzelmi
                    irányítás szükséges majd ahhoz, hogy a hullámok nyugodtak maradjanak.
                    <br></br>
                    <br></br>
                    <b>Meg tudod csinálni!</b>
                    <br></br>
                    <br></br>
                    A gyakorlatot bármikor megszakíthatod. Javasolt időtartam 5 perc.
                </Typography>
            </div>
        )
    },

    ctw_calm: {
        en: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    Divided attention
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">
                    If you have mastered CTW sounds, you are ready to accomplish
                    tasks that require divided attention. In the CTW music exercise you
                    will hear a relaxing music throughout the task. The music will change
                    slowly and gradually. Try to “listen in” the music, maybe even engage
                    in following it's tunes, WHILE keeping your waves calm. This should
                    be a breeze after the previous exercises and will help you prepare to
                    maintain an ideal arousal level while doing other tasks.
                    <br />
                    <br />
                    <b>Enjoy conscious control and multi-tasking!</b>
                    <br />
                    <br />
                    You can stop the exercise at any time. Recommended duration 5 minutes.
                </Typography>
            </div>
        ),
        hu: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    A figyelem megosztása
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">
                    Ha már sikeres vagy a hullám-szelídítésben különböző hanghatások mellett, készen állsz a figyelem
                    megosztását célzó feladatokra is. Ebben a feladatban egy megnyugtató zenét fogsz hallani a feladat
                    teljes időtartama alatt. Próbálj meg (akár időről időre) „behallgatni” a zenébe, MIKÖZBEN csendesen
                    tartod a hullámokat. Ha megy, próbáld meg magadban követni a dallamot. Ez valószínűleg könnyen
                    megy majd, mert az eddigi gyakorlással már temérdek tapasztalatot gyűjtöttél. Ez a gyakorlat
                    segíthet abban, hogy a hétköznapi feladataid elvégzése közben is ideális szinten tartsd az arousal
                    szintedet.
                    <br></br>
                    <br></br>
                    <b>Élvezd a tudatos kontrollt, miközben többfelé figyelsz!</b>
                    <br></br>
                    <br></br>
                    A gyakorlatot bármikor megszakíthatod. Javasolt időtartam 5 perc.
                </Typography>
            </div>
        ),
    },

    ctw_shepard: {
        en: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    Resisting annoyance
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">
                    This is a real challenge for most people. Ideally you will try this task
                    after successful sessions in both CTW sounds and CTW music. Your
                    task is the same: emotional self-control to keep your waves down.
                    But in this exercise, you will hear a special synthetic "music" called
                    the Shepard tone throughout the task. The sound will feel as if is
                    constantly increasing, without bounds. Some people find this sound
                    helpful in focusing, while others think it is rather disturbing.
                    <br />
                    <br />
                    <b>Which group do you belong to? Test yourself, and find out!</b>
                    <br />
                    <br />
                    If you are successful in maintaining a low-level
                    arousal regardless of external annoyance, such as the Shephard
                    tone, you can put this skill to use while exposed to
                    other annoying external impulses.
                    <br />
                    <br />
                    You can stop the exercise at any time. Recommended duration 5 minutes.
                </Typography>
            </div>
        ),
        hu: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    Bosszantó dolgok kiszűrése
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">
                    Ez a feladat a legtöbb ember számára kihívás. Akkor vagy kész erre a feladatra, ha már jól megy a
                    hullám-szelídítés mind középszinten hanghatások mellett, mind pedig haladó szinten megnyugtató,
                    folytonos zene mellett. A feladat továbbra is az, hogy lecsendesítsd a hullámokat és hogy megőrizd a
                    lelki nyugalmad. Ez azonban nem lesz könnyű egy speciális, szintetikus zenei hatás, az ún. Shepard
                    tone mellett. Ez a furcsa hanghatás olyan illúziót kelt, mintha az határok nélkül, folyamatosan emelkedne.
                    Vannak, akiknek ez a hang segít a fókuszálásban, mások pedig kifejezetten bosszantónak találják.
                    <br />
                    <br />
                    <b>Vajon Te melyik csoportba tartozol? Próbáld ki és lássuk, hogy boldogulsz vele.</b>
                    <br />
                    <br />
                    Ha ebben a feladatban sikerül a hullámokat megszelídíteni, és szinten tartani még a Shepard tone
                    mellett is, már kész vagy arra, hogy a mindennapok bosszantó dolgai mellett is kiegyensúlyozott légy.
                    <br />
                    <br />
                    A gyakorlatot bármikor megszakíthatod. Javasolt időtartam 5 perc.
                </Typography>
            </div>
        ),
    },

    ctw_tetris: {
        en: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    Emotion-management under cognitive load
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">
                    Now that you have mastered emotional management under various
                    circumstances, you are ready to try active engagement in a task
                    while controlling your arousal level. In Tetris you are expected to
                    focus on turning and placing geometrical shapes for best fit. But can
                    you manage your level of arousal while engaging in this cognitively
                    demanding task? Moreover, will you be able to master both, when
                    the “stakes get high”?
                    <br />
                    <br />
                    <b>Push yourself to the limit!</b>
                    <br />
                    <br />
                </Typography>
            </div>
        ),
        hu: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    Érzelmek irányítása kognitív terhelés mellett
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">
                    Már többféle helyzetben is bizonyítottad, hogy képes vagy érzelmeid irányítására. Kész vagy tehát
                    arra, hogy megpróbáld az izgalmi állapotodat nyugodt szinten megtartani, miközben egy elmét
                    próbáló feladatot teljesítesz. A Tetris játékban a geometriai formákat forgatva kell azokat a
                    legmegfelelőbb helyre beilleszteni. Képes vagy erre, miközben az érzelmeidet is kordában tartod?
                    Mi több: akkor is képes leszel nyugodt maradni, amikor már nagyobb a tét?
                    <br />
                    <br />
                    <b>Próbálgasd a határaidat!</b>
                    <br />
                </Typography>
            </div>
        ),
    },

    ctw_reinforce: {
        en: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    Reinforce
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">

                </Typography>
            </div>
        ),
    },


    ctw_updown: {
        en: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    Max 5 minutes!
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">

                </Typography>
            </div>
        ),
    },


    ctw_subliminal: {
        en: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    Subliminal
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">

                </Typography>
            </div>
        ),
    },


    ctw_control: {
        en: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    Flower growing...
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">

                </Typography>
            </div>
        ),
    },



    keywords: {
        en: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    Keywords...
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">

                </Typography>
            </div>
        ),
    },


    ctw_keywords: {
        en: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    CTW Keywords...
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">

                </Typography>
            </div>
        ),
    },


    ctw_keywords_nowave: {
        en: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    CTW Keywords...
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">

                </Typography>
            </div>
        ),
    },



    periodic_audio: {
        en: (
            <div>

            </div>
        ),
        hu: (
            <div>

            </div>
        )
    },


    periodic_cartoon_image: {
        en: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">

                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">

                </Typography>
            </div>
        ),
    },


    periodic_scary_image: {
        en: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">

                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">

                </Typography>
            </div>
        ),
    },

    phobia_keywords: {
        en: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    Keywords...
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">

                </Typography>
            </div>
        ),
    },


    phobia_ctw_keywords: {
        en: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    Phobia CTW Keywords...
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">

                </Typography>
            </div>
        ),
    },
}