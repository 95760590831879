import React, { Component } from 'react';

import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

// import DownloadIcon from '@mui/icons-material/Download';
// import DeleteIcon from '@mui/icons-material/Delete';
// import QueryStatsIcon from '@mui/icons-material/QueryStats';

// import { Line } from 'react-chartjs-2';
// import { defaults } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';

// import DataTable from 'react-data-table-component';
// import { data } from 'jquery';

// import Chart from 'chart.js/auto';


import WaveResultPlot from './WaveResultPlot';
import ScoreBox from './ScoreBox';
import GroupResultChart from './GroupResultChart';

import campaign_data from './scripts/campaign_data';
import { MyContext } from './MyContext';


export default class GroupResultCard extends Component {
  static contextType = MyContext;

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      deleteDialogOpen: false
    }

    this.campdata = campaign_data();

    //console.log("Wave const");

  }

  componentDidUpdate(prevProps) {
    if (this.props === prevProps) return;

  }

  componentDidMount() {
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClickEda() {
    this.setState({ open: true });
  }

  async handleClickDownload() {
    const aTag = document.createElement('a');

    let ses = this.props.session;
    console.log("DATA:", ses);

    let ts, gsr;

    let res = [];
    let i = 0;

    let t0 = 0;

    for ([ts, gsr] of ses.data.eda) {

      if (t0 == 0) t0 = ts;

      // corrected ts
      // ts = t0 + 250 * i;

      if (ses.data.fineacc && ses.data.fineacc.length > 0) {

        let sacc = " ";

        if (ses.data.fineacc.length > i + 1) sacc = ses.data.fineacc[i][1];

        res.push("" + ts + " " + gsr + " [" + sacc + "]\n");

      } else if (ses.data.acc) {

        let sacc = "";
        sacc = "" + (ses.data.acc[i][1]);
        console.log(ts, gsr, ses.data.acc[i][1]);

        res.push("" + ts + " " + gsr + " " + sacc + "\n");

      } else {

        res.push("" + ts + " " + gsr + "\n");

      }

      i += 1;
    }

    const blob = new Blob(res, { type: 'text/plain' });

    let dev = "";
    if (ses.device) dev = "_" + ses.device + "_";

    console.log("DEV", dev);

    let fname = ses.user + "_" + ses.campaign + "_" + ses.exercise + "_" + this.date + "_" + this.time + dev + ".txt";

    //aTag.href = data;
    aTag.href = URL.createObjectURL(blob);
    aTag.download = fname;
    aTag.click();


    let res_events = [];
    if (ses.data.events) {
      if (ses.data.events.length > 0) {
        let e = null;
        for ([ts, e] of ses.data.events) {

          res_events.push("" + ts + " " + e + "\n");
        }

        let fname2 = ses.user + "_" + ses.campaign + "_" + ses.exercise + "_" + this.date + "_" + this.time + dev + "events.txt";
        const blob2 = new Blob(res_events, { type: 'text/plain' });

        aTag.href = URL.createObjectURL(blob2);
        aTag.download = fname2;
        aTag.click();

      }

    }


  }


  async deleteSession() {

    console.log("BACKEND DeleteSession:", this.props.session);

    let resp = await fetch('/api/deleteexercise', {
      method: 'POST',
      body: JSON.stringify({ ts: this.props.session.ts, user: this.props.session.user }),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    let d = await resp.json();

    //if(this.props.reload.current) this.props.reload.current();
    this.context.reload();

  }

  handleDeleteClickOpen = () => {
    this.setState({ deleteDialogOpen: true });
  };

  handleDeleteClose = () => {
    this.setState({ deleteDialogOpen: false });
  };



  render() {

    console.log("GroupResultCard render");
    let grp = this.props.group;

    if (!grp) return null;

    console.log("grp", grp);

    var date = new Date(grp.ts).toLocaleDateString("en-US");
    var time = new Date(grp.ts).toLocaleTimeString("en-US");

    this.date = date;
    this.time = time;

    // let dur = ses.data.eda[ses.data.eda.length - 1][0] - ses.data.eda[0][0];
    // dur = Math.floor(dur / 1000);

    if (!grp.campaign) return;

    let ex = this.campdata[grp.campaign].exercises[grp.exercise];

    // console.log("USER", this.context.userRecord);

    return (
      <Card style={{ backgroundColor: 'lightgrey' }} elevation={5} >

        <CardContent>

          <Typography variant="h6" color="text.primary">
            {date} {time}
          </Typography>

          <Typography variant="body2" color="text.primary">
            {this.campdata[grp.campaign].label} / {ex.label}
          </Typography>

        </CardContent>

        <Button size="small" fontSize="small" onClick={this.handleClickEda.bind(this)}>Chart</Button>

        <Backdrop
          sx={{ zIndex: 2, margin: 'auto', width: 1, height: 1 }}
          open={this.state.open}
        // onClick={this.handleClose.bind(this)}
        >
          {this.state.open &&
            <Paper elevation={5} sx={{ width: 0.95, height: 0.95 }}>
              <GroupResultChart
                group={grp}
              />

              <div style={{ position: 'absolute', left: '0%', width: '100%', top: 20, zIndex: '100'}}>
                <Button variant="contained" onClick={this.handleClose.bind(this)}>Close</Button>
              </div>


            </Paper>
          }

        </Backdrop>

        {/* </CardContent> */}

      </Card>
    );

  }

}