import Typography from '@mui/material/Typography';

export const instruction_chill_data = {

    chill_relax: {
        hu: (
            <div>
                {/* <Typography variant="h6" align='justify' color="text.primary">
                    Csigavér
                </Typography> */}

                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Kövesd a relaxációs feladatot.
                    <br></br>
                </Typography>

            </div>
        ),
    },

    chill_hangok: {
        hu: (
            <div>
                {/* <Typography variant="h6" align='justify' color="text.primary">
                    Csigavér
                </Typography> */}

                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    A következő próbában különböző hangokat fogsz hallani. 
                    Próbáld meg ezeket figyelmen kívül hagyni.                   
                     <br></br>
                </Typography>

            </div>
        ),
    },


    chill_csigaver: {
        hu: (
            <div>
                {/* <Typography variant="h6" align='justify' color="text.primary">
                    Csigavér
                </Typography> */}

                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Most próbáljuk meg a "hullámszelídítést". Próbáld meg lecsendesíteni a hullámokat.
                    Ez a folyamat hasonló a biciklizéshez: nem kell túlgondolni, csak próbálgatni.
                    Ha nem sikerül elsőre, az nem baj, de kérlek próbálkozz, amíg a feladat tart.
                    <br></br>
                    <br></br>
                </Typography>

            </div>
        ),
    },

    chill_kapaszkodos: {

        hu: (
            <div>
                {/* <Typography variant="h5" align='center' color="text.secondary">
                Az ellazulást gyakran segíti ha egy pontra figyelsz. Most ezt próbáljuk ki.               
                </Typography> */}
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">
                    Az ellazulást gyakran segíti ha egy pontra figyelsz. Most ezt próbáljuk ki.
                </Typography>
            </div>
        ),
    },


    chill_kisvirag: {
        hu: (
            <div>
                {/* <Typography variant="h5" align='center' color="text.secondary">
                Az ellazulást gyakran segíti ha egy pontra figyelsz. Most ezt próbáljuk ki.               
                </Typography> */}
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">
                    A következő próbában két hullámot fogsz látni, mindegyik egy kicsit mást jelez vissza az érzelmi-aktivációs állapotodról. Próbáld meg mindkettőt elsimítani.
                </Typography>
            </div>
        ),
    },


    chill_geri: {
        hu: (
            <div>
                {/* <Typography variant="h5" align='center' color="text.secondary">
                Az ellazulást gyakran segíti ha egy pontra figyelsz. Most ezt próbáljuk ki.               
                </Typography> */}
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">
                    Most pihenésképpen nézd meg ezt a rövid rajzfilmet.
                </Typography>
            </div>
        ),
    },


    chill_csendesito: {
        hu: (
            <div>
                {/* <Typography variant="h5" align='center' color="text.secondary">
                Az ellazulást gyakran segíti ha egy pontra figyelsz. Most ezt próbáljuk ki.               
                </Typography> */}
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">
                A fülhallgatót leteheted, innen már nem lesznek hangok, csak a hullámokkal játszunk tovább. Próbáld meg lecsendesíteni a hullámokat.
                </Typography>
            </div>
        ),
    },

    chill_lefel: {
        hu: (
            <div>
                {/* <Typography variant="h5" align='center' color="text.secondary">
                Az ellazulást gyakran segíti ha egy pontra figyelsz. Most ezt próbáljuk ki.               
                </Typography> */}
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">
                A most következő próbában is hullámszelídítéssel kezdünk, de ha ez sikerül, megváltozik a kép, és ekkor próbáld meg felkorbácsolni az érzelmi-aktivációs hullámaidat. Fontos, hogy továbbra se mozogj vagy feszülj, henem ezt "belülről", a gondolataiddal tedd.
                </Typography>
            </div>
        ),
    },

    chill_szelidito: {
        hu: (
            <div>
                {/* <Typography variant="h5" align='center' color="text.secondary">
                Az ellazulást gyakran segíti ha egy pontra figyelsz. Most ezt próbáljuk ki.               
                </Typography> */}
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">
                Most levezetésképpen csak próbáld meg újra megszelídíteni a hullámokat.
                </Typography>
            </div>
        ),
    },

    chill_tree: {
        hu: (
            <div>
                {/* <Typography variant="h5" align='center' color="text.secondary">
                Az ellazulást gyakran segíti ha egy pontra figyelsz. Most ezt próbáljuk ki.               
                </Typography> */}
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">
                Most az lesz a cél, hogy ha elsimítottad a  hullámokat, tartsd is őket elsimítva és közben figyeld mi történik.
                </Typography>
            </div>
        ),
    },

    chill_tetris: {
        hu: (
            <div>
                {/* <Typography variant="h5" align='center' color="text.secondary">
                Az ellazulást gyakran segíti ha egy pontra figyelsz. Most ezt próbáljuk ki.               
                </Typography> */}
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">
                Játszunk Tetrist. Az irányításhoz használd a nyilakat. Próbálj meg úgy játszani, hogy közben kisimuljanak a hullámok és 
                maradjanak is ott. 
                </Typography>
            </div>
        ),
    },

}