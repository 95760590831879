import React, { Component } from 'react';
import Wave from 'react-wavify'
import { InputLabel, FormControl, OutlinedInput, IconButton, Button } from '@mui/material';
import Typography from '@mui/material/Typography';

export default class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: '',
      password: ''
    };
  }

  handleInputChange (type, event) {
    this.setState({
        [type]: event.target.value
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    fetch('/authenticate', {
      method: 'POST',
      body: JSON.stringify({ user: this.state.user, password: this.state.password }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => {

        console.log("LOGIN response:", data);
        this.props.onLogin(data);

      })
      .catch(err => {
        console.error(err);
        alert('Error logging in please try again');
      });
  }

  render() {
    return (
      <div>

        <br></br><br></br><br></br>

        {/* <form onSubmit={this.onSubmit}>
          <p>Evolving technology for human sciences</p>
          <p></p>
          <input
            type="text"
            name="user"
            placeholder="Enter user name"
            autoComplete="username"
            value={this.state.user}
            onChange={this.handleInputChange}
            required
            size="30"
          />
          <input
            type="password"
            autoComplete="current-password"
            name="password"
            placeholder="Enter password"
            value={this.state.password}
            onChange={this.handleInputChange}
            required
            size="30"
          />
          <input type="submit" value="Submit" />
        </form> */}

        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">User</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            // type={values.showPassword ? 'text' : 'password'}
            type='text'
            autoComplete="username"
            value={this.state.user}
            onChange={this.handleInputChange.bind(this, 'user')}
            // endAdornment={
            //   <InputAdornment position="end">
            //     <IconButton
            //       aria-label="toggle password visibility"
            //       onClick={handleClickShowPassword}
            //       onMouseDown={handleMouseDownPassword}
            //       edge="end"
            //     >
            //       {values.showPassword ? <VisibilityOff /> : <Visibility />}
            //     </IconButton>
            //   </InputAdornment>
            // }
            label="Password"
          />
        </FormControl>

        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            // type={values.showPassword ? 'text' : 'password'}
            type='password'
            autoComplete="current-password"
            value={this.state.password}
            onChange={this.handleInputChange.bind(this, 'password')}
            // endAdornment={
            //   <InputAdornment position="end">
            //     <IconButton
            //       aria-label="toggle password visibility"
            //       onClick={handleClickShowPassword}
            //       onMouseDown={handleMouseDownPassword}
            //       edge="end"
            //     >
            //       {values.showPassword ? <VisibilityOff /> : <Visibility />}
            //     </IconButton>
            //   </InputAdornment>
            // }
            label="Password"
          />
        </FormControl>

        <br></br><br></br>

        <Button size="medium" onClick={this.onSubmit.bind(this)} variant="contained">LOGIN</Button>

        <div className="intro-wave">
          <Wave fill='#B289EF'
            paused={false}
            options={{
              height: 20,
              amplitude: 30,
              speed: 0.15,
              points: 7
            }}
            style={{ position: 'absolute', left: '0px', top: '90%', zIndex: '1', height: "100%" }}
          ></Wave>

          <Wave fill='rgba(150, 97, 255, .8)'
            paused={false}
            options={{
              height: 20,
              amplitude: 30,
              speed: 0.15,
              points: 5
            }}
            style={{ position: 'absolute', left: '0px', top: '90%', zIndex: '2', height: "100%" }}
          ></Wave>
        </div>

        <br></br><br></br>

        <Typography variant="body2" color="text.secondary">
          <a href="https://obimon.com">obimon.com</a>
        </Typography>

        <br></br>

        <Typography variant="body2" color="text.secondary">
          Contact us: <a href="mailto:info@obimon.com">info@obimon.com</a>
        </Typography>


      </div>
    );
  }
}