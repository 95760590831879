import React, { Component } from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import BoltIcon from '@mui/icons-material/Bolt';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import ShutterSpeedIcon from '@mui/icons-material/ShutterSpeed';
import ParkIcon from '@mui/icons-material/Park';

import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import BalanceIcon from '@mui/icons-material/Balance';

import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarRateIcon from '@mui/icons-material/StarRate';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import FilterVintageIcon from '@mui/icons-material/FilterVintage';
import { Typography } from '@mui/material';



class ScoreBox extends React.Component {

  constructor(props) {
    super(props);

  }

  render() {

    let d = this.props.data;

    return (
      <div>
        <Stack justifyContent="center" spacing={2} direction="row">

          {d.relaxpts>0 &&
            <div>
              <Tooltip title="Relax points earned">
                <LocalFloristIcon/>
              </Tooltip>
              <Typography>{d.relaxpts}</Typography>
            </div>
          }

          {d.decr>0 &&
            <div>
              <Tooltip title="Control points earned">
                <ShutterSpeedIcon/>
              </Tooltip>
              <Typography>{d.decr}</Typography>
            </div>
          }

          {d.sustain>0 &&
            <div>
              <Tooltip title="Balance points earned">
                <BalanceIcon/>
              </Tooltip>
              <Typography>{d.sustain}</Typography>
            </div>
          }

          {d.tree>0 &&
            <div>
              <Tooltip title="Forester points earned">
                <ParkIcon></ParkIcon>
              </Tooltip>
              <Typography>{d.tree}</Typography>
            </div>
          }

        </Stack>
      </div>
    )
  }

}

export default ScoreBox;