import Typography from '@mui/material/Typography';

export const instruction_rezilio_data = {

    // rezilio_bevezetes: {
    //     hu: (
    //         <div>
    //             {/* <Typography variant="h6" align='justify' color="text.primary">
    //                 Csigavér
    //             </Typography> */}

    //             <Typography variant="body1" align='justify' color="text.primary">
    //                 <br></br>
    //                 Kérlek, tedd fel a fejhallgatót és hallgasd meg a hangfelvételt. 
    //                 <br></br>
    //             </Typography>

    //         </div>
    //     ),
    // },


    rezilio_darts_gyakorlas : {
        hu: (
            <div>
                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Most próbáljunk ki együtt egy páros online Darts játékot. Ha vége van a gyakorlásnak, állítsd le a mérést (középen lila pipa).                    <br></br>
                </Typography>

            </div>
        ),

    },

    rezilio_darts_verseny : {
        hu: (
            <div>
                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Most indulhat a verseny! Ha vége van, állítsd le a mérést (középen lila pipa).                
                    </Typography>
            </div>
        ),

    },

    rezilio_relax: {
        hu: (
            <div>
                {/* <Typography variant="h6" align='justify' color="text.primary">
                    Csigavér
                </Typography> */}

                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Először egy kis lazítással kezdünk, mely végigjárja majd minden tagunkat. Kérlek kövesd a hangfelvétel utasításait. A gyakorlatok alatt nyugodtan csukd be a szemed!
                    <br></br>
                </Typography>

            </div>
        ),
    },



    rezilio_erzesek3d: {
        hu: (
            <div>
                {/* <Typography variant="h6" align='justify' color="text.primary">
                    Csigavér
                </Typography> */}

                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Most álljunk meg, és kérlek jelezz a vizsgálatvezetőnek!
                    <br></br>
                </Typography>

            </div>
        ),
    },

    rezilio_darts_visszavago : {
        hu: (
            <div>
                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Most indulhat visszavágó! Ha vége van, állítsd le a mérést (középen lila pipa).
                </Typography>
            </div>
        ),

    },

}