
import { calcLevelRezilia } from './calc_level_rezilia';

export const rezilia_data = {
    rezilia: {
        label: "Rezilia",
        levelType: "REZILIA",
        levelFn: calcLevelRezilia,
        exercises: {


            rezilia_erzesek1: {
                label: "Érzések",
                level: 1,
                eda: true,
                type: "FeedbackSlider",
                params: {
                    text: "Hogy érzed magad most?",
                    textLeft: "Teljesen ellazult vagyok",
                    textRight: "Kifejezetten feszült vagyok",

                },

            },

            // rezilia_bevezetes: {
            //     label: "Bevezetés",
            //     levelType: "REZILIA",
            //     level: 2,
            //     eda: true,
            //     type: "WaveBasic",
            //     params: {
            //         duration: 68000,
            //         backgroundImage: "/media/rezilio/bamboo.jpg",
            //         backgroundSound: "/media/rezilio/intro.mp3",
            //         continueOnZero: true,
            //         hideWaves: true,
            //         point_mult: 1,
            //         l_incr: 1,
            //         l_decr: 1,
            //         r_incr: 1,
            //         r_decr: 1
            //     },

            // },

            rezilia_darts_gyakorlas: {
                label: "Darts játék - gyakorlás",
                level: 2,
                eda: true,
                type: "WaveBasic",
                params: {
                    duration: 900000,
                    backgroundImage: "/media/rezilio/bamboo.jpg",
                    // backgroundSound: "/media/rezilio/intro.mp3",
                    continueOnZero: true,
                    hideWaves: true,
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },

            },

            rezilia_darts_verseny: {
                label: "Darts verseny",
                level: 3,
                eda: true,
                type: "WaveBasic",
                params: {
                    duration: 900000,
                    backgroundImage: "/media/rezilio/bamboo.jpg",
                    // backgroundSound: "/media/rezilio/intro.mp3",
                    continueOnZero: true,
                    hideWaves: true,
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },

            },

            rezilia_csigaver: {
                label: "Csigavér",
                level: 4,
                eda: true,
                type: "WaveBasic",
                params: {
                    duration: 180000,
                    backgroundImage: "/media/00szinkombinacioJO_5.jpg",

                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },

            },

            rezilia_lelegzos: {
                label: "Lélegzős",
                level: 5,
                eda: true,

                type: "WaveBasic",
                params: {
                    duration: 180000,
                    backgroundImage: "/media/lelegzos.png",
                    introLabel: "Mondd lassan magadban és közben csináld is: Beszívom a levegőt és kifújom a levegőt!",
                    introTime: 20000,

                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },

            },

            rezilia_kisvirag: {
                label: "Kisvirág",
                level: 6,
                eda: true,
                type: "Waves2Phase",
                params: {
                    flower: true,
                    backgroundImage: "/media/tree.jpg",
                    duration: 180000,
                    stopWhenGrown: true,
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },
            },


            rezilia_erzesek2a: {
                label: "Érzések 2a",
                level: 7,
                eda: true,
                type: "FeedbackSlider",
                params: {
                    text: "Hogy érzed magad most?",
                    textLeft: "Teljesen ellazult vagyok",
                    textRight: "Kifejezetten feszült vagyok",

                },

            },

            rezilia_erzesek2b: {
                label: "Érzések 2b",
                level: 8,
                eda: true,
                type: "FeedbackSlider",
                params: {
                    text: "Mennyire volt hasznos ez a gyakorlat az érzelmi állapotod szabályozásához?",
                    textLeft: "Egyáltalán  nem volt hasznos",
                    textRight: "Nagyon hasznos volt",

                },

            },

            rezilia_erzesek2c: {
                label: "Érzések 2c",
                level: 9,
                eda: true,
                type: "FeedbackSlider",
                params: {
                    text: "Mennyire valószínű, hogy használnád ezt a  gyakorlatot az érzelmi állapotod szabályozásához máskor is?",
                    textLeft: "Egyáltalán nem valószínű",
                    textRight: "Nagyon valószínű",

                },

            },

            // rezilia_fajdalomreflexiok: {
            //     label: "Fájdalomreflexiók",
            //     levelType: "REZILIA",
            //     level: 9,
            //     eda: true,
            //     type: "WaveBasic",
            //     params: {
            //         duration: 117000,
            //         backgroundImage: "/media/rezilio/bamboo.jpg",
            //         backgroundSound: "/media/rezilio/fajdalomreflexio.mp3",
            //         continueOnZero: true,
            //         hideWaves: true,
            //         point_mult: 1,
            //         l_incr: 1,
            //         l_decr: 1,
            //         r_incr: 1,
            //         r_decr: 1
            //     },

            // },

            // rezilia_lefel: {
            //     label: "Most fáj, most nem fáj",
            //     levelType: "REZILIA",
            //     level: 10,
            //     eda: true,
            //     type: "WaveUpdown",
            //     params: {
            //         backgroundImage: "/media/moon.jpg",
            //         visual: {
            //             incr: ["/media/incr/fire1.jpg", "/media/incr/fire2.jpg", "/media/incr/fire3.jpg", "/media/incr/fire4.jpg", "/media/incr/fire5.jpg", "/media/incr/fire6.jpg",],
            //             decr: ["/media/decr/water1.jpg", "/media/decr/water2.jpg", "/media/decr/water3.jpg", "/media/decr/water4.jpg", "/media/decr/water5.jpg", "/media/decr/water6.jpg", "/media/decr/water7.jpg", "/media/decr/water8.jpg",],
            //         },
            //         uptext: "Most képzelj el egy erős fájdalmat és éld át teljes valójában!",
            //         downtext: "Most engedd el a fájdalommal teli gondolatokat, hisz nem tarthatnak örökké.",
            //         duration: 180000,
            //         point_mult: 1,
            //         l_incr: 2,
            //         l_decr: 1,
            //         r_incr: 2,
            //         r_decr: 1,

            //     },
            // },

            rezilia_lefel: {
                label: "Föl és le… Érzelmi libikóka",
                level: 10,
                eda: true,
                type: "WaveUpdown",
                params: {
                    backgroundImage: "/media/moon.jpg",
                    visual: {
                        incr: ["/media/incr/fire1.jpg", "/media/incr/fire2.jpg", "/media/incr/fire3.jpg", "/media/incr/fire4.jpg", "/media/incr/fire5.jpg", "/media/incr/fire6.jpg",],
                        decr: ["/media/decr/water1.jpg", "/media/decr/water2.jpg", "/media/decr/water3.jpg", "/media/decr/water4.jpg", "/media/decr/water5.jpg", "/media/decr/water6.jpg", "/media/decr/water7.jpg", "/media/decr/water8.jpg",],
                    },
                    uptext: "Most gondolj valamire, ami feszültséget okoz benned és éld át teljes valójában!",
                    downtext: "Most engedd el ezt a gondolatot, hisz nem tarthat örökké.",
                    duration: 180000,
                    point_mult: 1,
                    l_incr: 2,
                    l_decr: 1,
                    r_incr: 2,
                    r_decr: 1,
                },
            },

            rezilia_facska: {
                label: "Fácska",
                level: 11,
                eda: true,
                type: "Waves2Phase",
                params: {
                    tree: true,
                    backgroundImage: "/media/desert4.jpg",
                    duration: 180000,
                    stopWhenGrown: true,
                    growSpeed: 0.5,
                    point_mult: 1,
                    l_incr: 2,
                    l_decr: 1,
                    r_incr: 2,
                    r_decr: 1
                },
            },


            rezilia_erzesek3a: {
                label: "Érzések 3a",
                level: 12,
                eda: true,
                type: "FeedbackSlider",
                params: {
                    text: "Hogy érzed magad most?",
                    textLeft: "Teljesen ellazult vagyok",
                    textRight: "Kifejezetten feszült vagyok",

                },

            },

            rezilia_erzesek3b: {
                label: "Érzések 3b",
                level: 13,
                eda: true,
                type: "FeedbackSlider",
                params: {
                    text: "Mennyire volt hasznos ez a gyakorlat az érzelmi állapotod szabályozásához?",
                    textLeft: "Egyáltalán  nem volt hasznos",
                    textRight: "Nagyon hasznos volt",

                },

            },

            rezilia_erzesek3c: {
                label: "Érzések 3c",
                level: 14,
                eda: true,
                type: "FeedbackSlider",
                params: {
                    text: "Mennyire valószínű, hogy használnád ezt az érzelmi állapotod szabályozásához máskor is?",
                    textLeft: "Egyáltalán nem valószínű",
                    textRight: "Nagyon valószínű",

                },

            },


            rezilia_erzesek3d: {
                inactive: true,
                label: "Érzések 3d",
                levelType: "REZILIA",
                level: 15,
                eda: true,
                type: "FeedbackSlider",
                params: {
                    text: "Most arra kérünk, hogy értékeld azt, hogy milyen intenzív fájdalomra számítasz, miután a kezed néhány percig jég hideg vízbe (2 Celsius fokos) merítetted?",
                    textLeft: "Egyáltalán nincs fájdalom",
                    textRight: "Legrosszabb elképzelhető fájdalom",

                },

            },


            rezilia_jegesviz: {
                inactive: true,
                label: "Jeges víz teszt",
                levelType: "REZILIA",
                level: 16,
                eda: true,
                type: "VideoEda",
                params: {
                    url: "/media/rezilio/cpt_instruction.mp4",
                    point_mult: 1,
                    l_incr: 2,
                    l_decr: 1,
                    r_incr: 2,
                    r_decr: 1,
                    hideWaves: false,

                },

            },

            rezilia_darts_visszavago: {
                label: "Darts visszavágó",
                level: 15,
                eda: true,
                type: "WaveBasic",
                params: {
                    duration: 900000,
                    backgroundImage: "/media/rezilio/bamboo.jpg",
                    // backgroundSound: "/media/rezilio/intro.mp3",
                    continueOnZero: true,
                    hideWaves: true,
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },

            },


        }
    }
}