import React, { Component } from 'react';

export default class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user : '',
      password: ''
    };
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
  }

  // onSubmit = (event) => {
  //   event.preventDefault();

  //   console.log("Register submit");

  //   fetch('/api/register', {
  //     method: 'POST',
  //     body: JSON.stringify(this.state),
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //   .then(res => {
  //     if (res.status === 200) {

  //       alert('Success adding user');

  //       //this.props.onRegisterFinished();

  //     } else {
  //       const error = new Error(res.error);
  //       throw error;
  //     }
  //   })
  //   .catch(err => {
  //     console.log("ERROR:", err);
  //     if(err === "user_exists") alert('User already exists!');
  //     else alert('Error registering in please try again');
  //   });
  // }

  async onSubmit(event) {
    event.preventDefault();

    console.log("BACKEND Register submit");

    let resp = await fetch('/api/register', {
      method: 'POST',
      body: JSON.stringify(this.state),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    console.log(resp);

    const text = await resp.text();

    console.log(text);

    if(text === "user_exists") alert("ERROR: User already exists!");
    if(text === "user_added") alert("SUCCESS!");
  }

  render() {
    return (
      <form onSubmit={this.onSubmit.bind(this)}>
        <h1>Register:</h1>
        <input
          type="text"
          name="user"
          autoComplete="off"
          placeholder="Enter user name"
          value={this.state.user}
          onChange={this.handleInputChange}
          required
        />
        <input
          type="password"
          name="password"
          autoComplete="off"
          placeholder="Enter password"
          value={this.state.password}
          onChange={this.handleInputChange}
          required
        />
        <input type="submit" value="Submit"/>
      </form>
    );
  }
}