import { isUndefined } from 'lodash';
import React, { Component } from 'react';

import WaveResultCard from '../WaveResultCard.js';
import GroupResultCard from '../GroupResultCard.js';

//import Backdrop from '@mui/material/Backdrop';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Stack } from '@mui/material';
//import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';

import campaign_data from '../scripts/campaign_data';
import { findCampHack } from '../scripts/campaign_data';
import adjust_timesteps from '../scripts/adjust_timesteps';


export default class GroupSessions extends Component {

  constructor(props) {
    super(props);

    console.log("GroupSessions ======== ", props);

    // this.exercise_data = exercise_data();
    this.campdata = campaign_data();
    
    this.state = {
      selectWeek: 0,
      loaded: false,
    }

    this.groups = [];

    // fetch group records
    this.fetchGroupSessions();

  }

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  // Returns the ISO week of the date.
  getWeek = function (date) {
    //var date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
      - 3 + (week1.getDay() + 6) % 7) / 7);
  }

  handlePageChange(e, value) {
    //console.log("handlePageChange", value);

    let w = value - 1;

    this.setState({ selectWeek: w });
  }

  async fetchGroupSessions() {
    let resp = await fetch('/api/getgroupexercises', { method: 'POST' });

    let gs = await resp.json();
    console.log("Group sessions:", gs);


    this.checkGroups(gs);
    this.setState({ loaded: true });

    // this.setState({ groupSessions: gs });

  }

  checkGroups(gs) {

    for(let s of gs) {
      console.log("checkGroups", s);

      s.data.eda = adjust_timesteps(s.data.eda);

      let found = false;
      for(let g of this.groups) {
        console.log("group", g);

        if(g.campaign != s.campaign) continue;
        if(g.exercise != s.exercise) continue;

        if(Math.abs(g.ts - s.ts) > 10e3) continue;

        g.sessions.push(s);      
        if(s.ts > g.ts) g.ts = s.ts;

        found = true;

        console.log("added session to existing group", this.groups);
        break;    
      }

      if(found) continue;

      //  not found, create new group
      this.groups.push({
        ts: s.ts,
        campaign: s.campaign,
        exercise: s.exercise,
        sessions: [s]
      });

      console.log("created new group", this.groups);
    }
  }

  render() {

    if (!this.state.loaded) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      );
    }

    //console.log("MySessions: ", this.state.sessions);

    if (this.groups.length === 0) {
      return (
        <Card>No sessions found!</Card>
      );
    }

    let s = {}


    // let gs = this.groups; //.reverse();
    // gs.sort((a,b) => {return(b.ts-a.ts)});

    // let now = new Date();
    // let thisYear = now.getFullYear();
    // let thisWeek = this.getWeek(now);

    // //console.log("thisWeek", thisWeek);

    // thisWeek = thisYear * 52 + thisWeek;
    // //console.log("thisWeek", thisWeek);

    // let nWeek = 1;

    // let prevt = 0;

    // this.groups=[];

    // for (let i in sessions) {

    //   let ses = sessions[i];

    //   let campname = false;

    //   if(! ses.campaign ) continue;

    //   campname = ses.campaign;

    //   if(this.campdata[campname].exercises[ses.exercise] === undefined) {
    //     console.log("WARNING exercise not found:", ses.exercise);
    //     continue;
    //   }

    //   let t = ses.ts;

    //   // if(now - t <100) {
    //   //   console.log(ses);
    //   // }

    //   if(t===prevt) {
    //     // console.log("Duplicate record", t, ses);
    //     continue;
    //   }
    //   prevt = t;

    //   let d = new Date(t);
    //   let week = this.getWeek(d);
    //   let year = d.getFullYear();

    //   week = year * 52 + week;

    //   let diffWeek = thisWeek - week;
    //   if (diffWeek > nWeek) nWeek = diffWeek;
    //   if (diffWeek != this.state.selectWeek) continue;

    //   //console.log(JSON.stringify(ses));

    //   if (!ses.data.scores) continue;
    //   if (ses.data.scores.length < 10) continue;

    //   // if(!ses.data.eda) continue;

    //   // console.log(ses);

    //   //console.log("MySessions sessionStats:", sessionStats)

    //   //console.log("Weekly session", ses);

    //   var date = new Date(t).toLocaleDateString("en-US");
    //   var time = new Date(t).toLocaleTimeString("en-US");

    //   // let scores = sessions[i].data.scores;

    //   // console.log("t", t);
    //   // console.log("s", ses);

    //   if(this.campdata[campname].group) this.checkGroups(ses);

    //   // s.push(
    //   //   <Grid item key={t+"_g"} xs={12} md={6} lg={4} xl={3}>
    //   //     <WaveResultCard key={t+"w"} session={ses}></WaveResultCard>
    //   //   </Grid>
    //   // )

    //   s[t] =
    //     <Grid item key={t+"_g"} xs={12} md={6} lg={4} xl={3}>
    //       <WaveResultCard key={t+"w"} session={ses}></WaveResultCard>
    //     </Grid>
      

    // }

    // now add groups
    for(let g of this.groups) {
      s[g.ts+1] = <Grid item key={g.ts+"_group"} xs={12} md={6} lg={4} xl={3}>
        <GroupResultCard key={g.ts+"_wg"} group={g}></GroupResultCard>
      </Grid>

    }

    console.log("s", Object.values(s));

    if (s.length === 0) {
      s[0] =
        <Paper style={{
          padding: 50,
          margin: 100
        }}>
          <Typography variant="body">No data on selected week</Typography>
        </Paper>
      
    }

    let keys = Object.keys(s)
    keys.sort().reverse();

    let sorted_s = []
    for(let i of keys) {
      sorted_s.push(s[i]);
    }

    //console.log("SCORES", scores);

    return (
      <Box maxWidth='90%' margin={3}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} container spacing={3} alignItems="center">
            <Grid item xs={4} textAlign="right" >
              <Typography variant="body">
                Select week:
              </Typography>
            </Grid>
            <Grid item xs={8}><Pagination count={52} color="primary" onChange={this.handlePageChange.bind(this)} />
            </Grid>
          </Grid>
          {sorted_s}
        </Grid>
      </Box>

    );


    return (
      <div className="mysessions">
        <Box margin={1}>
          <Stack spacing={1}>
            {s}
          </Stack>
        </Box>
      </div>
    );

  }



}