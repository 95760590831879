import React from 'react';

const saplingSize = 30;
const fruitingSize = 80;


export default class Tree extends React.Component {

  constructor(props) {
    super(props);

    this.canvas = React.createRef()

    if (props.treeSize) this.treeSize = props.treeSize;
    else this.treeSize = 0;

    console.log("Fractal constr", props.treeSize);

    this.startHash = Math.floor(Math.random() * 1000000);
    this.rand = this.LCG(this.startHash);
    this.rand(); // drop first

    this.fruitSize = Math.floor(Math.random() * 5) + 3;
    this.fruitPercentage = Math.floor(Math.random() * 60) + 10; // between 10 and 80
    this.maxLeafSize = Math.floor(Math.random() * 20) + 10; // between 10 and 40

    this.rotateSize = (Math.random() * 6) + 15; // between 4 and 10
    this.rotateVar = (Math.random() * 6) + 4;  // between 4 and 10

    this.lenAvgDec = 0.85;
    this.lenVarDec = 0; // -0.1 to 0.1

    this.fruitColor = Math.floor(Math.random() * 360);
    this.fruitColorVar = Math.floor(Math.random() * 30);

    let lc = Math.floor(Math.random() * 40) + 100;
    this.leafColor = "hsl(" + lc + ", 100%, 25%)";
    //this.leafColor = 'green';

    let bc = Math.floor(Math.random() * 50) + 50;
    this.branchColor = "hsl(28, " + bc + "% , 25%)";

    // window.addEventListener('resize', this.handleResize.bind(this));
    // this.handleResize();


    this.state = {
    }
  }

  handleResize() {
    this.setState({ update: 1 });
  }

  saveImage() {
    console.log("SAVE");

    // this.width = this.canvas.current.width = 1024;
    // this.height = this.canvas.current.height = 768;
    // this.startDrawTree();

    var dataURL = this.canvas.current.toDataURL('image/webp', 0.5);
    console.log("Tree image size:", dataURL);
    //let image = new Image();
    //image.src = canvas.toDataURL();

    let treeCat = "seedling";
    if (this.treeSize > saplingSize / 2) treeCat = "sapling";
    if (this.treeSize > saplingSize) treeCat = "young";
    if (this.treeSize > fruitingSize) treeCat = "fruiting";

    return {
      type: "tree",
      data: {
        cat: treeCat,
        size: Math.floor(this.treeSize),
      },
      img: dataURL
    };
  }


  componentDidUpdate() {
    this.treeSize = this.props.treeSize;

    if (this.props.hash) {
      this.startHash = this.props.hash;
      this.rand = this.LCG(this.startHash);
      this.rand();
    }

    //this.treeSize=100;

    //console.log("treeSize", this.treeSize);
  }

  componentWillUnmount() {
    console.log("Tree unmounted!");
    //if (this.animation) cancelAnimationFrame(this.animation);


    this.running = false;
  }

  componentDidMount() {
    console.log("FRACTAL )))))))))))))))))))))");

    let canvas = this.canvas.current;

    // update canvas size
    this.width = canvas.width  = 500;
    this.height = canvas.height  = 720;

    console.log("FRACTAL", this.width, this.height);

    this.ctx = canvas.getContext('2d') //// 3 - access node using .current

    let ctx = this.ctx;


    //this.loop();


    //   ctx.beginPath();
    //   ctx.arc(0, 0, 100, 0, 2 * 3.1415);
    //   ctx.stroke();

    this.w = 1;

    this.lastt = new Date();

    this.running = true;
    this.loop();

  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  LCG(s) {
    return function () {
      s = Math.imul(48271, s) | 0 % 2147483647;
      //return (s & 2147483647) / 2147483648;
      return (s & 2147483647);
    }
  }


  loop() {

    if (!this.running) return;

    let canvas = this.canvas.current;

    let scale = window.innerHeight / 720;

    canvas.style.height = Math.floor(canvas.height * scale) + "px";
    canvas.style.width = Math.floor(canvas.width * scale) + "px";

    this.startDrawTree();
    if (this.running) setTimeout(this.loop.bind(this), 500);
  }

  startDrawTree() {
    //console.log("treeSize", this.treeSize);

    let t0 = Date.now();


    let ctx = this.ctx;

    ctx.resetTransform();
    ctx.clearRect(0, 0, this.width, this.height);

    //let scale = window.innerHeight / this.height;
    // ctx.scale(this.width / window.innerWidth, this.height / window.innerHeight);

    //console.log("SCALE ", window.innerHeight, this.height, scale);

    ctx.globalCompositeOperation = "destination-over";
    ctx.shadowColor = 'rgba(0,0,0,0.3)'
    ctx.shadowOffsetX = 5;
    ctx.shadowBlur = 10;



    //ctx.fillStyle = "#0f05";
    //ctx.fillRect(0, 0, this.width, this.height);

    let treebase = this.height * 2 / 3;

    ctx.translate(this.width / 2, treebase);

    let a = 0; //Math.sin(this.w);
    this.w = this.treeSize / 100.0 * 10.0; // number of branches
    let nodelen = this.treeSize / 100 * (this.height - treebase) / 4;
    let branchwidth = this.w * 1.5;

    this.rand = this.LCG(this.startHash);
    this.rand();

    this.color1 = '#632f02';
    this.color2 = 'green';

    this.nbr = 0;

    this.drawTree(0, 0, nodelen, a, branchwidth, this.w, this.startHash);

    console.log("NBR = ", this.nbr);

    // if(this.treeSize>100) this.treeSize = 100;
    // if(this.treeSize<5) this.treeSize = 5;



    //console.log("drawTree", this.treeSize, nodelen, branchwidth);

    //console.log("Loop", this.w);

    let t1 = Date.now();

    //console.log("++++++", t1 - t0);
  }


  drawTree(startX, startY, len, angle, branchWidth, w, hash) {

    let rand = this.LCG(hash);
    rand();
    rand();
    rand();

    let ctx = this.ctx;

    ctx.beginPath();
    ctx.save();


    ctx.strokeStyle = this.branchColor;
    ctx.fillStyle = this.branchColor;


    ctx.lineWidth = 0.2;
    ctx.translate(startX, startY);
    ctx.rotate(angle * Math.PI / 180);
    //ctx.rotate(5 * Math.PI / 180);
    ctx.moveTo(0, 0);

    //console.log("draw", w, len);


    w -= 1;


    // new hash
    //hash = rand() + angle ; 

    let br = ((rand() % 10) / 100) + 0.7;

    let nextBranchWidth = branchWidth * br;

    let thisLen = len;
    if (w < 1) thisLen = len * w;

    if (w < 1) {
      //nextBranchWidth=0;
    }

    //ctx.lineTo(0, -thisLen);

    let xx = nextBranchWidth;
    let yy = thisLen;

    // alternate polygon:
    ctx.beginPath();
    ctx.moveTo(-branchWidth / 2, 0);
    ctx.lineTo(-nextBranchWidth / 2, -thisLen);
    //ctx.bezierCurveTo(-xx*1.1, -thisLen/3, -xx*0.9, -thisLen*2/3, -nextBranchWidth / 2, -thisLen);
    ctx.lineTo(nextBranchWidth / 2, -thisLen);
    ctx.lineTo(branchWidth / 2, 0);
    //ctx.bezierCurveTo(xx*1.05, -thisLen*2/3, xx*0.95, -thisLen*1/3, branchWidth /2 , 0);

    ctx.closePath();
    ctx.fill();


    ctx.stroke();
    //if (len < 5) {
    //if (this.treeSize > saplingSize && nextBranchWidth < 0.7) {
    if (this.treeSize > saplingSize && w <= 1) {

      //ctx.strokeStyle = "green";
      //ctx.fillStyle = this.color2;

      ctx.translate(0, -thisLen);
      let leafAngle = rand() % 360;
      ctx.rotate(leafAngle * Math.PI / 180);


      ctx.fillStyle = this.leafColor;
      ctx.beginPath();

      let leafSize = (this.treeSize - saplingSize) / (100 - saplingSize) * this.maxLeafSize;
      let leafWidth = Math.PI / 2;

      //console.log("leafsize", leafSize);

      ctx.arc(-leafSize, 0, leafSize, 0, leafWidth);
      //ctx.arc(-leafSize, 0, leafSize, -leafWidth, 0, true);
      ctx.fill();

      if (this.treeSize > fruitingSize) {
        // fruit color

        if ((rand() % 100) < this.fruitPercentage) {

          let c = this.fruitColor;
          let cvar = this.fruitColorVar;

          let fruitSize = (this.treeSize - fruitingSize) / (100 - fruitingSize) * this.fruitSize;

          let cc = Math.floor(((rand() % (cvar * 2)) - cvar) + c) % 360;

          let col = "hsl(" + cc + ", 100%, 50%)";

          ctx.fillStyle = col;
          ctx.beginPath();
          ctx.arc(0, 0, fruitSize, 0, Math.PI * 2);
          ctx.fill();
        }
      }

      ctx.restore();
      return;
    }

    if (w < 1 || nextBranchWidth < 0.7) {
      ctx.restore();
      return;
    }

    this.nbr += 1;

    let a1 = (rand() % (this.rotateVar + 1)) - this.rotateVar * 2;
    let a2 = (rand() % (this.rotateVar + 1)) - this.rotateVar * 2;

    let len1 = -((rand() % 100) / 100) * this.lenVarDec + this.lenAvgDec;
    let len2 = -((rand() % 100) / 100) * this.lenVarDec + this.lenAvgDec;

    let nbranch = 0;
    nbranch = ((rand() % 100));

    //let a1 = Math.random()-0.5;
    //this.drawTree(0, -len, len * 0.8, angle + 6 + a1, nextBranchWidth, w, color1, color2, hash);
    if (nbranch <= 95) this.drawTree(0, -len, len * len1, angle + this.rotateSize + a1, nextBranchWidth, w, hash + angle * 34738);

    //let a2 = Math.random()-0.5;
    if (nbranch >= 5) this.drawTree(0, -len, len * len2, angle - this.rotateSize - a2, nextBranchWidth, w, hash + angle * 54982);

    // if (nbranch >= 95) {
    //   let a = (rand() % (this.rotateVar + 1)) - this.rotateVar / 2;
    //   this.drawTree(0, -len, len * len2, angle - this.rotateSize + a, nextBranchWidth, w, hash - angle * 2);
    // }

    ctx.restore();
  }

  render() {
    //console.log("render");

    // let canvas = this.canvas.current;

    // let offset = 0;
    // if(canvas) {
    //   offset = - canvas.style.width /2 + window.innerWidth /2;
    //   console.log("offset", canvas.style.width, window.innerWidth, offset);
    // }

    return (
      // <Paper sx={{ width: 1, height: 1 }} margin='auto' alignItems="center">

      <div>
        <div style={{
          position: "fixed",
          zIndex: -2, width: "100%", top: 0, left: 0
        }}>
          <div style={{
            marginLeft: "auto", marginRight: "auto"
          }}>
            <canvas ref={this.canvas}>
              <p>Add suitable fallback here.</p>
            </canvas>
          </div>
        </div>
      </div>
      // </Paper>
    );

  }
}
