
import campaign_data from './campaign_data';


export function calcLevelRezilia(sessions) {
    // console.log("calcLevelChill", sessions);

    let campdata = campaign_data();


    let level = 1;


    for(let s of sessions) {
        
        if(! s.campaign ) continue;
        let camp = campdata[s.campaign];
        let ex = camp.exercises[s.exercise];


        if(camp.levelType != "REZILIA") continue;

        // console.log(s);
        // console.log("EX", ex);

        if(ex.level >= level) {
            level = ex.level + 1;
            // console.log("New LEVEL", level)
        }

    }


    // features
    let earnedfeatures = [];

    let info = "";

    return [level, info, earnedfeatures];

}



