import React from 'react';
import { makeNoise2D } from "open-simplex-noise";
import { makeNoise3D } from "open-simplex-noise";

const saplingSize = 30;
const fruitingSize = 80;

const noise = makeNoise2D(Date.now());
const noise3D = makeNoise3D(Date.now());

export default class Flower extends React.Component {

  constructor(props) {
    super(props);

    this.canvas = React.createRef()

    if (props.size) this.size = props.size;
    else this.size = 0;

    console.log("Flower constr", props.size);


    this.state = {
    }
  }

  handleResize() {
    this.setState({ update: 1 });
  }

  saveImage() {
    console.log("SAVE");

    // this.width = this.canvas.current.width = 1024;
    // this.height = this.canvas.current.height = 768;
    // this.startDrawTree();

    var dataURL = this.canvas.current.toDataURL('image/webp', 0.5);
    console.log("Flower size:", dataURL);
    //let image = new Image();
    //image.src = canvas.toDataURL();

    return {
      type: "flower",
      data: {
        size: Math.floor(this.size),
      },
      img: dataURL
    };
  }


  componentDidUpdate() {

    console.log("Flower update", this.props.size);

    if(this.props.size > this.size) {
      this.size = this.props.size;
      this.growFlower();
    }
    
  }

  componentWillUnmount() {
    console.log("Tree unmounted!");
    //if (this.animation) cancelAnimationFrame(this.animation);


    this.running = false;
  }

  componentDidMount() {
    console.log("FRACTAL )))))))))))))))))))))");

    let canvas = this.canvas.current;

    // update canvas size
    this.width = canvas.width = 500;
    this.height = canvas.height = 720;

    console.log("FRACTAL", this.width, this.height);

    this.ctx = canvas.getContext('2d') //// 3 - access node using .current

    let ctx = this.ctx;
    ctx.globalCompositeOperation = "destination-over";


    //this.loop();

    this.lastt = new Date();

    this.running = true;
    //this.loop();

    this.circle = { x: this.width / 2, y: this.height / 2, radius: 1 };
    this.frequency = Math.floor(Math.random()*10 + 5) ;
    this.magnitude = 1 ;
    this.independence = 0.1 + Math.random()/4;
    this.spacing = 0.01;
    let count = 300;
    this.h = Math.floor((Math.random()*260 + 180) % 360);

    this.drawFlower();

  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  hsl(h, s, l) {
    let c = "hsl(" + h + "," + s + "% , " + l + "%, 20%)";
    return c;
  }

  drawFlower() {

    // ctx.shadowColor = 'rgba(0,0,0,0.1)'
    // ctx.shadowOffsetX = 1;
    // ctx.shadowBlur = 1;

    this.radius = 1;

    //current.radius *= (this.magnitude + 1);

    let i=0;

    // STROKE COLOR:
    let h = Math.floor( (this.h + Math.random() * 30) % 360);
    let s = Math.floor(Math.random() * 10 + 90);
    let l = Math.floor(Math.random() * 50 );
    this.strokeColor = this.hsl(h,s,l);


    // for(let i=0; i<100; i+=0.1) {
    //    setTimeout(() => {
    //      this.size = i;
    //     this.growFlower();
    //    }, i * 10);
      
    
    // }

    this.growFlower();

    
  } 

  growFlower() {

    let newradius = this.width/8/100*this.size;
    while(this.radius < newradius) {
      // draw a circle, the final parameter controlling how similar it is to
      // other circles in this image

      console.log("radius", this.radius);

      let h = Math.floor( (this.h + Math.random() * 80) % 360);
      let s = Math.floor(Math.random() * 10 + 90);
      let l = Math.floor(Math.random() * 10 + 50);

      let c = this.hsl(h, s, l);

      this.strokeColor = this.hsl(100-h,s,50);


      //setTimeout(() => {
      this.drawDeformedCircle(this.radius * this.independence, c);
      //}, i * 1000);

      this.radius /= (1 - this.spacing);

    }
  }

  drawDeformedCircle(seed, c) {

    let ctx = this.ctx;
    // let r = Math.random()*2*3.1415;
    // let dx = Math.sin(r) * circle.radius;
    // let dy = Math.cos(r) * circle.radius;

    // var my_gradient = ctx.createLinearGradient(x, y, x+dx, y+dy);
    // let col1 = this.hsl(16,100,50);
    // let col2 = this.hsl(16,70,50);
    // my_gradient.addColorStop(0, col1);
    // my_gradient.addColorStop(1, col2);

    ctx.strokeStyle = this.strokeColor;

    ctx.beginPath();

    ctx.fillStyle = c;

    // Sample points evenly around the circle
    const samples = Math.floor(4 * this.radius + 20);
    for (let j = 0; j < samples + 1; ++j) {
      const angle = (2 * Math.PI * j) / samples;

      // Figure out the x/y coordinates for the given angle
      const x = Math.cos(angle);
      const y = Math.sin(angle);

      // Randomly deform the radius of the circle at this point
      const deformation = noise3D(x * this.frequency,
        y * this.frequency,
        seed) + 1;
      const radius = this.radius * (1 + this.magnitude * deformation);

      // Extend the circle to this deformed radius
      ctx.lineTo(this.circle.x + radius * x,
        this.circle.y + radius * y);
    }

    ctx.fill();
    ctx.stroke();
  }

  render() {
    //console.log("render");

    // let canvas = this.canvas.current;

    // let offset = 0;
    // if(canvas) {
    //   offset = - canvas.style.width /2 + window.innerWidth /2;
    //   console.log("offset", canvas.style.width, window.innerWidth, offset);
    // }

    return (
      // <Paper sx={{ width: 1, height: 1 }} margin='auto' alignItems="center">

      <div>
        <div style={{
          position: "fixed",
          zIndex: -2, width: "100%", top: 0, left: 0
        }}>
          <div style={{
            marginLeft: "auto", marginRight: "auto"
          }}>
            <canvas ref={this.canvas}>
              <p>Add suitable fallback here.</p>
            </canvas>
          </div>
        </div>
      </div>
      // </Paper>
    );

  }
}
