import React from 'react';
//import ReactHowler from 'react-howler'
import { Howl } from 'howler';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


class AdjustVolume extends React.Component {
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.vidRef = React.createRef();


  }

  componentDidMount() {
    this.loadMusic();
  }

  componentDidUpdate() {
    this.loadMusic();
  }

  loadMusic() {
    if(this.sound) return;

    this.sound = new Howl({
      src: "./media/546087_kjartan-abel_stardust-100-loopable-ambient-music-track.mp3",
      autoplay: false,
      loop: true,
      volume: 1,
    });

    this.sound.once('load', this.musicLoaded.bind(this));

  }

  musicLoaded() {
    console.log("loaded");

    setTimeout(() => {
      this.sound.play();
      this.sound.fade(0, 100, 1000);
    }, 100);


  }

  componentWillUnmount() {
    if (this.sound) this.sound.fade(100, 0, 0);
  }

  onPressed() {
    if (this.sound) this.sound.fade(100, 0, 500);

    setTimeout(() => {
      this.props.onVolumeAdjusted();
    }, 500);

  }

  render() {

    return (


      <div>
        <br></br>

        <Typography variant="h4" color="text.primary">
          Adjust sound volume
        </Typography>

        <br></br>

        <Typography variant="h6" color="text.primary">
          Please adjust your sound volume so that the music playing is loud but not
          uncomfortably loud.
          <br></br>
          <br></br>
          When ready, press 'OK'
        </Typography>

        <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.onPressed.bind(this)}>OK</Button>

      </div>
    );

  };
}

export default AdjustVolume;