import React from 'react'
import Wave from 'react-wavify'
// import { Howl } from 'howler'
import { CalcScore } from '../scripts/calc_score';
import { CalcSessionStats } from '../scripts/calc_sessionstats';

import Backdrop from '@mui/material/Backdrop';
import Fab from '@mui/material/Fab';
// import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Howl } from 'howler'


import NavigationIcon from '@mui/icons-material/Navigation';
import WaveResultCard from '../WaveResultCard';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography'
import { CircularProgress } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { MyContext } from '../MyContext';


class WaveBasic extends React.Component {
    static contextType = MyContext;

    constructor(props) {
        super(props);

        this.treeRef = React.createRef();

        this.col1 = [178, 137, 239, 0.7];

        this.color1 = this.rgba(this.col1);

        let params = props.params;

        if (params.backgroundSound) {
            this.bgPlayer = new Howl({
                src: [props.params.backgroundSound],
                html5: true,
                loop: false
            })
        }


        if (process.env.NODE_ENV === 'development') this.test = true;
        this.simrelax = 100;

        this.decr_ended_ts = false;

        this.calc_score = new CalcScore(params);
        this.calc_stats = new CalcSessionStats();

        this.eda = [];

        this.scores = [];

        this.n = 0;


        this.t = 10;
        this.start = Date.now();
        this.end = 0;

        this.success = false;

        

        this.state = {
            showResult: false,
            amplitude: 0,
            askFeedback: false,
            textAreaValue: "",
            bImg: params.backgroundImage,
        }

        if (params.duration) {
            const dur = params.duration;
            console.log("WavesBasic duration: " + dur);

            this.timeout = setTimeout(() => {
                this.ended();
            }, dur);
        }

    }

    finished() {
        console.log("Finished called");
        // on finish, we do not send data, because we already called store_cb in "ended"
        this.props.finished_cb({}, false);
    }


    componentDidMount() {

        console.log("mounted");
        if (this.bgPlayer) this.bgPlayer.play();

    }

    componentWillUnmount() {
        console.log("componentWillUnmount()");

        if (this.bgPlayer) this.bgPlayer.stop();

    }


    async ended() {
        if(this.endedCalled) return;
        this.endedCalled = true;

        clearTimeout(this.timeout);

        console.log("Ended");

        if (this.bgPlayer) this.bgPlayer.stop();

        // let sessionData = {
        //     eda: this.eda,
        //     scores: this.scores,
        // };

        // let sessionId = await this.props.store_cb(sessionData);

        //console.log("**************************** Session ID:", sessionId);

        //Now read back
        // await fetch

        this.setState({ askFeedback: true, ended: true });
        //this.setState({ showResult: true, sessionData: sessionData, ended: true });

    }

    async save() {
        this.setState({ saving: true });

        let sessionData = {
            eda: this.eda,
            scores: this.scores,
            feedback: this.state.textAreaValue,
            success: this.success
        };

        let sessionId = await this.props.store_cb(sessionData);

        console.log("**************************** Session ID:", sessionId);

        //Now read back
        // await fetch

        this.setState({ showResult: true, askFeedback: false, sessionData: sessionData, saving: false });

    }


    onEda(gsr, acc) {
        if (this.state.ended) {
            return;
        }

        let ts = Date.now();

        if (!this.lastts) {
            this.lastts = ts;
            return;
        }

        // if( (!this.firstTextShown) && (!this.props.params.hideWaves)) {
        //     this.firstTextShown = true;

        //     let label = "Focus on the wave.";
        //     let dur = 6000;

        //     if(this.props.params.introLabel) label = this.props.params.introLabel;
        //     if(this.props.params.introTime) dur = this.props.params.introTime;
            
        //     this.setState({ backdropLabel: label });
        //     setTimeout(()=>{this.setState({ backdropLabel: false })}, dur);
        // }

        this.eda.push([ts, gsr])

        let sl, sr;

        [sl, sr] = this.calc_score.calc_one(ts, gsr);

        this.calc_stats.calc_one(ts, sl, sr);

        let s = Math.min(sl, sr); // this is min! unlike in Wave2Phase, where it is max, (much easier)

        if (this.test) {
            s = this.simrelax;

            //console.log("simrelax", this.simrelax);
        }
  

        this.scores.push([ts, sl, sr]);

        // let relax = 0;
        // if (this.calc_stats.relaxpts) relax = this.calc_stats.relaxpts;

        // let amp = (50 - relax) * 2;
        // if (amp <= 0) {
        //     this.ended();
        // }


        if(s>150) s=150;
        
        if(s<=0) {
            this.success = true;

            if(!(this.props.params.continueOnZero === true)) {
                this.ended();
            }
        }

        this.setState({ amplitude: s });

        console.log("amp", s);


        this.lastts = ts;

    }


    rgba(c) {
        return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${c[3]})`
    }

    siminc() {
        this.simrelax += 5;
    }

    simdec() {
        this.simrelax -= 5;
        if (this.simrelax < 0) this.simrelax = 0;
    }

    handleTextChange(event) {
        this.setState({ textAreaValue: event.target.value });

    }

    async feedback() {
        await this.save();
        this.setState({ askFeedback: false });
    }

    render() {
        
        if (this.state.saving) {
            return (
                <div>
                    <p>Saving...</p>
                    <CircularProgress />
                </div>
            );
        }

        if (this.state.askFeedback) {
            return (
                <Box maxWidth='90%' margin={3} sx={{ flexDirection: 'column' }}>

                    <br></br>

                    <Typography>
                        If you wish, you can enter your thoughts here:
                    </Typography>

                    <br></br>

                    <TextareaAutosize
                        aria-label="minimum height"
                        minRows={5}

                        //placeholder="If you wish, you can enter your thoughts here..."
                        value={this.state.textAreaValue}
                        onChange={this.handleTextChange.bind(this)}
                        style={{ width: '90%' }}
                    />

                    <br></br>

                    <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.feedback.bind(this)}>Continue</Button>

                </Box>
            );
        }

        if (this.state.showResult) {

            //let final_score = this.calc_score.calc_final_score(this.scores);


            //let total = Math.floor(this.state.final_score.total);

            //let rank = this.calcRank(this.state.hist, total);

            //console.log("Show RESULT", this.state.sessionData);

            let ses = {
                type: 'WaveBasic',
                exercise: this.props.exercise,
                campaign: this.props.campaign,
                data: this.state.sessionData,
                ts: Date.now(),
                device: this.context.deviceName,
                user: this.context.user
            }


            return (
                <div>
                    <p>
                        Congratulations! You gained experience in controlling your thoughts and emotions,
                        and collected some relax points as well!
                    </p>

                    <Box maxWidth='90%' margin={3}>

                        <WaveResultCard session={ses}></WaveResultCard>

                        <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.finished.bind(this)}>SAVE SESSION</Button>

                    </Box>
                </div>
            );
        }


        let showWaves = true;
        if(this.props.params.hideWaves) showWaves = false;

        // const style = {
        //     margin: 0,
        //     top: 'auto',
        //     right: 20,
        //     bottom: 20,
        //     left: 'auto',
        //     position: 'fixed',
        // };

        return (


            <div>

                <div style={{ position: 'absolute', left: '0px', top: 0, zIndex: '-10', height: "100%", width: "100%" }}>
                    <img
                        // src="/media/00szinkombinacioJO_5.jpg"
                        src={this.state.bImg}
                        style={{ height: "100%", width: "100%", objectFit: "cover" }}
                    />
                </div>

                {this.state.backdropLabel &&
                    <Backdrop
                        sx={{ p: 1, color: 'white', zIndex: 0, background: '#fff0', 
                                textShadow: 'black 0.1em 0.1em 0.2em' }}
                        // open={this.state.backdropLabel}
                        open={true}
                        transitionDuration="5000"
                    >
                        {this.state.backdropLabel}
                    </Backdrop>}

                {showWaves && 
                    <Wave fill={this.color1}
                        paused={false}
                        options={{
                            height: 50,
                            amplitude: this.state.amplitude,
                            speed: 0.15,
                            points: 4
                        }}
                        style={{ position: 'absolute', left: '0px', top: '50%', zIndex: '-3', height: "50%" }}
                    ></Wave>
                }

                <div style={{ backgroundImage: `url('./public/media/00szinkombinacioJO_5.jpg')` }}>
                </div>
                <Fab variant="contained" color="primary" >
                    <CheckCircleIcon onClick={this.ended.bind(this)} />
                </Fab>

                {this.test &&
                    <Fab variant="contained" color="primary" >
                        <NavigationIcon onClick={this.siminc.bind(this)} />
                    </Fab>}
                {this.test &&
                    <Fab variant="contained" color="primary" >
                        <NavigationIcon onClick={this.simdec.bind(this)} />
                    </Fab>}

            </div>


        );

    };
}

export default WaveBasic;