import { isUndefined } from 'lodash';
import React, { Component } from 'react';

/* import { Line } from 'react-chartjs-2';
import { defaults } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
 */
import { CalcScore } from './scripts/calc_score';
import MySessions from './pages/MySessions';
import Button from '@mui/material/Button';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';


export default class PupilSelector extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user:"",
      userSelected: false,
      pupil:""
    }
  }

  pupilSelected() {
    console.log("Selected", this.state.pupil);

    if(this.state.pupil !== "") {
      this.fetchRecords(this.state.pupil);

      this.setState({userSelected: true, user: this.state.pupil});

    }

  }

  handlePupilChange(event) {
    console.log("handlePupilChange", event);
    this.setState({ pupil: event.target.value });
}

  render() {

    console.log("pupils:", this.props.pupils);

    if (!this.state.userSelected) {

      let s = [];

      if(this.props.pupils)
        for (let pupil of this.props.pupils) {
          // console.log("AA", animal);
          s.push(
            <MenuItem value={pupil}>{pupil}</MenuItem>
          )
        }
      

      return (
          <div>
          <br></br>
          <FormControl >
              <InputLabel id="demo-simple-select-label">Please select</InputLabel>
              <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.pupil}
                  label=""
                  onChange={this.handlePupilChange.bind(this)}
              >
                  {s}

              </Select>

              <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.pupilSelected.bind(this)}>Continue</Button>

          </FormControl>
          </div>
      )

      // return (
      //   <div>
      //     <p>Select user</p>
      //     <form onSubmit={event => {

      //         this.fetchRecords(this.state.user);

      //         this.setState(
      //           {
      //             userSelected: true
      //           }
      //         );

      //       }
      //     } >

      //     <label>
      //           <input
      //           name="user"
      //           value={this.state.user}
      //           onChange={event => this.setState({
      //             user: event.target.value,
      //           })}                
      //           required
      //         />
      //       </label>
      //       <input type="submit" value="Submit" />

      //     </form>
      //   </div>
      // )
    }

    return (
      <div>

        Inspect records for user: <b>{this.state.user}</b>
        <br></br>
        
        <MySessions user={this.state.user} sessions={this.state.sessions} />

        <button onClick={()=>{this.back_cb();}}>BACK</button>


      </div>
    );

  }

  back_cb() {
    console.log("BACKCB");
    this.setState({userSelected: false, user:""});
  }  

  async fetchRecords(user) {
    console.log("BACKEND pupil fetchRecords", user);

    let resp = await fetch('/api/find', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: user,
      })
    });

    let sessions = await resp.json();

    //console.log("APP loadRecords num:", sessions.length);

    // TEST RECALULATE SCORES
    for (let s of sessions) {

      console.log("TEST RECALULATE SCORES", s);

      if (s.data.scores) continue;

      if (!s.data.eda) continue;
      if (s.data.eda.length < 10) continue;

      let params = null;
      if(s.params.l_incr) params = s.params;
      if(s.data.difficulty_params) params = s.data.difficulty_params;
      else continue

      console.log("TEST RECALULATE SCORES params", params, s);


      let calc_score = new CalcScore(s.params);
      let scores = calc_score.calc_all_db(s.data.eda);

      s.data.scores = scores; // REPLACE!
    }

    this.setState({sessions: sessions});
  }
}