
import { CalcSessionStats } from './calc_sessionstats';
import { getWeek } from './getWeek';
import features from '../scripts/features';
import campaign_data from './campaign_data';

function isValidEdaSession(s) {
    if (!s.data) return false;
    if (!s.data.eda) return false;
    if (s.data.eda.length < 10) return false;

    if (!s.data.scores) return false;
    if (s.data.scores.length<10) return false;

    let dur = s.data.eda[s.data.eda.length-1][0] - s.data.eda[0][0];
    //if(dur < 60*1000) return false;

    return true;
}
// Criteria: what to do to reach the next level (finish the current one)
const levels = {

    1: {
        levelsessions: 1,
    },

    2: {
        levelsessions: 1,
    },

    3: {
        levelsessions: 1,
    },

    4: {
        levelsessions: 1,
    },

    5: {
        levelsessions: 1,
    },

    6: {
        levelsessions: 1,
    },

    7: {
        levelsessions: 1,
    },

    8: {
        levelsessions: 1,
    },

    9: {
        levelsessions: 1,
    },

    // 9: {
    //     decr: 200,
    //     tree: 3,
    //     // relax: 210,
    // },

    // 10: {
    //     tree: 6,
    //     relax: 1000,
    //     decr: 500,
    //     sustain: 500,

    // },

    // 11: {
    //     tree: 6,
    //     // relax: 210,
    // },

    // 80: {
    //     relax: 80,
    //     decr: 150,
    //     sustain: 150,
    //     trees: 3
    // }

};

function getStat(ses) {

    let highestlevel = 0;
    let campdata = campaign_data();

    for (let s of ses) {

        if(! s.campaign ) continue;
        let camp = campdata[s.campaign];
        if(camp.levelType != 'STRESSPROFILING1') continue;

        console.log("STRESSPROFILING s", s);
            
        let ex = camp.exercises[s.exercise];

        if(ex === undefined) {
            console.log("EX UNDEFINED", s.exercise);
            continue;
        }


        console.log("STRESSPROFILING EX", ex);

        let slevel = ex.level;

        if(slevel > highestlevel) highestlevel = slevel;
        
    }

    console.log("STRESSPROFILING highestLevel", highestlevel);

    return highestlevel
}

export function calcLevelStressProfiling1(sessions, courseLevel) {
    // console.log("calcLevelZen");

    console.log("STRESSPROFILING courseLevel", courseLevel);


    let level = 1;
    let res, info;

    let highestLevel = getStat(sessions);
    console.log("STRESSPROFILING highestLevel", highestLevel);

    let text = false;

    let currentLevel = highestLevel+1;

    // if(text) {
    //     info = "To reach the next level, you need to get "+text;
    // } else {
    //     info = "You have reached the final level!";
    //     //currentLevel+=1;
    // }

    // if(courseLevel && currentLevel>courseLevel) {
    //     currentLevel = courseLevel;
    //     info = "You have reached the maximum level set by your trainer."
    // }
    // console.log("STRESSPROFILING l", currentLevel, info, delta, s);

    let next_exercise = false;

    next_exercise = getExerciseFromLevel(currentLevel);


    // OVERRRIDE!!!
    // currentLevel = 100;
    info = "";
    let earnedfeatures = [];

    return [currentLevel, info, earnedfeatures, next_exercise];

}

function getExerciseFromLevel(level) {
    let campdata = campaign_data();

    let camp = 'stress_profiling_1'

    let ex = campdata[camp].exercises;
    for(let e in ex) {
        if(ex[e].level == level) {
            console.log("STRESSPROFILING LEVEL", level, "camp", camp, "exercise", e);
            return [camp, e];
        }
    }

    console.log("STRESSPROFILING LEVEL", level, "exercise not found");
    return false;
}

function getRandomExercise(level, delta) {
    let campdata = campaign_data();

    let camp = 'stress_profiling_1'

    console.log("STRESSPROFILING LEVEL", level, "delta", delta, "camp", camp, campdata[camp].exercises);

    let ex = [];
    for(let e in campdata[camp].exercises) {
        if(campdata.stress_profiling_1.exercises[e].level <= level) {
            ex.push([camp, e]);
        }
    }

    console.log("STRESSPROFILING LEVEL", level, "random exercise", ex);
    return ex[Math.floor(Math.random() * ex.length)];
}