import React, { Component } from 'react';
import Wave from 'react-wavify'
import { InputLabel, FormControl, OutlinedInput, IconButton, Button } from '@mui/material';
import Typography from '@mui/material/Typography';

export default class ForceReload extends Component {
  constructor(props) {
    super(props)
    this.state = {};
  }

  handleForceReload = async (event) => {
    console.log("Force reload");

    let key = Date.now();

    let resp = await fetch('/api/set_reload_key', {
      method: 'POST',
      body: JSON.stringify({key: key}),
      headers: {
        'Content-Type': 'application/json'
      }
    }) 

    console.log(resp);
  }

  render() {
    return (
      <div>
        <br></br><br></br><br></br>
        Force reload all clients
        <br></br>
        <br></br>
        <Button variant="outlined" onClick={this.handleForceReload}>Force reload</Button>
      </div>
    );
  }
}