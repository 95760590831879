import React from 'react';
import ReactHowler from 'react-howler'
//import {Howl, Howler} from 'howler';


import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// files from https://www.zapsplat.com/ or https://freesound.org/

class AudioSession extends React.Component {
  constructor(props) {
    super(props);

    console.log("Audio props: " + JSON.stringify(props));

    this.timeout = 0;
    this.loaded = false;

    this.state = {
      audioEnded: false
    }
  }

  onLoad() {
    console.log("Audio onLoad ");

    //this.players[s].play();
    this.loaded = true;

    console.log("Loaded");
    //this.player.play()

  }

  audioEnded() {
    this.setState({ audioEnded: true, pressTimer: 100 });

    this.timer = setInterval(() => this.decreasePressTimer(), 100);
  }

  componentWillUnmount() {
    if (this.timer) clearInterval(this.timer);
  }


  decreasePressTimer() {
    let d = this.state.pressTimer;
    d -= 2;

    if (d < 0) {
      if (this.timer) clearInterval(this.timer);
      this.finish();
    }
    else this.setState({ pressTimer: d });

  }

  finish() {
    if (this.timer) clearInterval(this.timer);

    this.props.finished_cb();
  }

  storeAndFinish() {
    if (this.timer) clearInterval(this.timer);

    this.props.store_cb([]);
    this.props.finished_cb();
  }

  onKeyDown(e) {
    console.log("KeyDown");
    if (e.key === 'f') this.audioEnded();
  }

  render() {

    if (this.state.audioEnded) {
      return (
        <div>
          <br></br>
        <Box>
          <Button sx={{m:5}} variant='contained' color='primary' onClick={this.storeAndFinish.bind(this)}>Save</Button>
          <Button sx={{m:5}} variant='contained' color='secondary' onClick={this.finish.bind(this)}>Do not save</Button>
        </Box>
        <br></br>

        <CircularProgress variant="determinate" value={this.state.pressTimer} />

        </div>
      );
    }

    let src = this.props.params.params.audio;
    let label = this.props.params.label;

    return (
      <div onKeyDown={this.onKeyDown.bind(this)} tabIndex="0">
        <br></br>
        <Typography variant="h4" color="text.secondary">
          Audio session
        </Typography>

        <br></br>

        <Typography variant="h6" color="text.primary">
          {label}
        </Typography>
        {/* 
        <audio controls>
          <source src={src} type="audio/mpeg"/>
        </audio> */}

        {/* <AudioPlayer
          src={this.props.params.audio}
          autoPlay
          controls
        /> */}

        <ReactHowler
          src={src}
          playing={true}
          preload={false}
          // key={this.props.params.audio}
          ref={(ref) => (this.player = ref)}
          onLoad={this.onLoad.bind(this)}
          onend={this.finished_cb}
        />

        <Button sx={{m:5}} variant='contained' color='primary' onClick={this.finish.bind(this)}>Abort</Button>

      </div>
    );

  };
}

export default AudioSession;