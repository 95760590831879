import React from 'react';
import exercise_data from '../scripts/exercise_data';
import campaign_data from '../scripts/campaign_data';


import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TopicIcon from '@mui/icons-material/Topic';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Exercise from './Exercise';
import CircularProgress from '@mui/material/CircularProgress';
// import { TextField } from '@mui/material';
// import { tabsListUnstyledClasses } from '@mui/base';
// import { toHaveStyle } from '@testing-library/jest-dom/matchers';
import { Button, Card, CardContent, Grid } from '@mui/material';
import { MyContext } from '../MyContext';

export default class GroupExerciseClient extends React.Component {
  static contextType = MyContext;

  constructor(props) {
    super(props);

    // console.log("Campaign =================================================");

    this.campdata = campaign_data();

  }


  componentDidUpdate(prevProps) {
    if (prevProps === this.props) return;
  }

  componentDidMount() {
    if (this.interval) clearInterval(this.interval);
    this.interval = setInterval(() => this.sendIamWaiting(), 2000);
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }

  async sendIamWaiting() {
    let resp = await fetch('/api/iamwaiting', { method: 'POST' });

    console.log("iamwaiting", resp);


    let d = await resp.json();

    console.log("iamwaiting data", d);

    if (d.campaign !== null) {
      this.context.setExercise(d.campaign, d.exercise);
      this.context.setPage("home");
    }

  }

  render() {
    console.log("aimwaiting");
    // create a string with the list of users waiting for the exercise to start


    return (

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Box textAlign="center">
          Waiting for the group exercise to start...
        </Box>
        <Box mt={2}>
          <CircularProgress />
        </Box>
      </Box>
    );



  }
}
