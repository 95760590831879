
import campaign_data from './campaign_data';


export function calcLevelChill(sessions) {
    // console.log("calcLevelChill", sessions);

    let campdata = campaign_data();


    let level = 1;


    for(let s of sessions) {
        
        if(! s.campaign ) continue;
        let camp = campdata[s.campaign];
        if(camp.levelType != 'CHILL') continue;
        let ex = camp.exercises[s.exercise];
        
        if(ex.level >= level) {
            level = ex.level + 1;
            // console.log("New LEVEL", level)
        }

    }


    // features
    let earnedfeatures = [];

    let info = "";

    return [level, info, earnedfeatures];

}



