import React from 'react';
// import ReactHowler from 'react-howler'

import Button from '@mui/material/Button';
// import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

class TextSession extends React.Component {
  constructor(props) {
    super(props);

    console.log("Audio props: " + JSON.stringify(props));

    this.timeout = 0;
    this.loaded = false;
  }


  finish() {
    this.props.finished_cb();
  }


  render() {

    let label = this.props.params.label;

    let text = this.props.params.params.text;

    return (
      <div>
        <br></br>

        <br></br>
        <Box sx={{ m: 'auto', maxWidth: "750px" }}>


        <Typography variant="h6" color="text.primary">
          {label}
        </Typography>

        <Typography variant="h6" color="text.primary">

            {text}

        </Typography>

        <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.finish.bind(this)}>OK</Button>
        </Box>

      </div>
    );

  };
}

export default TextSession;