

import { calcLevelCbtStress } from './calc_level_cbt_stress';
import Typography from '@mui/material/Typography';
import { cbt_stress_data } from './cbt_stress_data';


let exrecises_from_cbt = { ...cbt_stress_data.cbt_stress.exercises };

exrecises_from_cbt["snails"] = {
    label: "Snails",
    level: 1,
    eda: true,
    type: "Racing",
    params: {
        duration: 300000,
        backgroundImage: "/media/cbtbasic/01fantasy.jpg",
        point_mult: 1,
    },
}

// console.log("exercises_from_cbt", exrecises_from_cbt);

export const cbt_stress_grp_data = {
    cbt_stress_grp: {
        label: "Basic training group work",
        levelType: "CBTSTRESS",
        levelFn: calcLevelCbtStress,
        group: true,
        exercises: exrecises_from_cbt,
    }
}


//     chill: {
//         label: "Chill",
//         level: 1,
//         eda: true,

//         type: "Waves",
//         params: {
//             duration: 180000,
//             // backgroundImage: "/media/00szinkombinacioJO_5.jpg",
//             backgroundImage: "/media/cbtbasic/01fantasy.jpg",

//             point_mult: 1,

//             // forced_difficulty_params: {
//             //     l_incr: 1,
//             //     l_decr: 1,
//             //     r_incr: 1,
//             //     r_decr: 1,
//             //     nWave: 1
//             // }
//         },

//         instruction: {
//             en: (
//                 <div>
//                     <Typography variant="body1" align='justify' color="text.primary">
//                         <br></br>
//                         <b>Pay attention to your waves. </b>
//                         This process is similar to riding a bicycle: you don't have to overthink it, just "chill".
//                         You will be notified when the task is over. It will take maximum 3 minutes.
//                         <br></br>
//                         <br></br>
//                     </Typography>

//                 </div>
//             ),
//         }

//     },

//     peek: {
//         label: "Peek",
//         level: 1,
//         eda: true,
//         type: "Waves",
//         params: {
//             duration: 180000,
//             // backgroundImage: "/media/kukucska.png",
//             backgroundImage: "/media/cbtbasic/02cat.jpg",

//             // introLabel: "Csukott szemmel próbáld, néha kikukucskálva!",
//             // introTime: 20000,

//             point_mult: 1,
//             // l_incr: 1,
//             // l_decr: 1,
//             // r_incr: 1,
//             // r_decr: 1
//         },

//         instruction: {
//             en: (
//                 <div>
//                     <Typography variant="body1" align='justify' color="text.primary">
//                         <br></br>
//                         <b>Calm your waves with your eyes closed. </b>
//                         From time-to-time open your eyes for a moment and peek for a few moments and check if you've managed to calm your waves.
//                         <br></br>
//                         <br></br>
//                     </Typography>

//                 </div>
//             ),

//         }

//     },

//     focus: {
//         label: "Focus",
//         level: 1,
//         eda: true,

//         type: "Waves",
//         params: {
//             duration: 180000,
//             // backgroundImage: "/media/megkapaszkodos.png",
//             backgroundImage: "/media/cbtbasic/03mountains.jpg",

//             // introLabel: "Keress egy pontot a képen és csak oda nézz!",
//             // introTime: 20000,

//             point_mult: 1,
//             // l_incr: 1,
//             // l_decr: 1,
//             // r_incr: 1,
//             // r_decr: 1
//         },

//         instruction: {
//             en: (
//                 <div>
//                     <br></br>
//                     <Typography variant="body1" align='justify' color="text.primary">
//                         <b>Choose a spot on the screen</b> and pay attention to that detail only.
//                         If you find yourself distracted, drive your focus back to the chosen point.
//                     </Typography>
//                 </div>
//             ),
//         }

//     },

//     breathe: {
//         label: "Breathe",
//         level: 1,
//         eda: true,


//         type: "Waves",
//         params: {
//             duration: 180000,
//             // backgroundImage: "/media/lelegzos.png",
//             backgroundImage: "/media/cbtbasic/04blowball.jpg",

//             // introLabel: "Mondd lassan magadban és közben csináld is: Beszívom a levegőt és kifújom a levegőt!",
//             // introTime: 20000,

//             point_mult: 1,
//             // l_incr: 1,
//             // l_decr: 1,
//             // r_incr: 1,
//             // r_decr: 1
//         },

//         instruction: {
//             en: (
//                 <div>
//                     <br></br>
//                     <Typography variant="body1" align='justify' color="text.primary">
//                         <b>Pay attention to your breathing.</b> First, just pay attention to the rhythm, and later,
//                         observe how by only paying attention to your breathing, it naturally gets deeper and slower.
//                     </Typography>
//                 </div>
//             ),
//         }

//     },

//     flower: {
//         label: "Flower",
//         level: 2,
//         eda: true,
//         type: "Waves",
//         params: {
//             flower: true,
//             backgroundImage: "/media/tree.jpg",
//             duration: 300000,
//             point_mult: 1,
//         },

//         instruction: {
//             en: (
//                 <div>
//                     <br></br>
//                     <Typography variant="body1" align='justify' color="text.primary">
//                     If you can keep your waves calm, you will see a flower grow on the screen.
//                     The flowe will keep growing as long as you can keep your waves calm.
//                     You have 5 minutes to grow the flower.
//                     </Typography>
//                 </div>
//             ),
//         }

//     },


//     touch: {
//         label: "Touch",
//         level: 2,
//         eda: true,
//         type: "Waves",
//         params: {
//             duration: 180000,
//             // backgroundImage: "/media/ujjbungyurgetos.png",
//             backgroundImage: "/media/cbtbasic/06yoga.jpg",

//             // introLabel: "Érintsd össze a hüvelyk és mutató ujjadat és körözz lassan!",
//             // introTime: 20000,

//             point_mult: 1,
//             // l_incr: 1,
//             // l_decr: 1,
//             // r_incr: 1,
//             // r_decr: 1
//         },

//         instruction: {
//             en: (
//                 <div>
//                     <br></br>
//                     <Typography variant="body1" align='justify' color="text.primary">
//                         <b>Touch two fingers</b> and try comforting yourself by moving them gently if you please.
//                         Pay attention to the physical and emotional sensations.
//                     </Typography>
//                 </div>
//             ),
//         }

//     },

//     hugging: {
//         label: "Hug",
//         level: 2,
//         eda: true,
//         type: "Waves",
//         params: {
//             duration: 180000,
//             // backgroundImage: "/media/dudolos.png",
//             backgroundImage: "/media/cbtbasic/07hands.jpg",

//             // introLabel: "Dúdold magadban a kedvenc dalodat!",
//             // introTime: 20000,

//             point_mult: 1,
//             // l_incr: 1,
//             // l_decr: 1,
//             // r_incr: 1,
//             // r_decr: 1
//         },

//         instruction: {
//             en: (
//                 <div>
//                     <br></br>
//                     <Typography variant="body1" align='justify' color="text.primary">
//                         <b>Place your hand gently on your chest or arms </b> try comforting yourself by moving it smoothly.
//                         Pay attention to the physical and emotional sensations.
//                     </Typography>
//                 </div>
//             ),
//         }

//     },


//     shifting: {
//         label: "Shift",
//         level: 2,
//         eda: true,
//         type: "Waves",
//         params: {
//             duration: 180000,
//             backgroundImage: "/media/hintazos.png",
//             // introLabel: "Keress két pontot a képen és lassan hintázz köztük a szemeddel!",
//             // introTime: 20000,

//             point_mult: 1,
//             // l_incr: 1,
//             // l_decr: 1,
//             // r_incr: 1,
//             // r_decr: 1
//         },

//         instruction: {
//             en: (
//                 <div>
//                     <br></br>
//                     <Typography variant="body1" align='justify' color="text.primary">
//                         <b>Choose two spots</b> on the image and shift your attention between them continuously.
//                     </Typography>
//                 </div>
//             ),
//         }

//     },

//     hum: {
//         label: "Hum",
//         level: 2,
//         eda: true,
//         type: "Waves",
//         params: {
//             duration: 180000,
//             // backgroundImage: "/media/hintazos.png",
//             backgroundImage: "/media/cbtbasic/09guitar.jpg",
//             // introLabel: "Keress két pontot a képen és lassan hintázz köztük a szemeddel!",
//             // introTime: 20000,

//             point_mult: 1,
//             // l_incr: 1,
//             // l_decr: 1,
//             // r_incr: 1,
//             // r_decr: 1
//         },

//         instruction: {
//             en: (
//                 <div>
//                     <br></br>
//                     <Typography variant="body1" align='justify' color="text.primary">
//                     <b>Think about your favorite song</b> and try to relax while you hum it quietly, or just think about it.
//                     </Typography>
//                 </div>
//             ),
//         }

//     },

//     tree: {
//         label: "Tree",
//         level: 3,
//         eda: true,
//         type: "Waves",
//         params: {
//             tree: true,
//             backgroundImage: "/media/desert4.jpg",
//             duration: 300000,
//             point_mult: 1,
//         },

//     },


//     sounds: {
//         label: "Sounds",
//         level: 3,
//         eda: true,

//         type: "Waves",
//         params: {
//             duration: 193000,
//             // backgroundImage: "/media/lelegzos.png",
//             backgroundImage: "/media/cbtbasic/05bell.jpg",
//             backgroundSound: "/media/cbtbasic/sounds.mp4",

//             continueOnZero: true,
//             hideWaves: false,

//             // introLabel: "Mondd lassan magadban és közben csináld is: Beszívom a levegőt és kifújom a levegőt!",
//             // introTime: 20000,

//             point_mult: 1,
//             // l_incr: 1,
//             // l_decr: 1,
//             // r_incr: 1,
//             // r_decr: 1
//         },

//         instruction: {
//             en: (
//                 <div>
//                     <br></br>
//                     <Typography variant="body1" align='justify' color="text.primary">
//                         <b>Pay attention to the sounds. </b> Observe your body and your reactions.
//                         Try not to engage with what you hear, just listen.
//                     </Typography>
//                 </div>
//             ),
//         }
//     },

//     inner_peace: {
//         label: "Inner peace",
//         level: 3,
//         eda: true,
//         type: "Waves",
//         params: {
//             duration: 40000,
//             // backgroundImage: "/media/00szinkombinacioJO_5.jpg",
//             backgroundImage: "/media/cbtbasic/10boat.jpg",
//             // introLabel: "You have 40 seconds to calm your waves.",
//             // introTime: 20000,

//             point_mult: 1,
//             // l_incr: 1,
//             // l_decr: 1,
//             // r_incr: 1,
//             // r_decr: 1
//         },

//         instruction: {
//             en: (
//                 <div>
//                     <br></br>
//                     <Typography variant="body1" align='justify' color="text.primary">
//                         <b>Now you are ready to find your inner peace. </b>
//                         You've learned different relaxation techniques ready to put forward in a challenge.
//                         You'll have 40 seconds to calm your waves whatever way you see fit.
//                     </Typography>
//                 </div>
//             ),
//         }

//     },

//     mindfulness1: {
//         label: "Mindfulness",
//         level: 4,
//         eda: true,
//         type: "Waves",
//         params: {
//             duration: 193000,
//             backgroundImage: "/media/cbtbasic/11panda.jpg",
//             backgroundSound: "/media/mindfulness.m4a",
//             continueOnZero: true,
//             hideWaves: false,
//             point_mult: 1,
//             // l_incr: 1,
//             // l_decr: 1,
//             // r_incr: 1,
//             // r_decr: 1
//         },

//         instruction: {
//             en: (
//                 <div>
//                     <br></br>
//                     <Typography variant="body1" align='justify' color="text.primary">
//                         <b></b>
//                         Mindfulness meditation is a mental training practice that teaches you to slow
//                         down racing thoughts, let go of negativity, and calm both your mind and body.
//                     </Typography>
//                 </div>
//             ),
//         }

//     },

//     mindfulness2: {
//         label: "Body-scan technique",
//         level: 4,
//         eda: true,
//         type: "Waves",
//         params: {
//             duration: 560000,
//             backgroundImage: "/media/cbtbasic/12beach.jpg",
//             backgroundSound: "/media/bodyscan.m4a",
//             continueOnZero: true,
//             hideWaves: false,
//             point_mult: 1,
//             // l_incr: 1,
//             // l_decr: 1,
//             // r_incr: 1,
//             // r_decr: 1
//         },

//         instruction: {
//             en: (
//                 <div>
//                     <br></br>
//                     <Typography variant="body1" align='justify' color="text.primary">
//                         <b></b>
//                         Body scanning is a simple and effective way to relax your body and mind.
//                     </Typography>
//                 </div>
//             ),
//         }

//     },

//     mindfulness3: {
//         label: "Acceptance meditation",
//         level: 4,
//         eda: true,
//         type: "Waves",
//         params: {
//             duration: 327000,
//             backgroundImage: "/media/cbtbasic/13bamboo.jpg",
//             backgroundSound: "/media/acceptancemeditation.m4a",
//             continueOnZero: true,
//             hideWaves: false,
//             point_mult: 1,
//             // l_incr: 1,
//             // l_decr: 1,
//             // r_incr: 1,
//             // r_decr: 1
//         },

//         instruction: {
//             en: (
//                 <div>
//                     <br></br>
//                     <Typography variant="body1" align='justify' color="text.primary">
//                         <b></b>
//                         Acceptance meditation is a practice that helps you to accept the present moment.
//                     </Typography>
//                 </div>
//             ),
//         }

//     },

//     // updown: {
//     //     label: "Up-down",
//     //     level: 9,
//     //     eda: true,
//     //     type: "WaveUpdown",
//     //     params: {
//     //         backgroundImage: "/media/moon.jpg",
//     //         visual: {
//     //             incr: ["/media/incr/fire1.jpg", "/media/incr/fire2.jpg", "/media/incr/fire3.jpg", "/media/incr/fire4.jpg", "/media/incr/fire5.jpg", "/media/incr/fire6.jpg",],
//     //             decr: ["/media/decr/water1.jpg", "/media/decr/water2.jpg", "/media/decr/water3.jpg", "/media/decr/water4.jpg", "/media/decr/water5.jpg", "/media/decr/water6.jpg", "/media/decr/water7.jpg", "/media/decr/water8.jpg",],
//     //         },
//     //         duration: 180000,
//     //         point_mult: 1,
//     //         l_incr: 2,
//     //         l_decr: 1,
//     //         r_incr: 2,
//     //         r_decr: 1,

//     //     },

//     // },



//     // tetris: {
//     //     label: "Tetris",
//     //     level: 10,
//     //     eda: true,
//     //     type: "Tetris",
//     //     params: {
//     //         point_mult: 1,
//     //         l_incr: 1,
//     //         l_decr: 1,
//     //         r_incr: 1,
//     //         r_decr: 1,

//     //     },

//     // },


// }


