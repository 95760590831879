import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FormControl, Select, MenuItem } from '@mui/material';

class AdjustDifficulty extends React.Component {
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.vidRef = React.createRef();

    this.state={
      difficultyValue: "basic"
    }

  }

  componentDidMount() {
    
  }

  componentDidUpdate() {
    
  }

  
  componentWillUnmount() {
  }

  onPressed() {
    this.props.onDifficultyAdjusted(this.state.difficultyValue);
  }

  handleButton() {
    console.log("Button");
  }

  handleDifficultyChange(event) {
    this.setState({ difficultyValue: event.target.value });
}

  render() {

    return (


      <div>
        <br></br>

        <Typography variant="h4" color="text.primary">
          Adjust difficulty
        </Typography>

        <br></br>

        <Typography variant="h6" color="text.primary">
          Please adjust the difficulty level of the exercise
          <br></br>
          <br></br>
          When ready, press 'OK'
        </Typography>

        <div>
                <br></br>
                <FormControl >
                    {/* <InputLabel id="demo-simple-select-label">Please select</InputLabel> */}
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.difficultyValue}
                        label=""
                        onChange={this.handleDifficultyChange.bind(this)}
                    >
                      <MenuItem value="basic">basic</MenuItem>
                      <MenuItem value="medium">medium</MenuItem>
                      <MenuItem value="advanced">advanced</MenuItem>
                    </Select>

                    {/* <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.handleButton.bind(this)}>OK</Button> */}

                </FormControl>
                </div>

        <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.onPressed.bind(this)}>OK</Button>

      </div>
    );

  };
}

export default AdjustDifficulty;