import React, { Component } from 'react';

// import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
// import Tooltip from '@mui/material/Tooltip';

// import BoltIcon from '@mui/icons-material/Bolt';
// import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
// import ShutterSpeedIcon from '@mui/icons-material/ShutterSpeed';

// import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
// import BalanceIcon from '@mui/icons-material/Balance';

// import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
// import StarBorderIcon from '@mui/icons-material/StarBorder';
// import StarRateIcon from '@mui/icons-material/StarRate';
// import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
// import FilterVintageIcon from '@mui/icons-material/FilterVintage';
import { Typography } from '@mui/material';
// import { gridColumnsTotalWidthSelector } from '@mui/x-data-grid';

class GDPR extends React.Component {

  constructor(props) {
    super(props);

    let lang = "en";
    if (props.lang) lang = this.props.lang;

    console.log("PROPS", props);

    this.state = {
      lang: lang
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props === prevProps) return;

    let lang = "en";
    if (this.props.lang) lang = this.props.lang;
    this.setState({ lang: lang });
  }

  render() {

    let lang = this.state.lang;

    if (lang === "en") return (
      <Box sx={{ m: 'auto', maxWidth: 800 }}>
        <br></br>
        <Typography variant="h5" align='center'>
          Obimon Systems Ltd. Privacy Policy
        </Typography>
        <br></br>

        <Typography variant="body2" align='left' component='div'>
          This privacy policy will explain how our organization uses the personal data we collect from you when you use our
          website, software and devices.
          <br></br><br></br>
          Topics:
          <br></br>
          <ul>
            <li>What data do we collect?</li>
            <li>How will we use your data?</li>
            <li>How do we store your data?</li>
            <li> What are your data protection rights?</li>
            <li> How do we use cookies?</li>
            <li> Privacy policies of other websites</li>
            <li>Changes to our privacy policy</li>
            <li>How to contact us</li>
            <li>How to contact the appropriate authorities</li>
          </ul>
        </Typography>

        <Typography variant="h4" align='center'>
        </Typography>

        <Typography variant="h6" align='left' component='div'>
          What data do we collect?
        </Typography>
        <Typography variant="body2" align='left' component='div'>
          You directly provide Obimon Systems Ltd. with most of the data we collect. We collect data when you:
          <ul>
            <li>Register for any of our products or services</li>
            <li>Participate in the measurements with the equipment of Obimon Systems Ltd.</li>
            <li>Voluntarily complete a customer survey or provide feedback on any of our message boards or via email</li>
            <li>Use or view our website via your browser's cookies</li>
          </ul>
        </Typography>

        <Typography variant="h6" align='left' component='div'>
          How will we use your data?
        </Typography>
        <Typography variant="body2" align='left' component='div'>
          Obimon Systems Ltd. collects your data so that we can:
          <ul>
            <li>Manage your account</li>
            <li>Analyse your data so that you can keep track of your progress</li>
            <li>Offer personalized trainings, exercises via our services</li>
          </ul>
          Obimon Ltd. will not share your personal data with third parties, only if you give explicit permission to do so.
        </Typography>

        <br></br>
        <Typography variant="h6" align='left' component='div'>
          How do we store your data?
        </Typography>
        <Typography variant="body2" align='left' component='div'>
          Obimon Systems Ltd. securely stores your data at our servers or in cloud service provider's system.
          We implement appropriate technical and organizational measures to ensure a level of security appropriate
          to the risk both in respect of the data stored.
          We will keep your personal data until you have an active account in our system or you ask to remove your personal data.
          You can close your account or ask to remove your personal data and we will delete it from our systems.
        </Typography>

        <br></br>
        <Typography variant="h6" align='left' component='div'>
          What are your data protection rights?
        </Typography>

        <Typography variant="body2" align='left' component='div'>
          We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the
          following:
          <br></br><br></br>
          <b>The right to access</b> – You have the right to request us for copies of your personal data.
          We may charge you a small fee for this service.
          <br></br>
          <b> The right to rectification </b>– You have the right to request that we correct any information you believe is inaccurate. You also have the right to request Our Company to complete the information you believe is incomplete.
          <br></br>
          <b>The right to erasure</b> – You have the right to request that we erase your personal data, under certain conditions.
          <br></br>
          <b>The right to restrict processing</b> – You have the right to request that we restrict the processing of your
          personal data, under certain conditions.
          <br></br>
          <b>The right to object to processing</b> – You have the right to object to our processing of your personal data,
          under certain conditions.
          <br></br>
          <b>The right to data portability</b> – You have the right to request that we transfer the data that we have
          collected to another organization, or directly to you, under certain conditions.
          <br></br><br></br>
          If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please
          contact us at our email: info@obimon.com.
        </Typography>

        <br></br>
        <Typography variant="h6" align='left' component='div'>
          How do we use cookies?
        </Typography>
        <Typography variant="body2" align='left' component='div'>
          Cookies are text files placed on your compiter to collect standard Internel log information and visitor behavior information.
          When you visit our websites, we may collect information from you automatically though cookies or similar technology.

          We use cookies in a range of ways to improve your experience on our website, including:
          <ul>
            <li>Keeping you signed in,</li>
            <li>Understanding how you use our website,</li>
            <li>Improve your user experience.</li>
          </ul>

        </Typography>

        <br></br>
        <Typography variant="h6" align='left' component='div'>
          Privacy policies of other websites
        </Typography>
        <Typography variant="body2" align='left' component='div'>
          Our website may contain links to other websites. Our privacy policy applies only to our website, so if you
          click on a link to another website, you should read their privacy policy.
        </Typography>

        <br></br>
        <Typography variant="h6" align='left' component='div'>
          Changes to our privacy policy
        </Typography>
        <Typography variant="body2" align='left' component='div'>
          Obimon Systems Ltd. keeps its privacy policy under regular review and places any updates on this web page. This privacy
          policy was last updated on 19 February 2022.
        </Typography>

        <br></br>
        <Typography variant="h6" align='left' component='div'>
          How to contact us?
        </Typography>
        <Typography variant="body2" align='left' component='div'>
          If you have any questions about Obimon Systems Ltd.’s privacy policy, the data we hold on you, or you would like to exercise
          one of your data protection rights, please do not hesitate to contact us.
          our e-mail: info@obimon.com, or via our web page: www.obimon.com.
        </Typography>

        <br></br>
        <Typography variant="h6" align='left' component='div'>
          How to contact the appropriate authority?
        </Typography>
        <Typography variant="body2" align='left' component='div'>
          Should you wish to report a complaint or if you feel that Obimon Ltd. has not addressed your concern in a satisfactory
          manner, you may contact:
          Nemzeti Adatvédelmi és Információszabadság Hatóság.
          Address: 1125 Budapest, Szilágyi Erzsébet fasor 22/C, Mail address: 1530 Budapest, Pf. 5.
          Phone: +36-1-391-1400, Fax: +36-1-391-1410.
          E-mail: ugyfelszolgalat@naih.hu.
          Homepage: http://www.naih.hu.
        </Typography>

        <br></br>
        <br></br>

      </Box>

    );

    if (lang === "hu") return (
      <Box sx={{ m: 'auto', maxWidth: 800 }}>
        <br></br>
        <Typography variant="h5" align='center'>
          Obimon Systems Ltd. adatvédelmi szabályok
        </Typography>
        <br></br>

        <Typography variant="body2" align='left' component='div'>
          Az alábbi adatvédelmi szabályokban összefoglaljuk, hogy cégünk hogyan kezeli azokat a személyes
          adatokat, amelyeket a Web-oldalunk, szoftvereink és eszközeink használata során oszt meg velünk.
          <br></br><br></br>
          Tartalom:
          <br></br>
          <ul>
            <li>Milyen adatokat gyűjtünk?</li>
            <li>Hogyan használjuk az adatokat?</li>
            <li>Hogyan tároljuk az adatokat?</li>
            <li>Milyen adatvédelemmel kapcsolatos jogai vannak a felhasználóknak?</li>
            <li>Hogyan használunk sütiket (cookie-kat)?</li>
            <li>Más Web-oldalakkal kapcsolatos adatvédelmi szabályok</li>
            <li>Adatvédelmi szabályaink változása</li>
            <li>Kapcsolat</li>
            <li>Hogyan érheti el az adatvédelemmel foglalkozó hatóságokat?</li>
          </ul>
        </Typography>

        <Typography variant="h4" align='center'>
        </Typography>

        <Typography variant="h6" align='left' component='div'>
          Milyen adatokat gyűjtünk?
        </Typography>
        <Typography variant="body2" align='left' component='div'>
          Ön adja meg nekünk az adatainak többségét, amiket munkánk során felhasználunk. Az alábbi események
          során keletkeznek a felhasznált adatok, ha Ön:
          <ul>
            <li>regisztrál egy termékünk vagy szolgáltatásunk használatára,</li>
            <li>részt vesz az Obimon Systems Kft. eszközeivel végzett mérésekben,</li>
            <li>önként kitölti egy kérdőívünket vagy visszajelzést ad az üzenőfalunkon vagy email-ben,</li>
            <li>megtekinti vagy használja a Web-siteunkat sütik (cookie-k) segítségével.</li>
          </ul>
        </Typography>

        <Typography variant="h6" align='left' component='div'>
          Hogyan használjuk az adatokat?
        </Typography>
        <Typography variant="body2" align='left' component='div'>
          Az Obimon Systems Kft. azért gyűjti az Ön adatait, hogy:
          <ul>
            <li>Menedzselje az Ön fiókját (account)</li>
            <li>Feldolgozhassa az adatokat az Ön fejlődésének elemzése céljából</li>
            <li>Személyre szabott tréningeket, gyakorlatokat javasoljunk Önnek termékeink és
              szolgáltatásaink segítségével</li>
          </ul>
          Az Obimon Systems Kft. csak akkor osztja meg az Ön személyes adatait harmadik féllel, amennyiben erre
          engedélyt ad.
        </Typography>

        <br></br>
        <Typography variant="h6" align='left' component='div'>
          Hogyan tároljuk az adatokat?
        </Typography>
        <Typography variant="body2" align='left' component='div'>
          Az Obimon Systems Kft. biztonságos adattárolást valósít meg a szerverein vagy egy felhő szolgáltató
          (cloud provider) rendszerében. Olyan műszaki és szervezeti megoldásokat alkalmazunk, amelyek
          megfelelő adatbiztonsági szintet nyújtanak. Az Ön adatait addig tároljuk, amíg
          aktív fiókja van rendszerünkben, vagy amíg ehhez hozzájárul, azaz nem kéri adatainak
          törlését. Ön bármikor megszüntetheti a fiókját vagy kérheti adatainak törlését, ebben az
          esetben az adatait töröljük a rendszerünkből.
        </Typography>

        <br></br>
        <Typography variant="h6" align='left' component='div'>
          Milyen adatvédelemmel kapcsolatos jogai vannak a felhasználóknak?
        </Typography>

        <Typography variant="body2" align='left' component='div'>
          Szeretnénk elősegíteni, hogy Ön tisztában legyen az adatvédelemmel kapcsolatos jogaival. Önnek joga van:
          <br></br><br></br>
          <b>Hozzáférni az adataihoz</b> – kérheti a tárolt személyes adatainak másolatát. Ezzel
          kapcsolatban előfordulhat, hogy kisebb díjat számítunk fel.
          <br></br>
          <b>Helyesbítés kérése</b>– kérheti, hogy helyesbítsük az adatait, amennyiben tévesnek ítéli
          azokat. Az adatok kiegészítését is kérheti, amennyiben ezt szükségesnek tartja.
          <br></br>
          <b>Adattörlés kérése</b> – kérheti adati törlését bizonyos feltételek mellett.
          <br></br>
          <b>Adatfeldolgozás korlátozásának kérése</b> – kérheti adatai feldolgozásának korlátozását bizonyos feltételek mellett.
          <br></br>
          <b>Adatfeldolgozás megtiltása</b> – kérheti, hogy ne dolgozzuk fel adatait bizonyos feltételek mellett.
          <br></br>
          <b>Adatok továbbadása</b> – kérheti, hogy adjuk tovább az adatait harmadik félnek,
          vagy közvetlenül Önnek, bizonyos feltételek mellett.
          <br></br><br></br>
          Amennyiben ilyen kérés érkezik hozzánk, egy hónapon belül válaszolunk a kérésre. Amennyiben a fenti
          jogai közül bármelyiket gyakorolni kívánja, kérjük lépjen velünk kapcsolatba: info@obimon.com.
        </Typography>

        <br></br>
        <Typography variant="h6" align='left' component='div'>
          Hogyan használunk sütiket (cookie-kat)?
        </Typography>
        <Typography variant="body2" align='left' component='div'>
          Amikor Ön a Web-oldalunkat meglátogatja, egy kisméretű adatfájlt, úgynevezett sütit
          (cookie) hozhatunk létre a számítógépén, amely kényelmi funkciókat láthatnak el vagy az oldal
          felhasználásáról tárolhatnak információt. Ezen sütik vagy hasonló
          technológiai megoldások segítségével automatikusan rögzített adatok többféle célt szolgálhatnak:
          <ul>
            <li>Folyamatos bejelentkezést biztosítsunk</li>
            <li>Web-oldalunk látogatottságát és használatát elemezhessük</li>
            <li>Növeljük a felhasználói élményt</li>
          </ul>

        </Typography>

        <br></br>
        <Typography variant="h6" align='left' component='div'>
          Más Web-oldalakkal kapcsolatos adatvédelmi szabályok
        </Typography>
        <Typography variant="body2" align='left' component='div'>
          Web-oldalunk más Web-oldalakra mutató linkeket tartalmazhat. Adatvédelmi szabályaink csak az
          Obimon Systems Kft. Web-oldalára vonatkoznak, amennyiben a hivatkozásokat követve más Web-
          oldalakra jut, ott az adott oldal adatvédelmi szabályai érvényesek.
        </Typography>

        <br></br>
        <Typography variant="h6" align='left' component='div'>
          Adatvédelmi szabályaink változása
        </Typography>
        <Typography variant="body2" align='left' component='div'>
          Az Obimon Systems Kft. időközönként felülvizsgálja és Web oldalán elérhetővé teszi az adatvédelmi
          szabályait. Jelen szabályok utolsó módosítása 2022 március 2-án történt.
        </Typography>

        <br></br>
        <Typography variant="h6" align='left' component='div'>
          Kapcsolat
        </Typography>
        <Typography variant="body2" align='left' component='div'>
          Ha bármely kérdése van a fenti adatvédelmi szabályokkal kapcsolatban, az tárolt adatokkal kapcsolatban
          vagy valamely adatkezeléssel kapcsolatos jogát szeretné gyakorolni, kérjük lépjen velünk kapcsolatba
          email-ben: info@obimon.com, vagy a web-lapunkon keresztül: www.obimon.com.
        </Typography>

        <br></br>
        <Typography variant="h6" align='left' component='div'>
          Hogyan érheti el az adatvédelemmel foglalkozó hatóságokat?
        </Typography>
        <Typography variant="body2" align='left' component='div'>
          Amennyiben panaszt kíván tenni vagy úgy érzi, hogy az Obimon Systems Kft. nem megfelelően kezelte az
          adatvédelemmel kapcsolatos kérését, a következő hatósághoz fordulhat: Nemzeti Adatvédelmi és
          Információszabadság Hatóság. Cím: 1125 Budapest, Szilágyi Erzsébet fasor 22/C, Postacím: 1530
          Budapest, Pf. 5. Telefon: +36-1-391-1400, Fax: +36-1-391-1410. E-mail: ugyfelszolgalat@naih.hu.
          Homepage: http://www.naih.hu.
        </Typography>

        <br></br>
        <br></br>

      </Box>

    );
  }

}

export default GDPR;