import React from 'react';
import { makeNoise2D } from "open-simplex-noise";
import { makeNoise3D } from "open-simplex-noise";

import { Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const edaAppService = '43974957-3487-9347-5977-654321987654' // app eda service

// ,// app eda 
// app eda characteristics
//   '43789734-9798-3479-8347-983479887878',//eda data uid characteristics
//   '43789734-9798-3479-8347-98347988787c',//eda keep alive
//   '43974957-3487-9347-5977-654321987654',//eda go to ota



const edaOtaService = '8a97f7c0-8506-11e3-baa7-0800200c9a66' // ota OTA service


//ota characteristics
// OTA service: 8a97f7c0-8506-11e3-baa7-0800200c9a66
// Image Characteristic: 122e8cc0-8508-11e3-baa7-0800200c9a66
// New Image Characteristic: 210f99f0-8508-11e3-baa7-0800200c9a66
// New Image TU Content Characteristic:2691aa80-8508-11e3-baa7-0800200c9a66
// ExpectedImageTUSeqNumber Characteristic: 2bdc5760-8508-11e3-baa7-0800200c9a66



const DeviceMode = {
  UNKNOWN: "Unknown",
  OTA: "OTA",
  APP: "APP",
}


// const CHUNK_LENGTH = 240;
//mtu == 200 byte 4 header + (13*16)Byte image data
const CHUNK_LENGTH = 13 * 16;
const NUM_OF_CHUNKS_TO_SEND = 8 //notif interval from OTA ble
const SECTOR_SIZE = 8 * 1024;

let writeAddressCharacteristic;
let indicateCharacteristic;
let writeWithoutResponseCharacteristic;
let fileContent;
let fileLength;
let nbSector;
let readyToReceive = false;
let uploadClicked = false;
var myDevice;

function resetVariables() {
  writeAddressCharacteristic = null;
  indicateCharacteristic = null;
  writeWithoutResponseCharacteristic = null;
  fileContent = null;
  fileLength = null;
  nbSector = null;
  readyToReceive = null
  uploadClicked = null
  myDevice = null
}


export default class Ota extends React.Component {

  state = {
    connected: false,
    disconnected: true,
    allServices: [],
    devServices: [],
    devCharacteristics: [],
    devMode: DeviceMode.UNKNOWN,
    // devMode: DeviceMode.OTA,//for debug purpuse
    manualySettingNbSector: false,
    uploadButtonEnabled: false,
    uploadPercentage: 0,
  };

  constructor(props) {
    super(props);
    console.log("Ota constr", props.size);
    // this.state = {
    // }
  }



  getSupportedProperties(characteristic) {
    let supportedProperties = [];
    for (const p in characteristic.properties) {
      if (characteristic.properties[p] === true) {
        supportedProperties.push(p.toUpperCase());
      }
    }
    return supportedProperties.join(', ');
  }

  onDisconnected() {
    if (this.state.devMode == DeviceMode.OTA) {
      console.log('HEADER - > OTA Bluetooth Device disconnected');
      this.state.disconnected = true
      this.state.allServices = []
    }
  }


  // Notification / indications handler
  notifHandler = (event) => {
    console.log("Notification / Indication :> received");
    var buf = new Uint8Array(event.target.value.buffer);
    console.log(buf);
    let replyCounter = buf[1] << 8 | buf[0]
    let errCode = buf[3] << 8 | buf[2]

    console.log('replyCounter ', replyCounter, ' errCode', errCode)
    if (uploadClicked == false) {
      console.log('not yet clicked return')
      return;
    }

    //912 - flash verif error - resend
    if (errCode == 0 || errCode == 912) {
      if (errCode == 912) {
        console.log('resend')
      }
      readyToReceive = true
      this.sliceAndSend(replyCounter);
    }

    return;
  }

  connect = () => {
    console.log('Requesting Bluetooth Device...');
    myDevice = navigator.bluetooth.requestDevice({
      filters:
        [
          {
            namePrefix: "_"            // BLE_HealthThermometer
          }],
      optionalServices: [edaAppService, edaOtaService],
    })


      .then(device => {
        myDevice = device;
        console.log("dev", device)
        myDevice.addEventListener('gattserverdisconnected', () => this.onDisconnected);
        return device.gatt.connect();
      })

      .then(server => {
        return server.getPrimaryServices();
      })

      .then(services => {
        console.log('HEADER - Getting Characteristics...');
        let queue = Promise.resolve();
        services.forEach(service => {
          console.log(service);
          if (service.uuid === edaOtaService) {
            this.setState({ devMode: DeviceMode.OTA })
            console.log('IN OTA MODE')
          } else if (service.uuid === edaAppService) {
            this.setState({ devMode: DeviceMode.APP })
          }
          else {
            console.log('NOT OTA MODE', service.uuid)
          }

          // createLogElement(service, 3, 'SERVICE')
          this.setState(prevState => ({
            devServices: [...prevState.devServices, service]
          }))

          queue = queue.then(_ => service.getCharacteristics()
            .then(characteristics => {
              console.log(characteristics);
              console.log('HEADER - > Service: ' + service.device.name + ' - ' + service.uuid);
              characteristics.forEach(characteristic => {

                this.setState(prevState => ({
                  devCharacteristics: [...prevState.devCharacteristics, characteristic]
                }))

                if (this.state.devMode === DeviceMode.APP) {
                  //lookup OTA characteristics
                  switch (characteristic.uuid) {
                    case "43789734-9798-3479-8347-98347988787a":
                      console.log('APP go to ota char.  found')
                      writeAddressCharacteristic = characteristic;
                      break;

                  }
                }
                else if (this.state.devMode === DeviceMode.OTA) {
                  //lookup OTA characteristics
                  switch (characteristic.uuid) {
                    case "210f99f0-8508-11e3-baa7-0800200c9a66":
                      writeAddressCharacteristic = characteristic;
                      break;
                    case "2bdc5760-8508-11e3-baa7-0800200c9a66":
                      indicateCharacteristic = characteristic;
                      indicateCharacteristic.startNotifications();
                      indicateCharacteristic.oncharacteristicvaluechanged = this.notifHandler;
                      break;
                    case "2691aa80-8508-11e3-baa7-0800200c9a66":
                      writeWithoutResponseCharacteristic = characteristic;
                      break;

                  }
                }

                // props.setAllCharacteristics((prevChar) => [
                //   ...prevChar,
                //   {
                //     characteristic
                //   },
                // ]);
                console.log('HEADER - >> Characteristic: ' + characteristic.uuid + ' ' + this.getSupportedProperties(characteristic));
                // createLogElement(characteristic, 4, 'CHARACTERISTIC')
              });
            }));
        });

        return queue;
      })
      .catch(error => {
        console.error(error);
      });



  }


  // Send to device the action to be ready for the update of the firmware
  writeAddress = async () => {




    // imageSize = (uint32_t)(att_data[4] << 24) + (uint32_t)(att_data[3] << 16) + (uint32_t)(att_data[2] << 8) + att_data[1];
    // imageBase = (uint32_t)(att_data[8] << 24) + (uint32_t)(att_data[7] << 16) + (uint32_t)(att_data[6] << 8) + att_data[5];
    // notification_range = NOTIFICATION_INTERVAL(att_data[0]); 




    // let address = document.getElementById("startSectorInput").value
    let sizeHex = fileLength.toString(16);
    sizeHex = sizeHex.padStart(8, '0')


    let sizeHexFistPart = sizeHex.substring(0, 2);
    let sizeHexSecondePart = sizeHex.substring(2, 4);
    let sizeHexThirdPart = sizeHex.substring(4, 6);
    let sizeHexForthPart = sizeHex.substring(6, 8);

    sizeHexFistPart = parseInt(sizeHexFistPart, 16);
    sizeHexSecondePart = parseInt(sizeHexSecondePart, 16);
    sizeHexThirdPart = parseInt(sizeHexThirdPart, 16);
    sizeHexForthPart = parseInt(sizeHexForthPart, 16);


    let address = "10051800";//0x10051800
    let hexStringFistPart = address.substring(0, 2);
    let hexStringSecondePart = address.substring(2, 4);
    let hexStringThirdPart = address.substring(4, 6);
    let hexStringForthPart = address.substring(6, 8);

    hexStringFistPart = parseInt(hexStringFistPart, 16);
    hexStringSecondePart = parseInt(hexStringSecondePart, 16);
    hexStringThirdPart = parseInt(hexStringThirdPart, 16);
    hexStringForthPart = parseInt(hexStringForthPart, 16);


    let myWord = new Uint8Array(9);

    let idx = 0

    myWord[0] = 1; // notif interval ?? todo what is it

    idx = 1;
    myWord[idx + 0] = sizeHexForthPart; // size
    myWord[idx + 1] = sizeHexThirdPart; // size
    myWord[idx + 2] = sizeHexSecondePart; // size
    myWord[idx + 3] = sizeHexFistPart; // size

    idx = 5
    myWord[idx + 0] = hexStringForthPart; // Address
    myWord[idx + 1] = hexStringThirdPart; // Address
    myWord[idx + 2] = hexStringSecondePart; // Address
    myWord[idx + 3] = hexStringFistPart; // Address
    console.log("Writing address >> " + myWord);

    try {
      console.log("Writing address >> " + myWord, "sizeHex> ", sizeHex, "address> ", address, "fileLength>", fileLength);

      await writeAddressCharacteristic.writeValue(myWord);

      // createLogElement(myWord, 2, "OTA WRITE");
    }
    catch (error) {
      console.log('2 : Argh! ' + error);
    }
  }


  calculateNbSector = async () => {
    fileLength = fileContent.length;
    nbSector = fileLength / SECTOR_SIZE;
    nbSector = Math.ceil(nbSector);
    // document.getElementById("nbSector").value = nbSector;

    console.log("file length = ");
    console.log(fileLength);
    console.log("NbSector = ");
    console.log(nbSector);
  }
  goToOtaButtonClick = async () => {
    let myWord = new Uint8Array(2);
    myWord[0] = 1; // Action 

    try {
      await writeAddressCharacteristic.writeValue(myWord);
      console.log("Writing >> " + myWord);
      // createLogElement(myWord, 2, "OTA WRITE");
    }
    catch (error) {
      console.log('2 : Argh! ' + error);
    }
    this.goToAtaFinished()
  }
  startUploadButtonClick = async () => {

    this.calculateNbSector()



    readyToReceive = true
    uploadClicked = true
    this.writeAddress();
    this.sliceAndSend(0);
  }


  goToAtaFinished = () => {
    //device was in app mode and sent to OTA mode

    resetVariables()
    this.setState({
      connected: false,
      disconnected: true,
      allServices: [],
      devServices: [],
      devCharacteristics: [],
      devMode: DeviceMode.UNKNOWN,
      manualySettingNbSector: false,
      uploadButtonEnabled: false,
      uploadPercentage: 0,
    })
  }
  otaFinished = () => {
    //called when last package download
    //hopefully the device is reseted

    resetVariables()
    this.setState({
      connected: false,
      disconnected: true,
      allServices: [],
      devServices: [],
      devCharacteristics: [],
      devMode: DeviceMode.UNKNOWN,
      manualySettingNbSector: false,
      uploadButtonEnabled: false,
      uploadPercentage: 0,
    })

  }

  sliceAndSend = async (from_chunk) => {
    // let progressUploadBar = document.getElementById('progressUploadBar');

    let totalBytes = 0;

    // readyToReceive = true;
    if (readyToReceive == true) {
      // readyToReceive = false;

      for (let act_chunk = from_chunk; act_chunk < NUM_OF_CHUNKS_TO_SEND + from_chunk; act_chunk++) {

        let start = act_chunk * CHUNK_LENGTH;
        let end = start + CHUNK_LENGTH;
        let sub;


        // Slice the fileContent (the binary file) into small chucks of CHUNK_LENGTH
        // And send them to the device
        // Start the timer
        var startTime = performance.now()
        if (end > fileLength) {
          console.log('No more to download ')
          //reset from ota
          this.otaFinished()
          return
        }


        //[0] => expected chsum
        //[1-208] => data   208=13*16 num of chunks
        //[209] need req
        //[210] seqnum low
        //[211] seqnum high
        console.log('seqnum', act_chunk)
        let seqnumHex = act_chunk.toString(16).padStart(4, '0');

        let seqNumHigh = parseInt(seqnumHex.substring(0, 2), 16);
        let seqNumLow = parseInt(seqnumHex.substring(2, 4), 16);
        let needNotif = 1; // request notification after blocks
        let fileChunk = fileContent.slice(start, end)
        let toSend = new Uint8Array(CHUNK_LENGTH + 4) // 4 is header

        toSend.set(fileChunk, 1)
        toSend.set([needNotif], CHUNK_LENGTH + 1) //209
        toSend.set([seqNumLow], CHUNK_LENGTH + 2)//210
        toSend.set([seqNumHigh], CHUNK_LENGTH + 3)//211


        //calc checksum
        let chsum = 0;
        for (let checkSumi = 1; checkSumi < CHUNK_LENGTH + 4; checkSumi++) {
          chsum ^= toSend[checkSumi]
        }
        toSend.set([chsum], 0)

        start = end;
        end += CHUNK_LENGTH;
        await writeWithoutResponseCharacteristic.writeValue(toSend)
        totalBytes += toSend.length
        let percentage = Number((end * 100) / fileLength)
        this.setState({ uploadPercentage: percentage })

        console.log(start + "> (" + totalBytes + ") writing " + toSend.length + ' bytes..');

        var endTime = performance.now()

        this.setState({ uploadButtonEnabled: false })

      }
    } else {
      console.log(" Not ready to receive ...");
    }
  }

  // Read the file selected from the file input and upload it
  browseFileButtonClick = async (input) => {
    console.log("FileLoader")

    this.setState({ uploadButtonEnabled: false })

    fileContent = input.target.files[0];

    let reader = new FileReader();
    reader.readAsArrayBuffer(fileContent);
    reader.onload = async function () {
      let uint8View = new Uint8Array(reader.result);


      //file len padding for chunk len data
      let fileLen16B = Math.ceil(uint8View.length / (CHUNK_LENGTH)) * CHUNK_LENGTH
      let rightPadding = fileLen16B - uint8View.length

      console.log('aa', uint8View.length, fileLen16B, rightPadding)
      fileContent = new (uint8View.constructor)(uint8View.length + rightPadding);
      fileContent.set(uint8View, 0);
      // Value, start position, end position
      fileContent.fill(0xff, uint8View.length);
      console.log(fileContent);




    }
    this.setState({ uploadButtonEnabled: true })
  }



  componentDidUpdate() {


  }

  componentWillUnmount() {
    console.log("Tree unmounted!");
    //if (this.animation) cancelAnimationFrame(this.animation);


  }

  componentDidMount() {
    console.log("Ota )))))))))))))))))))))");


  }


  render() {


    return (
      // <Paper sx={{ width: 1, height: 1 }} margin='auto' alignItems="center">

      <Box sx={{ flexGrow: 1 }} >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div>
              <Button variant="contained" onClick={this.connect}
              >Connect</Button>
              <Button variant="outlined">Disconnect</Button>
            </div>
            <div>
              <p>You are {this.state.connected}  {this.state.age}.</p>
              <p> ACT MODE {this.state.devMode}</p>
            </div>

          {/* APP stuff  - send to ota*/}
            {this.state.devMode === DeviceMode.APP && (
              <div>
                <Button variant="contained" onClick={this.goToOtaButtonClick}
                >Go to OTA</Button>
              </div>
            )}
            {/* OTA stuff - download file */}
            {this.state.devMode === DeviceMode.OTA && (
              <div>



                <input type="file" onChange={(e) => this.browseFileButtonClick(e)} />
                {this.state.uploadButtonEnabled && (
                  <Button variant="contained" onClick={this.startUploadButtonClick}
                  >Start download</Button>
                )}
                <p>Percentage {this.state.uploadPercentage} %</p>



              </div>

            )}

         
          </Grid>

        </Grid>
      </Box >

    );

  }


}

