import { isUndefined } from 'lodash';
import React, { Component } from 'react';

/* import { Line } from 'react-chartjs-2';
import { defaults } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
 */
import { CalcScore } from './scripts/calc_score';
import MySessions from './pages/MySessions';


export default class ResultSelector extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user:"",
      userSelected: false,
    }
  }

  

  render() {

    if (!this.state.userSelected) {
      return (
        <div>
          <p>Select user</p>
          <form onSubmit={event => {

              this.fetchRecords(this.state.user);

              console.log(this.state.sessions);

              this.setState(
                {
                  userSelected: true
                }
              );

            }
          } >

          <label>
                <input
                name="user"
                value={this.state.user}
                onChange={event => this.setState({
                  user: event.target.value,
                })}                
                required
              />
            </label>
            <input type="submit" value="Submit" />

          </form>
        </div>
      )
    }

    return (
      <div>

        Inspect records for user: <b>{this.state.user}</b>
        <br></br>
        
        <MySessions user={this.state.user} sessions={this.state.sessions} />

        <button onClick={()=>{this.back_cb();}}>BACK</button>


      </div>
    );

  }

  back_cb() {
    console.log("BACKCB");
    this.setState({userSelected: false, user:""});
  }  

  async fetchRecords(user) {
    console.log("ResultSelctor fetchRecords", user);

    let resp = await fetch('/api/find', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: this.state.user,
      })
    });

    let sessions = await resp.json();

    //console.log("APP loadRecords num:", sessions.length);

    // TEST RECALULATE SCORES
    for (let s of sessions) {
      if (s.data.scores) continue;

      if (!s.data.eda) continue;
      if (s.data.eda.length < 10) continue;

      let calc_score = new CalcScore(s.params);
      let scores = calc_score.calc_all_db(s.data.eda);

      s.data.scores = scores; // REPLACE!
    }

    this.setState({sessions: sessions});
  }
}