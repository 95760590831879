import React from 'react'
// import Wave from 'react-wavify'
// import { Howl } from 'howler'
import { CalcScore } from '../scripts/calc_score';
import { CalcSessionStats } from '../scripts/calc_sessionstats';

import Backdrop from '@mui/material/Backdrop';
import Fab from '@mui/material/Fab';
// import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import NavigationIcon from '@mui/icons-material/Navigation';
import WaveResultCard from '../WaveResultCard';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography'
import { CircularProgress } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { MyContext } from '../MyContext';
import Chart from 'chart.js/auto';

class RawEda extends React.Component {
    static contextType = MyContext;

    constructor(props) {
        super(props);

        this.treeRef = React.createRef();

        this.col1 = [178, 137, 239, 0.7];

        this.color1 = this.rgba(this.col1);

        let params = props.params;

        if (process.env.NODE_ENV === 'development') this.test = true;
        this.simrelax = 100;

        this.decr_ended_ts = false;

        this.calc_score = new CalcScore(params);
        this.calc_stats = new CalcSessionStats();

        this.eda = [];
        this.acc = [];
        this.fineacc = [];
        this.events = [];

        this.scores = [];

        this.n = 0;


        this.t = 10;
        this.start = Date.now();
        this.end = 0;

        this.success = false;



        this.state = {
            showResult: false,
            amplitude: 0,
            askFeedback: false,
            textAreaValue: "",
            bImg: params.backgroundImage,
        }

        this.addData = this.addData.bind(this);

    }

    finished() {
        console.log("Finished called");
        // on finish, we do not send data, because we already called store_cb in "ended"
        this.props.finished_cb({}, false);
    }


    async ended() {
        if (this.endedCalled) return;
        this.endedCalled = true;

        console.log("Ended");

        // let sessionData = {
        //     eda: this.eda,
        //     scores: this.scores,
        // };

        // let sessionId = await this.props.store_cb(sessionData);

        //console.log("**************************** Session ID:", sessionId);

        //Now read back
        // await fetch


        this.setState({ askFeedback: true, ended: true });
        //this.setState({ showResult: true, sessionData: sessionData, ended: true });
    }

    ////////////////////////////////////////////////////////////////////////////////////

    // function to update the chart 
    addData(chart, label, data) {
        chart.data.labels.push(label);

        // trim the data to have onlly last 100 points
        if (chart.data.labels.length > 100) {
            chart.data.labels.shift();
        }

        chart.data.datasets.forEach((dataset) => {
            dataset.data.push(data);
            if (dataset.data.length > 100) {
                dataset.data.shift();
            }
        });
        chart.update();
    }

    componentDidMount() {
        //////////////////////////////
        this.canvas = document.getElementById('myChart');
        this.canvas.height = 75;

        console.log("Canvas", this.canvas);

        this.data = {
            labels: [],
            datasets: [{
                label: 'EDA',
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgb(255, 99, 132)',
                data: [],
            }]
        };

        const config = {
            type: 'line',
            data: this.data,
            options: {
                responsive: true, // Enable responsive resizing
                maintainAspectRatio: false, // Allow full width and height adjustment
                animation: false, // Disable animations
                scales: {
                    x: {
                        grid: {
                            drawOnChartArea: false // Disable vertical grid lines
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false // Hide the legend entirely
                    }
                }
            }
        };

        this.myChart = new Chart(
            this.canvas,
            config
        );

        window.addEventListener('resize', () => {
            this.myChart.resize();
        });

        // randomly add new data
        // setInterval(() => {
        //     const newLabel = (Math.random() + 1).toString(36).substring(7);
        //     const newData = Math.floor(Math.random() * 10);
        //     this.addData(this.myChart, "x", newData); // `this` now correctly refers to the class instance
        // }, 1000);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.myChart.resize);
    }

    ////////////////////////////////////////////////////////////////////////////////////

    async save() {
        this.setState({ saving: true });

        let sessionData = {
            eda: this.eda,
            acc: this.acc,
            fineacc: this.fineacc,
            events: this.events,
            scores: this.scores,
            feedback: this.state.textAreaValue,
            // success: this.success
        };

        let sessionId = await this.props.store_cb(sessionData, this.start);

        console.log("**************************** Session ID:", sessionId);

        this.ses = {
            type: 'WaveBasic',
            exercise: this.props.exercise,
            campaign: this.props.campaign,
            data: sessionData,
            ts: this.start,
            device: this.context.deviceName,
            user: this.context.user
        }

        console.log("Save: ses", this.ses);


        //Now read back
        // await fetch

        this.setState({ showResult: true, askFeedback: false, sessionData: sessionData, saving: false });

    }

    onAcc(acc) {
        if (this.state.ended) {
            return;
        }

        // console.log(acc);
        let ts = Date.now();

        this.fineacc.push([ts, acc]);
    }

    onEda(gsr, acc) {

        if (this.state.ended) {
            return;
        }

        let ts = Date.now();

        if (!this.lastts) {
            this.lastts = ts;
            return;
        }


        this.setState({ backdropLabel: gsr });

        this.eda.push([ts, gsr])
        this.acc.push([ts, acc])

        let sl, sr;

        [sl, sr] = this.calc_score.calc_one(ts, gsr);

        this.calc_stats.calc_one(ts, sl, sr);

        let s = Math.min(sl, sr); // this is min! unlike in Wave2Phase, where it is max, (much easier)

        if (this.test) {
            s = this.simrelax;

            //console.log("simrelax", this.simrelax);
        }


        this.scores.push([ts, sl, sr]);

        // let relax = 0;
        // if (this.calc_stats.relaxpts) relax = this.calc_stats.relaxpts;

        // let amp = (50 - relax) * 2;
        // if (amp <= 0) {
        //     this.ended();
        // }


        if (s > 150) s = 150;

        if (s <= 0) {
            s = 0;
        }

        this.setState({ amplitude: s });

        console.log("amp", s);

        this.addData(this.myChart, '', gsr); 

        this.lastts = ts;

    }


    rgba(c) {
        return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${c[3]})`
    }

    siminc() {
        this.simrelax += 5;
    }

    simdec() {
        this.simrelax -= 5;
        if (this.simrelax < 0) this.simrelax = 0;
    }

    handleTextChange(event) {
        this.setState({ textAreaValue: event.target.value });

    }

    async feedback() {
        await this.save();
        this.setState({ askFeedback: false });
    }

    onPressed(e) {
        e.preventDefault();
        let now = Date.now();
        this.events.push([now, "" + this.events.length]);

        console.log(e, this.events);

    }

    render() {

        if (this.state.saving) {
            return (
                <div>
                    <p>Saving...</p>
                    <CircularProgress />
                </div>
            );
        }

        if (this.state.askFeedback) {
            return (
                <Box maxWidth='90%' margin={3} sx={{ flexDirection: 'column' }}>

                    <br></br>

                    <Typography>
                        If you wish, you can enter your thoughts here:
                    </Typography>

                    <br></br>

                    <TextareaAutosize
                        aria-label="minimum height"
                        minRows={5}

                        //placeholder="If you wish, you can enter your thoughts here..."
                        value={this.state.textAreaValue}
                        onChange={this.handleTextChange.bind(this)}
                        style={{ width: '90%' }}
                    />

                    <br></br>

                    <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.feedback.bind(this)}>Continue</Button>

                </Box>
            );
        }

        if (this.state.showResult) {

            //let final_score = this.calc_score.calc_final_score(this.scores);


            //let total = Math.floor(this.state.final_score.total);

            //let rank = this.calcRank(this.state.hist, total);

            //console.log("Show RESULT", this.state.sessionData);


            return (
                <div>
                    <p>
                        Congratulations! You gained experience in controlling your thoughts and emotions,
                        and collected some relax points as well!
                    </p>

                    <Box maxWidth='90%' margin={3}>

                        <WaveResultCard session={this.ses}></WaveResultCard>

                        <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.finished.bind(this)}>SAVE SESSION</Button>

                    </Box>
                </div>
            );
        }



        // const style = {
        //     margin: 0,
        //     top: 'auto',
        //     right: 20,
        //     bottom: 20,
        //     left: 'auto',
        //     position: 'fixed',
        // };

        return (


            <div>

                <Fab variant="contained" color="primary" >
                    <CheckCircleIcon onClick={this.ended.bind(this)} />
                </Fab>

                <br></br>
                <br></br>
                <Button variant="contained" onClick={this.onPressed.bind(this)}>Add marker ({this.events.length})</Button>


                <script src="https://cdn.jsdelivr.net/npm/chart.js"></script>
                <div style={{height: "300px", width: "100%" }}>
                    <canvas id="myChart"></canvas>
                </div>
            </div>


        );

    };
}

export default RawEda;