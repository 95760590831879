import Typography from '@mui/material/Typography';

export const instruction_kids_ctw_data = {
    kids_ctw_basic: {
        en: (
            <div>
                <Typography variant="h6" align='justify' color="text.primary">
                    Hullámszelidítés alapfokon
                </Typography>

                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Nyugizz és figyeld, hogy mi zajlik benned!
                    <br></br>
                    <br></br>
                    A gyakorlat maximum 3 percig tart, de Te bármikor megszakíthatod a képernyő tetején lévő gomb megnyomásával.
                    <br></br>
                    <br></br>
                </Typography>

                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                        src='media/features/csiko.png'
                        style={{ width: "200px", objectFit: "cover" }}
                    ></img>
                </div>
            </div>
        ),
        hu: (
            <div>
                <Typography variant="h6" align='justify' color="text.primary">
                    Hullámszelidítés alapfokon
                </Typography>

                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Nyugizz és figyeld, hogy mi zajlik benned!
                    <br></br>
                    <br></br>
                    A gyakorlat maximum 3 percig tart, de Te bármikor megszakíthatod a képernyő tetején lévő gomb megnyomásával.
                    <br></br>
                    <br></br>
                </Typography>

                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                        src='media/features/csiko.png'
                        style={{ width: "200px", objectFit: "cover" }}
                    ></img>
                </div>
            </div>
        ),
    },



    kids_ctw_flower: {

        en: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    Flower growing...
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">

                </Typography>
            </div>
        ),
    },

    kids_ctw_updown: {
        en: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    Max 5 perc!
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">

                </Typography>
            </div>
        ),
    },

    kids_ctw_tree: {

        hu: (
            <div>
                <Typography variant="h6" align='justify' color="text.primary">
                    Hullámszelídítés középfokon
                </Typography>

                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Ez a gyakorlat egy kicsit nagyobb kihívás, mint az, amit már alapfokon már elsajátítottál. Az aktuális
                    lelkiállapotodat itt már nem egy, hanem két hullámmal jellemeztük, melyek az izgalmi szint két
                    fontos összetevőjét jelenítik meg. Az egyik az általános stressz szint lassú változásait, míg a másik az
                    apró érzelmi kilengéseket jelzi vissza. Ezeket külső ingerek, de akár a saját gondolataid is
                    előidézhetik.
                    <br></br>
                    <br></br>
                    Próbáld meg mindkét hullámot lecsendesíteni!
                    <br></br>
                    <br></br>
                    Ha sikerül megszelídíteni a hullámokat, egy kis fácska kezd majd el kinőni a földből, szépen lassan.
                    Próbáld meg szinten tartani a már nyugodt érzelmi hullámaidat, és nézd, ahogy a kis facsemetéd
                    leveleket hajt és gyümölcsöt hoz.
                    <br></br>
                    <br></br>
                    Láthatod majd, hogy mindig máshogy sikerül majd úrrá lenni az érzelmeiden. Éppen ezért lesz
                    minden fa más és más.
                    <br></br>
                    <br></br>
                    Mivel ez egy nehéz feladat, így várhatóan több napig is kell majd gyakorolnod, hogy igazán jól
                    menjen.
                    <br></br>
                    <br></br>
                    <b>A fák, amiket növesztesz, fáradságos munkádat szimbolizálják a rögös úton, melyen végig mész.</b>
                    <br></br>
                    <br></br>
                    Mivel ez a gyakorlat sokkal összetetteb, mint az előző, itt már három különböző jutalom pontot is
                    tudsz majd gyűjteni. A <b>relax pontokon</b> kívül ún. <b>kontroll (control) pontokat</b> és <b>egyensúlyi (balance)
                        pontokat</b> is gyűjthetsz:
                    <br></br>
                    <br></br>
                    <lu>
                        <li><b>Relax pontokat</b> az egész gyakorlat alatt kaphatsz akkor, amikor lefelé viszed a stressz szintedet.</li>
                        <li>A <b>control pontok</b> azt mutatják meg, hogy milyen gyorsan tudod levinni az érzelmi hullámaidat.</li>
                        <li>A <b>balance pontokat</b> pedig azért kapod, ha sikeres vagy abban, hogy szelíden tartsd a hullámokat,
                            miután sikeresen lecsendesítetted őket.</li>
                    </lu>
                    <br></br>
                    A gyakorlatot bármikor megszakíthatod. Javasolt időtartam 5 perc.
                    <br></br>
                    <br></br>
                </Typography>
            </div>
        ),
    },

    kids_ctw_sounds: {

        en: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    Stay focused!
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">
                    Remember when you finally felt relaxed, and then, a sudden noise
                    simply knocked you out of this wonderful state of mind? This
                    exercise will help you to
                    maintain your relaxed state, despite external
                    sounds.
                    <br></br>
                    <br></br>
                    The first step along this way is to eliminate effects of external
                    disturbances instantly as they try to interfere with your focus. Most
                    people react with a temporary arousal to any external sound. Arousal
                    is your mind's way to prepare for you for action. But since you hold
                    the control for your own emotional state, it is you, who can easily put
                    your focus back on track and restrain those waves!
                    <br />
                    <br />
                    <b>Observe how it feels when you take control.</b>
                    <br />
                    <br />
                    You can stop the exercise at any time. Recommended duration 5 minutes.
                </Typography>
            </div>
        ),
        hu: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    Összpontosíts!
                </Typography>
                <br></br>

                <Typography variant="h6" align='justify' color="text.primary">
                    Hullám-szelídítés hanghatások mellett (középfok)
                </Typography>

                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Emlékszel arra, amikor végre teljesen ellazultál, majd egy váratlan hanginger kibillentett ebből a
                    csodálatos lelkiállapotból? Ez a gyakorlat abban segít neked, hogy meg tudd tartani a nyugalmat
                    akkor is, amikor külső ingerek érnek.
                    <br></br>
                    <br></br>
                    Az első lépés ezen az úton az, hogy kiszűrd a külvilágból érkező zajokat azonnal, amikor azok
                    megzavarnának a koncentrálásban. A legtöbb ember izgalmi szintjét egy pillanatra megemelik a
                    külső hanghatások. Azonban mivel az érzelmi állapotod kulcsa nálad van, te vagy az, aki vissza tudod
                    terelni kisimult medrükbe a rakoncátlankodó hullámokat, amikor csak akarod.
                    <br></br>
                    <br></br>
                    <b>Figyeld meg milyen érzés amikor te irányítasz!</b>
                    <br></br>
                    <br></br>
                    A gyakorlatot bármikor megszakíthatod. Javasolt időtartam 5 perc.
                </Typography>
            </div>
        )
    },


    kids_ctw_tetris: {

        en: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    Emotion-management under cognitive load
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">
                    Now that you have mastered emotional management under various
                    circumstances, you are ready to try active engagement in a task
                    while controlling your arousal level. In Tetris you are expected to
                    focus on turning and placing geometrical shapes for best fit. But can
                    you manage your level of arousal while engaging in this cognitively
                    demanding task? Moreover, will you be able to master both, when
                    the “stakes get high”?
                    <br />
                    <br />
                    <b>Push yourself to the limit!</b>
                    <br />
                    <br />
                </Typography>
            </div>
        ),
        hu: (
            <div>
                <Typography variant="h5" align='center' color="text.secondary">
                    Érzelmek irányítása kognitív terhelés mellett
                </Typography>
                <br></br>
                <Typography variant="body1" align='justify' color="text.primary">
                    Már többféle helyzetben is bizonyítottad, hogy képes vagy érzelmeid irányítására. Kész vagy tehát
                    arra, hogy megpróbáld az izgalmi állapotodat nyugodt szinten megtartani, miközben egy elmét
                    próbáló feladatot teljesítesz. A Tetris játékban a geometriai formákat forgatva kell azokat a
                    legmegfelelőbb helyre beilleszteni. Képes vagy erre, miközben az érzelmeidet is kordában tartod?
                    Mi több: akkor is képes leszel nyugodt maradni, amikor már nagyobb a tét?
                    <br />
                    <br />
                    <b>Próbálgasd a határaidat!</b>
                    <br />
                </Typography>
            </div>
        ),
    },

}