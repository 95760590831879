import React from 'react';

const saplingSize = 30;
const fruitingSize = 80;


export default class Tree extends React.Component {

  constructor(props) {
    super(props);

    this.canvas = React.createRef()
    this.offcanvas = React.createRef()


    if (props.treeSize) this.treeSize = props.treeSize;
    else this.treeSize = 0;

    console.log("Fractal constr", props.treeSize);

    this.startHash = Math.floor(Math.random() * 1000000);
    this.rand = this.LCG(this.startHash);
    this.rand(); // drop first

    this.fruitSize = Math.floor(Math.random() * 5) + 3;
    this.fruitPercentage = Math.floor(Math.random() * 60) + 10; // between 10 and 80
    this.maxLeafSize = Math.floor(Math.random() * 20) + 10; // between 10 and 40

    this.rotateSize = (Math.random() * 6) + 15; // between 4 and 10
    this.rotateVar = (Math.random() * 6) + 4;  // between 4 and 10

    this.lenAvgDec = 0.85;
    this.lenVarDec = 0; // -0.1 to 0.1

    this.fruitColor = Math.floor(Math.random() * 360);
    this.fruitColorVar = Math.floor(Math.random() * 30);

    let lc = Math.floor(Math.random() * 40) + 100;
    this.leafColor = "hsl(" + lc + ", 100%, 25%)";
    //this.leafColor = 'green';

    let bc = Math.floor(Math.random() * 50) + 50;
    this.branchColor = "hsl(28, " + bc + "% , 25%)";

    // window.addEventListener('resize', this.handleResize.bind(this));
    // this.handleResize();


    this.state = {
    }
  }

  handleResize() {
    this.setState({ update: 1 });
  }

  saveImage() {
    console.log("SAVE");

    // this.width = this.canvas.current.width = 1024;
    // this.height = this.canvas.current.height = 768;
    // this.startDrawTree();

    var dataURL = this.offcanvas.current.toDataURL('image/webp', 0.5);
    console.log("Tree image size:", dataURL);
    //let image = new Image();
    //image.src = canvas.toDataURL();

    let treeCat = "seedling";
    if (this.treeSize > saplingSize / 2) treeCat = "sapling";
    if (this.treeSize > saplingSize) treeCat = "young";
    if (this.treeSize > fruitingSize) treeCat = "fruiting";

    return {
      type: "tree",
      data: {
        cat: treeCat,
        size: Math.floor(this.treeSize),
      },
      img: dataURL
    };
  }


  componentDidUpdate() {
    this.treeSize = this.props.treeSize;

    if (this.props.hash) {
      this.startHash = this.props.hash;
      this.rand = this.LCG(this.startHash);
      this.rand();
    }

    //this.treeSize=100;

    //console.log("treeSize", this.treeSize);
  }

  componentWillUnmount() {
    console.log("Tree unmounted!");
    //if (this.animation) cancelAnimationFrame(this.animation);


    this.running = false;
  }

  componentDidMount() {
    console.log("Tree2");

    let offcanvas = this.offcanvas.current;
    let canvas = this.canvas.current;

    // update canvas size
    this.width = canvas.width  = offcanvas.width = 500;
    this.height = canvas.height  = offcanvas.height = 720;

    let scale = window.innerHeight / 720;
    offcanvas.style.height = canvas.style.height = Math.floor(canvas.height * scale) + "px";
    offcanvas.style.width = canvas.style.width = Math.floor(canvas.width * scale) + "px";


    this.offscreen = offcanvas.transferControlToOffscreen();
    this.worker = new Worker("./workers/tree_worker.js");
    this.worker.postMessage({ canvas: this.offscreen}, [this.offscreen]); // first send needed


    console.log("FRACTAL", this.width, this.height);

    this.ctx = canvas.getContext('2d') //// 3 - access node using .current

    let ctx = this.ctx;


    //this.loop();


    //   ctx.beginPath();
    //   ctx.arc(0, 0, 100, 0, 2 * 3.1415);
    //   ctx.stroke();

    this.w = 1;

    this.lastt = new Date();

    this.running = true;
    this.loop();

  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  LCG(s) {
    return function () {
      s = Math.imul(48271, s) | 0 % 2147483647;
      //return (s & 2147483647) / 2147483648;
      return (s & 2147483647);
    }
  }


  loop() {

    if (!this.running) return;

    let canvas = this.canvas.current;
    let offcanvas = this.offcanvas.current;

    // let scale = window.innerHeight / 720;
    // offcanvas.style.height = canvas.style.height = Math.floor(canvas.height * scale) + "px";
    // offcanvas.style.width = canvas.style.width = Math.floor(canvas.width * scale) + "px";

    
    // canvas.style.height = Math.floor(canvas.height * scale) + "px";
    // canvas.style.width = Math.floor(canvas.width * scale) + "px";

    //const offcanvas = new OffscreenCanvas(canvas.width, canvas.height);
    //const offctx = offcanvas.getContext('2d');
    //var worker = new Worker("./workers/tree_worker.js");
    //this.worker.postMessage({ canvas: this.offscreen, stylewidth: canvas.style.width, styleheight: canvas.style.height });

    //var bitmapOne = offcanvas.transferToImageBitmap();
    //canvas.transferFromImageBitmap(bitmapOne);

    this.worker.postMessage({treeSize: this.treeSize, stylewidth: canvas.style.width, styleheight: canvas.style.height}); // first send needed


    //this.startDrawTree();
    if (this.running) setTimeout(this.loop.bind(this), 100);
  }



  render() {
    //console.log("render");

    // let canvas = this.canvas.current;

    // let offset = 0;
    // if(canvas) {
    //   offset = - canvas.style.width /2 + window.innerWidth /2;
    //   console.log("offset", canvas.style.width, window.innerWidth, offset);
    // }

    return (
      // <Paper sx={{ width: 1, height: 1 }} margin='auto' alignItems="center">

      <div>
        <div style={{
          position: "fixed",
          zIndex: -2, width: "100%", top: 0, left: 0
        }}>
          <div style={{
            marginLeft: "auto", marginRight: "auto"
          }}>
            <canvas ref={this.canvas}>
              <p>Add suitable fallback here.</p>
            </canvas>
          </div>
        </div>

        <div style={{
          position: "fixed",
          zIndex: -1, width: "100%", top: 0, left: 0
        }}>
          <div style={{
            marginLeft: "auto", marginRight: "auto"
          }}>
            <canvas ref={this.offcanvas}>
              <p>Add suitable fallback here.</p>
            </canvas>
          </div>
        </div>

      </div>
      // </Paper>
    );

  }
}
