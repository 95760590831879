import React, { Component } from 'react';

import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, CategoryScale } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

// import { defaults } from 'react-chartjs-2';
// import 'chartjs-plugin-annotation';
import annotationPlugin from 'chartjs-plugin-annotation';

// import DataTable from 'react-data-table-component';
// import { data } from 'jquery';

import { CalcScore } from './scripts/calc_score.js';
import { CalcSessionStats } from './scripts/calc_sessionstats.js';

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale, zoomPlugin);



export default class ResultChart extends Component {

  constructor(props) {
    super(props);

    ChartJS.register(annotationPlugin);

    this.state = {
      drawData: false,
      drawOptions: false
    }

    this.annotations = [];

    //console.log("Wave const");

  }

  componentDidUpdate(prevProps) {
    console.log("componentDidUpdate");
    if (this.props === prevProps) {
      return;
    }

    if(this.props.data.ts === prevProps.data.ts) return;

    console.log("props changed", this.props.data, prevProps.data);  
    this.processRecord();

  }

  componentDidMount() {
    this.processRecord();
  }


  render() {

    if (!this.state.drawData) {
      return (
        <div>
          <p> No data</p>

        </div>
      );
    }

    //console.log("Record Id:", this.state.recordId);

    return (
      <Line
        ref="chart"
        data={this.state.drawData}
        options={this.state.drawOptions}
      // plugins={[ChartAnn]}
      />

    );

  }

  processRecord() {

    //if(!(data in this.props)) return; 

    let c = [];
    let acc = [];

    let row = this.props.data;

    //console.log("Record: " + JSON.stringify(row._id));

    this.setState({ recordId: row._id });

    // console.log("data: " + JSON.stringify(row.data));

    let t0 = 0;

    if (!row.data) return;
    if (!row.data.eda) return;
    if (row.data.eda.length === 0) return;

    if (row.data.start) {
      t0 = row.data.start;
    }
    else t0 = row.data.eda[0][0];

    //console.log("t0="+t0);

    if (!row.data.eda) return;

    var mineda = 1000000;


    let i = 0;
    for (let eda of row.data.eda) {
      //if (t0 === 0) t0 = eda[0];

      //x.push( ((eda[0]-t0)/1000.0).toFixed(3) );
      //y.push(eda[1]);

      let x = (eda[0] - t0) / 1000.0;
      // corrected ts
      // x = (i * 250)/1000;

      const y = eda[1];

      if (y < mineda) {
        mineda = y
      }


      c.push({ x: x, y: y })

      if (row.data.acc) {
        acc.push({ x: x, y: row.data.acc[i][1] })
      }

      i += 1;
    }

    // console.log("ACC", acc);

    // if fineacc exists, overwrite rough acc plot
    if (row.data.fineacc) {
      if (row.data.fineacc.length > 0) {
        // console.log("FINEACC", row.data.fineacc);
        acc = [];

        let t0_fineacc = row.data.fineacc[0][0];
        let eda_acc_diff = t0_fineacc - t0;
        console.log("XXX EDA ACC DIFF", eda_acc_diff);

        let ii = 0;
        for (let [ts, a] of row.data.fineacc) {

          let x = (ts - t0) / 1000.0;
          // corrected
          // x = (ii * 250.0 + eda_acc_diff) / 1000.0;


          // console.log("XX-", x, a);

          for (let i in a) {
            let offset = 0.250 * (15 - i) / 16.0;
            // console.log("XX offset ",offset, i);

            acc.push({ x: x - offset, y: a[i] })
          }

          ii++;
        }


        //console.log("XXX", acc);
      }
    }


    let d = {
      datasets: [
        {
          label: 'eda',
          fill: false,
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,

          borderWidth: 2,

          yAxisID: 'EDA',
          data: c

        }
      ]
    };

    if (acc.length > 0) {
      d.datasets.push({
        label: 'acceleration',
        fill: false,
        borderColor: 'rgba(250,1,1,1)',
        borderWidth: 1,
        // borderCapStyle: 'butt',
        // pointBorderWidth: 0.1,
        // pointHoverRadius: 5,
        // pointHoverBorderWidth: 2,
        // pointRadius: 1,
        // pointHitRadius: 10,

        yAxisID: 'ACC',
        data: acc
      });
    }

    let options = {
      maintainAspectRatio: false,
      responsive: true,
      animation: false,

    };

    //options.hover = {
    //  mode: 'new mode'
    //}

    options.hover = {
      mode: 'x'
    }


    // options.scales = {

    //   yAxes: [
    //     {
    //       id: 'EDA',
    //       type: 'linear',
    //       ticks: {
    //         min: mineda,
    //         beginAtZero: false,
    //         //fontColor: 'white'
    //       },
    //     }
    //   ],
    //   xAxes: [{
    //     type: 'linear',
    //     ticks: {
    //       //fontColor: 'white',

    //     }
    //   }],
    // }

    options.scales = {

      'EDA': {
        type: 'linear',
        ticks: {
          min: mineda,
          beginAtZero: false,
          //fontColor: 'white'
        },
      },
      x: {
        type: 'linear',
        ticks: {
          //fontColor: 'white',

        }
      },
    }

    if (acc.length > 0) {
      options.scales['ACC'] =
      {
        type: 'linear',
        ticks: {
          beginAtZero: false,
          fontColor: 'red'
        },
        max: 300
      }
    }


    if (row.type === "WavesAdaptive") {
      let c_scr_score = [];
      let c_scl_score = [];

      row.data.scores.forEach(e => {
        const x = (e[0] - t0) / 1000.0;

        console.log("SCORE: " + e[0]);

        c_scl_score.push({ x: x, y: e[1] })
        c_scr_score.push({ x: x, y: e[2] })


      });

      d.datasets.push(
        {
          label: 'scl_score',
          fill: false,
          borderColor: '#B289EF',
          pointRadius: 1,

          yAxisID: 'SCORE',
          data: c_scl_score

        },
        {
          label: 'scr_score',
          fill: false,
          borderColor: 'rgba(150, 97, 255, 1)',
          pointRadius: 1,

          yAxisID: 'SCORE',
          data: c_scr_score

        }
      )

      // Alternate calc
      let scores_l = [];
      let scores_r = [];

      let p = {
        l_incr: 2,
        l_decr: 1,
        r_incr: 2,
        r_decr: 1
      }

      let c = new CalcScore(p);

      [scores_l, scores_r] = c.calc_all(row.data.eda);


      d.datasets.push(
        {
          label: 'scl_score_alt',
          fill: false,
          borderColor: 'red',
          pointRadius: 1,

          yAxisID: 'SCORE',
          data: scores_l

        },
        {
          label: 'scr_score_alt',
          fill: false,
          borderColor: 'darkred',
          pointRadius: 1,

          yAxisID: 'SCORE',
          data: scores_r

        }
      )

      options.scales['SCORE'] =
      {
        id: 'SCORE',
        type: 'linear',
        ticks: {
          beginAtZero: false,
          //fontColor: 'white'
        },
      }

    }

    if (row.type === "VideoEda" || row.type === "Waves" || row.type === "Waves2Phase" || row.type === "WaveBasic" || row.type === "Tetris" || row.type === "WaveReinforce" || row.type === "WaveUpdown") {
      let c_scr_score = [];
      let c_scl_score = [];

      let scores = [];

      row.data.scores.forEach(e => {
        const x = (e[0] - t0) / 1000.0;

        //console.log("SCORE: "+e[0]);

        c_scl_score.push({ x: x, y: e[1] })
        c_scr_score.push({ x: x, y: e[2] })

      });

      //this.setState({ scores: [c_scl_score, c_scr_score]})
      this.setState({ scores: row.data.scores });

      let calcSessionStats = new CalcSessionStats();
      let sessionStats = calcSessionStats.calc_stats(row);

      this.setState({ text: JSON.stringify(sessionStats, null, 2) });

      if (sessionStats.t_decr) this.addAnnotation("t_decr", "green", sessionStats.t_decr / 1000.0);

      d.datasets.push(
        {
          label: 'scl_score',
          fill: false,
          borderColor: '#B289EF',
          pointRadius: 1,

          yAxisID: 'SCORE',
          data: c_scl_score

        },
        {
          label: 'scr_score',
          fill: false,
          borderColor: 'rgba(150, 97, 255, 1)',
          pointRadius: 1,

          yAxisID: 'SCORE',
          data: c_scr_score

        }
      )

      // options.scales.yAxes.push(             
      //   {
      //     id: 'SCORE',
      //     type: 'linear',
      //     ticks: {
      //       beginAtZero: false,
      //       //fontColor: 'white'
      //     },
      //   }, 

      // )

      options.scales['SCORE'] =
      {
        type: 'linear',
        ticks: {
          beginAtZero: false,
          //fontColor: 'white'
        },
      }

    }

    if (row.data.events) {
      console.log("HAS EVENTS", row.data.events);
      for (let event of row.data.events) {
        this.addAnnotation(event[1], "red", (event[0] - t0) / 1000.0);
      }

    }

    if (row.type === "SyntheticAudio") {
      row.section.sequence.forEach(e => {
        if (e[0] >= 0) {

          const sound = row.section.sounds[e[0]];
          var s = sound.substring(sound.lastIndexOf("/") + 1);



          console.log("Sound:" + s + " " + e[0]);
          this.addAnnotation(s, "red", (e[1]) / 1000.0);
        }
      });

    }

    if (row.type === "Questions") {

      let i = 0;
      row.section.questions.forEach(e => {
        let text = e[0];

        let showTs = row.data.answers[i].showTs;

        this.addAnnotation(text, "red", (showTs - t0) / 1000.0);

        i++;
      });

    }


    if (row.type === "TetrisTest") {
      row.data.speedChanges.forEach(e => {
        this.addAnnotation("speed", "green", (e - t0) / 1000.0);
      });

      row.data.skips.forEach(e => {
        this.addAnnotation("skip", "red", (e - t0) / 1000.0);
      });

    }

    options.plugins = {
      annotation: {
        annotations: this.annotations
      },

      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: false
          },
          drag: {
            enabled: false
          },

          mode: 'x',
        }
      },

      legend: {
        display: true,
        position: 'bottom'
      }

    }

    console.log("drawData:", d);
    console.log("drawOptions:", options);

    this.setState(
      {
        drawData: d,
        drawOptions: options
      });
  }

  // addAnnotation(label, color, value) {
  //   this.annotations.push(
  //     {
  //       drawTime: "afterDatasetsDraw",
  //       type: "line",
  //       mode: "vertical",
  //       scaleID: "x-axis-0",
  //       value: value,
  //       borderWidth: 1,
  //       borderColor: color,
  //       label: {
  //         content: label,
  //         enabled: true,
  //         position: "top",
  //         rotation: '0',
  //         yAdjust: this.annotations.length*20

  //       }
  //     }
  //   );
  // }

  addAnnotation(label, color, value) {
    this.annotations.push(
      {
        drawTime: "afterDatasetsDraw",
        type: "line",
        xMin: value,
        xMax: value,
        borderWidth: 1,
        borderColor: color,
        label: {
          display: true,
          content: label,
          position: "top",
          rotation: '0',
          // yAdjust: this.annotations.length*20

        }
      }
    );
  }


  addAnnotationHorizontal(label, yscale, color, value) {
    this.annotations.push(
      {
        // drawTime: "afterDatasetsDraw",
        type: "line",
        mode: "horizontal",
        // scaleID: yscale,
        value: value,
        // borderWidth: 1,
        // borderColor: color,
        // label: {
        //   content: label,
        //   enabled: true,
        //   position: "top",
        //   rotation: '0',
        //   yAdjust: this.annotations.length*20

        // }
        yMin: 60,
        yMax: 60,
        borderColor: 'rgb(255, 99, 132)',
        borderWidth: 2,
      }
    );

  }
}