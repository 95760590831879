import React from 'react'
import Wave from 'react-wavify'
import wt from 'discrete-wavelets';
import { Howl } from 'howler'
import { CalcScore } from '../scripts/calc_score';
import { CalcSessionStats } from '../scripts/calc_sessionstats';

import Backdrop from '@mui/material/Backdrop';
// import BackdropUnstyled from '@mui/base/BackdropUnstyled';
import Fab from '@mui/material/Fab';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CircularProgress, Typography } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import { ThirtyFpsSelectOutlined } from '@mui/icons-material';
import WaveResultPlot from '../WaveResultPlot';
import WaveResultCard from '../WaveResultCard';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import ScoreBox from '../ScoreBox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CardContent } from '@mui/material';

import { calcParamsFromDifficulty } from '../scripts/calc_difficulty';

import Tree from '../pages/Tree';
import Flower from '../pages/Flower';

// import { session } from 'passport';
import { MyContext } from '../MyContext';

class Racing extends React.Component {
    static contextType = MyContext;

    constructor(props, context) {
        super(props);

        this.canvas = React.createRef()

        this.objRef = React.createRef();

        this.col1 = [178, 137, 239, 0.7];
        this.col2 = [150, 97, 255, 0.5];

        this.color1 = this.rgba(this.col1);
        this.color2 = this.rgba(this.col2);

        this.objSize = 0;

        this.disturbData = [];


        let params = props.params;

        console.log("Waves params: ", params);
        console.log("Waves context: ", context);

        let difficulty_params = {};

        if (params.forced_difficulty_params) {
            // forced difficulty
            difficulty_params = params.forced_difficulty_params;
            console.log("Forced difficulty: ", difficulty_params);

        } else {
            console.log("Difficulty scale: ", context.difficulty);
            difficulty_params = calcParamsFromDifficulty(context.difficulty);
        }

        this.difficulty_params = difficulty_params;



        this.players = []
        this.fired = []
        this.decoded_images = []

        // if (process.env.NODE_ENV === 'development') this.test = true;
        // this.simscore = 100;

        this.success = false;

        this.decr_ended_ts = false;

        this.calc_score = new CalcScore(difficulty_params);
        this.calc_stats = new CalcSessionStats();

        this.eda = [];

        this.scores = [];
        this.minscore = 100;

        this.sclResult = 0;
        this.scrResult = 0;

        this.n = 0;


        this.t = 10;
        this.scl = 0;
        this.scr = 0;
        this.start = Date.now();
        this.end = 0;
        this.nEndPos = 0;

        this.state = {
            sclScore: 100,
            scrScore: 100,
            showResult: false,
            askFeedback: false,
            textAreaValue: "",
            bImg: params.backgroundImage,
            rt_progress: {},
            endPos: {},
        }

        this.smooth_progress = {};

        if (params.duration) {
            const dur = params.duration;
            console.log("Racing duration: " + dur);

            this.timeout = setTimeout(() => {
                this.ended();
            }, dur);
        }

    }

    async componentDidMount() {

        console.log("mounted");
        if (this.bgPlayer) this.bgPlayer.play();

        let imglist = ["/media/cbtbasic/snails/a1.png",
            "/media/cbtbasic/snails/a2.png",
            "/media/cbtbasic/snails/c3.png",
            "/media/cbtbasic/snails/c4.png",
            "/media/cbtbasic/snails/c5.png",
            "/media/cbtbasic/snails/c6.png",
            "/media/cbtbasic/snails/c7.png",
            "/media/cbtbasic/snails/c9.png",
            "/media/cbtbasic/snails/c10.png",
            "/media/cbtbasic/snails/c11.png",
        ];

        for (let i = 0; i < imglist.length; i++) {

            let img = new Image();
            img.src = imglist[i];
            await img.decode();

            this.decoded_images.push(img);
        }

        // call drawSubl every 100ms
        this.interval = setTimeout(this.drawSubl.bind(this), 50);
        this.rt_timer = setTimeout(this.poll_rt_progress.bind(this), 500);


    }

    mutePlayers() {
        if (this.bgPlayer) this.bgPlayer.stop();

        for (let i = 0; i < this.players.length; i++) {
            this.players[i].stop();
        }

    }

    componentWillUnmount() {
        console.log("Waves2Phase componentWillUnmount()");

        this.mutePlayers();

        if (this.rt_timer) clearTimeout(this.rt_timer);
        if (this.interval) clearInterval(this.interval);

    }

    finished() {
        console.log("Waves2Phase finish()");
        // on finish, we do not send data, because we already called store_cb in "ended"
        this.props.finished_cb({}, false);


    }

    async save() {
        this.setState({ saving: true });


        let sessionData = {
            eda: this.eda,
            scores: this.scores,
            feedback: this.state.textAreaValue,
            difficulty_params: this.difficulty_params,
            success: this.success
        };

        console.log("Racing sessionData", sessionData);

        if (this.objData) {
            let creatureId = await this.props.storeCreature_cb(this.objData.type, this.objData.data, this.objData.img);
            console.log("**************************** Creature ID:", creatureId);

            sessionData["creature"] = {
                type: this.objData.type,
                data: this.objData.data,
                creatureId: creatureId
            }
        }

        if (this.props.params.disturb) sessionData.disturbData = this.disturbData;

        let sessionId = await this.props.store_cb(sessionData);

        console.log("**************************** Session ID:", sessionId);

        //Now read back
        // await fetch

        this.setState({ showResult: true, askFeedback: false, sessionData: sessionData, saving: false });

    }

    // call a web service periodically to store the current score and also fetch the scores of the other users
    async poll_rt_progress() {
        if (this.state.ended) return;

        // console.log("poll_rt_progress", Date.now());

        let prog = 100 - this.minscore;

        // send the current score to the server
        let resp = await fetch('/api/rt_progress', {
            method: 'POST',
            body: JSON.stringify({ user: this.context.user, progress: prog }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });


        if (resp.status === 200) {

            let data = await resp.json();
            // console.log("rt_progress data", data);

            this.setState({ rt_progress: data });

            // cycle through the racers and check if any of them has reached the end (progress == 100)
            // then update an object with the final position 1 to n
            let nRace = Object.keys(data).length;
            
            for (let racer in data) {
                if (data[racer].progress == 100 && this.state.endPos[racer] == undefined) {
                    this.nEndPos++;
                    this.state.endPos[racer] = this.nEndPos;
                }
            }
            this.setState({ endPos: this.state.endPos });

        } else {
            console.log("rt_progress error", resp.status);
        }

        // call the function again after 0.5 seconds
        this.rt_timer = setTimeout(this.poll_rt_progress.bind(this), 500);


    }



    async ended() {
        if (this.endedCalled) return;
        this.endedCalled = true;

        this.mutePlayers();
        clearTimeout(this.timeout);

        if (this.rt_timer) clearTimeout(this.rt_timer);
        if (this.interval) clearInterval(this.interval);

        console.log("ended");

        if (this.objRef.current) {
            console.log("objData filled");
            this.objData = this.objRef.current.saveImage();
        }

        this.setState({ askFeedback: true, ended: true });
        console.log("Ended");


    }

    drawSubl() {
        // console.log("drawSubl");

        if (this.state.ended) return;

        if (this.decoded_images.length == 0) {
            console.log("No image");
            return;
        }

        let canvas = this.canvas.current;
        if (!canvas) {
            console.log("No canvas");
            return;
        }

        if (!this.xx) this.xx = 0;
        this.xx += 0.01;

        if (this.xx > 1) this.xx = 0;

        // this.xx = 1;

        // update canvas size
        this.width = canvas.width = window.innerWidth;
        this.height = canvas.height = window.innerHeight;
        this.ctx = canvas.getContext('2d')

        this.ctx.clearRect(0, 0, canvas.width, canvas.height);

        // console.log("canvas width, height", this.width, this.height);

        // nRace is the number of racers in rt_progress object
        let nRace = Object.keys(this.state.rt_progress).length;

        // for each racers
        let i = 0;
        for (let racer in this.state.rt_progress) {

            let progress = this.state.rt_progress[racer].progress / 100.0;

            if (this.smooth_progress[racer] == undefined) {
                this.smooth_progress[racer] = progress;
            } else {
                // console.log("racer, progress", racer, progress);
                let diff = progress - this.smooth_progress[racer];
                // console.log("diff", diff);

                this.smooth_progress[racer] += diff / 10;
                progress = this.smooth_progress[racer];

                // console.log("racer", racer, "progress", progress);
            }


            let img = this.decoded_images[i % this.decoded_images.length];
            // console.log("img", img.width, img.height);

            let scaler = nRace + 2;
            if (scaler < 5) scaler = 5;

            let img_ysize_canvas = (this.height - 100) / (scaler); // x% of the screen height
            let img_scale = img_ysize_canvas / img.height; // scale the image to the screen height
            let img_xsize_canvas = img.width * img_scale;

            // console.log("img_scale", img_scale);
            // console.log("img_xsize_canvas", img_xsize_canvas);
            // console.log("img_ysize_canvas", img_ysize_canvas);

            let tx = progress * (this.width - img_ysize_canvas * 2);
            let ty = this.height - img_ysize_canvas * i - img_ysize_canvas * 2;

            // console.log("racer", racer, "tx, ty", tx, ty);

            // this.ctx.fillStyle = "#FF0000";
            // this.ctx.fillRect(0, 0, this.width, this.height);

            let lineWidth = this.height / 150;

            if (racer == this.context.user) {
                this.ctx.fillStyle = "#FF0000";
            } else {
                this.ctx.fillStyle = "grey";
            }
            this.ctx.fillRect(0, ty + img_ysize_canvas - lineWidth * 2, tx + img_xsize_canvas / 2, lineWidth);

            this.ctx.drawImage(img, 0, 0, img.width, img.height, tx, ty, img_xsize_canvas, img_ysize_canvas);



            // draw the finish line text
            if (this.state.endPos[racer] != undefined) {
                // change text font to have white border
                this.ctx.font = ""+Math.floor(img_ysize_canvas/2)+"px Arial";
                this.ctx.strokeStyle = "black";
                this.ctx.lineWidth = 3;
                this.ctx.strokeText("" + this.state.endPos[racer], tx , ty + img_ysize_canvas );
                this.ctx.fillStyle = "white";
                
                this.ctx.fillText("" + this.state.endPos[racer], tx , ty + img_ysize_canvas );

                
            }

            // // draw the endPos text next to the finish line
            // if (this.state.endPos[racer] != undefined) {
            //     this.ctx.font = "30px Arial";
            //     this.ctx.fillStyle = "black";
            //     this.ctx.fillText("Finished " + this.state.endPos[racer], this.width - 200, this.height - img_ysize_canvas * 2 + 30);
            // }


            i++;
        }



        this.interval = setTimeout(this.drawSubl.bind(this), 50);

    }


    onEda(gsr, acc) {
        if (this.state.ended) {
            return;
        }

        console.log("onEda", gsr, acc);

        let ts = Date.now();

        if (!this.lastts) {
            this.lastts = ts;
            return;
        }

        this.eda.push([ts, gsr])

        let [sl, sr] = this.calc_score.calc_one(ts, gsr);

        if (this.test) {
            sl = this.simscore;
            sr = sl;

            console.log("SIMSCORE", this.simscore);
        }

        this.calc_stats.calc_one(ts, sl, sr);
        this.scores.push([ts, sl, sr]);

        this.setState({ sclScore: sl, scrScore: sr });

        let score = Math.min(sl, sr);
        if (this.difficulty_params.nWave == 2) {
            score = Math.max(sl, sr);
        }

        if (score <= 0) {
            this.success = true;
            score = 0;

            // if (!this.props.params.continueOnZero) {
            //     this.ended();
            // }
        }

        console.log("SCORE", score);

        if (score < this.minscore) this.minscore = score;

        this.lastts = ts;

    }

    lerp = function (a, b, u) {
        return (1 - u) * a + u * b;
    };

    rgba(c) {
        return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${c[3]})`
    }

    siminc() {
        this.simscore += 5;
    }

    simdec() {
        this.simscore -= 5;
        if (this.simscore < 0) this.simscore = 0;
    }

    handleTextChange(event) {
        this.setState({ textAreaValue: event.target.value });

    }

    async feedback() {
        await this.save();
        this.setState({ askFeedback: false });
    }

    render() {

        if (this.state.saving) {
            return (
                <div>
                    <p>Saving...</p>
                    <CircularProgress />
                </div>
            );
        }

        if (this.state.askFeedback) {
            return (
                <Box maxWidth='90%' margin={3} sx={{ flexDirection: 'column' }}>

                    <br></br>

                    <Typography>
                        If you wish, you can enter your thoughts here:
                    </Typography>

                    <br></br>

                    <TextareaAutosize
                        aria-label="minimum height"
                        minRows={5}

                        //placeholder="If you wish, you can enter your thoughts here..."
                        value={this.state.textAreaValue}
                        onChange={this.handleTextChange.bind(this)}
                        style={{ width: '90%' }}
                    />

                    <br></br>

                    <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.feedback.bind(this)}>Continue</Button>

                </Box>
            );
        }

        if (this.state.showResult) {

            //let final_score = this.calc_score.calc_final_score(this.scores);


            //let total = Math.floor(this.state.final_score.total);

            //let rank = this.calcRank(this.state.hist, total);

            //console.log("Show RESULT", this.state.sessionData);

            let ses = {
                exercise: this.props.exercise,
                campaign: this.props.campaign,
                data: this.state.sessionData,
                ts: Date.now(),
                device: this.context.deviceName,
                user: this.context.user
            }


            return (
                <Box maxWidth='90%' margin={3} sx={{ flexDirection: 'column' }}>

                    <WaveResultCard session={ses}></WaveResultCard>

                    <br></br>

                    <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.finished.bind(this)}>SAVE SESSION</Button>

                </Box>
            );
        }

        let showWave1 = true;
        let showWave2 = true;

        let a1 = this.state.sclScore;
        let a2 = this.state.scrScore;

        if (a1 > 100) a1 = 100;
        if (a2 > 100) a2 = 100;

        if (this.difficulty_params.nWave == 1) {
            a1 = Math.min(a1, a2);
            a2 = 0;
            showWave2 = false;
        }

        if (this.props.params.hideWaves === true) {
            a1 = 0;
            a2 = 0;
            showWave1 = false;
            showWave2 = false;
        }


        const style = {
            margin: 0,
            top: 'auto',
            right: 20,
            bottom: 20,
            left: 'auto',
            position: 'fixed',
        };


        return (


            <div>

                {this.state.bImg &&
                    <div style={{ position: 'absolute', left: '0px', top: 0, zIndex: '-10', height: "100%", width: "100%" }}>
                        <img
                            src={this.state.bImg}
                            style={{ height: "100%", width: "100%", objectFit: "cover" }}
                        />
                    </div>

                }

                {this.state.backdropLabel &&
                    <Backdrop
                        sx={{
                            p: 1, color: 'black', zIndex: 0, background: '#fff0',
                            textShadow: 'white 0.1em 0.1em 0.2em, white -0.1em -0.1em 0.2em'
                        }}
                        // open={this.state.backdropLabel}
                        open={true}
                        transitionDuration="5000"
                    >
                        {this.state.backdropLabel}
                    </Backdrop>}


                <div>
                    <div style={{
                        position: 'absolute', left: '0px', top: 0, zIndex: '-2', height: "100%", width: "100%"
                    }}>
                        <div style={{
                            height: "100%", width: "100%", objectFit: "cover"
                        }}>
                            <canvas ref={this.canvas}>
                                <p>Add suitable fallback here.</p>
                            </canvas>
                        </div>
                    </div>
                </div>



                {showWave1 &&
                    <Wave fill={this.color1}
                        paused={false}
                        options={{
                            height: 50,
                            amplitude: a1,
                            speed: 0.15,
                            points: 2
                        }}
                        style={{ position: 'absolute', left: '0px', top: '50%', zIndex: '-4', height: "50%" }}
                    ></Wave>
                }
                {showWave2 &&
                    <Wave fill={this.color2}
                        paused={false}
                        options={{
                            height: 50,
                            amplitude: a2,
                            speed: 0.15,
                            points: 10
                        }}
                        style={{ position: 'absolute', left: '0px', top: '50%', zIndex: '-3', height: "50%" }}
                    ></Wave>

                }



                <Fab variant="contained" color="primary" >
                    <CheckCircleIcon onClick={this.ended.bind(this)} />
                </Fab>


                {this.test &&
                    <Fab variant="contained" color="primary" >
                        <NavigationIcon onClick={this.siminc.bind(this)} />
                    </Fab>}
                {this.test &&
                    <Fab variant="contained" color="primary" >
                        <NavigationIcon onClick={this.simdec.bind(this)} />
                    </Fab>}


            </div>


        );

    };
}

export default Racing;