import Typography from '@mui/material/Typography';

export const instruction_rezilia_data = {

    rezilia_bevezetes: {
        hu: (
            <div>
                {/* <Typography variant="h6" align='justify' color="text.primary">
                    Csigavér
                </Typography> */}

                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Kérlek, tedd fel a fejhallgatót és hallgasd meg a hangfelvételt.
                    <br></br>
                </Typography>

            </div>
        ),
    },

    rezilia_darts_gyakorlas : {
        hu: (
            <div>
                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Most próbáljunk ki együtt egy páros online Darts játékot. Ha vége van a gyakorlásnak, állítsd le a mérést (középen lila pipa).                    <br></br>
                </Typography>

            </div>
        ),

    },

    rezilia_darts_verseny : {
        hu: (
            <div>
                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Most indulhat a verseny! Ha vége van, állítsd le a mérést (középen lila pipa).                
                    </Typography>
            </div>
        ),

    },

    rezilia_csigaver: {
        hu: (
            <div>
                {/* <Typography variant="h6" align='justify' color="text.primary">
                    Csigavér
                </Typography> */}

                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Ebben a gyakorlatban a képernyőn megjelenő hullámok érzelmi állapotunkat mutatják meg. Próbáld meg lecsendesíteni a hullámokat. Ez a folyamat hasonló a biciklizéshez: nem kell túlgondolni, csak próbálgatni. Ha nem sikerül elsőre, az nem baj, de kérlek próbálkozz, amíg a feladat tart.
                    <br></br>
                </Typography>

            </div>
        ),
    },


    rezilia_lelegzos: {
        hu: (
            <div>
                {/* <Typography variant="h6" align='justify' color="text.primary">
                    Csigavér
                </Typography> */}

                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Az érzelmeket a légzés szabályozásán keresztül is lehet befolyásolni. A következő gyakorlat erről szól.
                    Gyakorlat közben ez van, maradjon: Mondd lassan magadban és közben csináld is: Beszívom a levegőt és kifújom a levegőt!                    <br></br>
                </Typography>

            </div>
        ),
    },

    rezilia_kisvirag: {
        hu: (
            <div>
                {/* <Typography variant="h6" align='justify' color="text.primary">
                    Csigavér
                </Typography> */}

                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    A következő próbában két hullámot fogsz látni, mindegyik egy kicsit mást jelez vissza az érzelmi-aktivációs állapotodról. Próbáld meg mindkettőt elsimítani.
                    <br></br>
                </Typography>

            </div>
        ),
    },

    rezilia_fajdalomreflexiok: {
        hu: (
            <div>
                {/* <Typography variant="h6" align='justify' color="text.primary">
                    Csigavér
                </Typography> */}

                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Most pedig beszéljünk a fájdalomról. Néha van és el kell viselni. De hogyan? Most mutatunk egy lehetséges megoldást. Csukd be a szemed és kövesd a hangfelvétel utasításait.
                </Typography>

            </div>
        ),
    },

    // rezilia_lefel: {
    //     hu: (
    //         <div>
    //             <Typography variant="body1" align='justify' color="text.primary">
    //                 <br></br>
    //                 Tapsztalhattad, hogy a fájdalom érzése agyban dől el. Gyakoroljuk ezt egy kicsit!
    //             </Typography>

    //         </div>
    //     ),
    // },

    rezilia_lefel: {
        hu: (
            <div>
                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Próbáld meg érzelmeidet “agyból irányítani”. Gondolj olyan dolgokra, amik befolyásolhatják az érzelmi feszültségi állapotodat.                 
                    </Typography>
            </div>
        ),
    },


    rezilia_facska: {
        hu: (
            <div>
                {/* <Typography variant="h6" align='justify' color="text.primary">
                    Csigavér
                </Typography> */}

                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Most az lesz a cél, hogy ha elsimítottad a hullámokat, tartsd is őket elsimítva. Ezzel felkészülsz arra, hogy érzéseidre akár több percig is tudj hatni.
                    <br></br>
                </Typography>

            </div>
        ),
    },



    rezilia_erzesek3d: {
        hu: (
            <div>
                {/* <Typography variant="h6" align='justify' color="text.primary">
                    Csigavér
                </Typography> */}

                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Most álljunk meg, és kérlek jelezz a vizsgálatvezetőnek!
                    <br></br>
                </Typography>

            </div>
        ),
    },

    rezilia_darts_visszavago : {
        hu: (
            <div>
                <Typography variant="body1" align='justify' color="text.primary">
                    <br></br>
                    Most indulhat visszavágó! Ha vége van, állítsd le a mérést (középen lila pipa).
                </Typography>
            </div>
        ),

    },



}