
import { calcLevelCTW } from './calc_level_ctw';
// import { calcLevelCTWkids } from './calc_level_ctw_kids';
// import { calcLevelZen } from './calc_level_zen';
// import { calcLevelAudioSessions } from './calc_level_audio';
// import { calcLevelChill } from './calc_level_chill';
// import { calcLevelRezilio } from './calc_level_rezilio';
// import { calcLevelRezilia } from './calc_level_rezilia';


// in JS objects insertion order is preserved! so dicts act like lists!!!!

export const ctw_data = {
    ctw: {        
        label: "Calm The Waves",
        levelType: "CTW",
        levelFn: calcLevelCTW,

        exercises: {
            ctw_basic: {
                label: "CTW beginner",
                level: 1,
                eda: true,
                
                type: "WaveBasic",
                params: {
                    duration: 300000,
                    backgroundImage: "/media/00szinkombinacioJO_5.jpg",
        
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },
        
            },
        
            ctw_0: {
                label: "CTW intermediate",
                level: 2,
                eda: true,
                
                type: "Waves2Phase",
                params: {
                    tree: true,
                    backgroundImage: "/media/desert4.jpg",
                    duration: 900000,
                    point_mult: 3,
                    l_incr: 2,
                    l_decr: 1,
                    r_incr: 2,
                    r_decr: 1
                },
        
            },
        
            ctw_sounds: {
                label: "CTW sounds (intermediate)",
                level: 3,
                eda: true,
                adjustVolume: true,
               
                type: "Waves2Phase",
                params: {
                    tree: true,
                    backgroundImage: "/media/cello.jpg",
                    duration: 900000,
                    point_mult: 6,
                    l_incr: 2,
                    l_decr: 1,
                    r_incr: 2,
                    r_decr: 1,
        
                    //backgroundSound: "./media/calm.mp3",
                    disturb: [
                        {
                            level: 60,
                            hist: 10,
                            sound: "./media/click.mp3"
                        },
                        {
                            level: 40,
                            hist: 10,
                            sound: "./media/baby1.mp3"
                        },
                        {
                            level: 20,
                            hist: 10,
                            sound: "./media/alert.mp3"
        
                        },
                        {
                            level: 5,
                            hist: 10,
                            sound: "./media/baby2_short.mp3",
        
                        }                        
                    ],
                },
        
            },
        
            ctw_1: {
                label: "CTW advanced",
                level: 4,
                eda: true,
                
                type: "Waves2Phase",
                params: {
                    tree: true,
                    backgroundImage: "/media/waterfall.jpg",
                    point_mult: 12,
                    l_incr: 2,
                    l_decr: 0.5,
                    r_incr: 2,
                    r_decr: 0.5
                },
        
            },
                
            ctw_calm: {
                label: "CTW music",
                level: 5,
                eda: true,
                adjustVolume: true,
                
                type: "Waves2Phase",
                params: {
                    tree: true,
                    backgroundImage: "/media/audience2.jpg",
                    point_mult: 24,
                    l_incr: 2,
                    l_decr: 0.5,
                    r_incr: 2,
                    r_decr: 0.5,
        
                    backgroundSound: "./media/calm.mp3",
                },
        
            },
        
            ctw_shepard: {
                label: "CTW Shepard",
                level: 5,
                eda: true,
                adjustVolume: true,
                            
                type: "Waves2Phase",
                params: {
                    tree: true,
                    backgroundImage: "/media/fractal3.jpg",
                    point_mult: 24,
                    l_incr: 2,
                    l_decr: 0.5,
                    r_incr: 2,
                    r_decr: 0.5,
        
                    backgroundSound: "./media/shepard.mp3",
                },
        
            },
        
            ctw_tetris: {
                label: "Tetris (basic)",
                level: 1,
                eda: true,
                
                type: "Tetris",
                params: {
                    showWaves: true, 
        
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1,
        
                },
        
            },
        }
    }
}

        //     ctw_tetris_nowave: {
        //         label: "Tetris (basic, nowaves)",
        //         level: 1,
        //         eda: true,
        
        //         type: "Tetris",
        //         params: {
        //             showWaves: false, 
        //             point_mult: 1,
        //             l_incr: 1,
        //             l_decr: 1,
        //             r_incr: 1,
        //             r_decr: 1,        
        //         },        
        //     },
        
        //     raweda: {
        //         label: "Raw EDA",
        //         level: 1,
        //         eda: true,
        
        //         type: "RawEda",
        //         params: {
        //             showWaves: false, 
        //             point_mult: 1,
        //             l_incr: 1,
        //             l_decr: 1,
        //             r_incr: 1,
        //             r_decr: 1,
        
        //         },
        
        //     },
                
        //     ctw_reinforce: {
        //         label: "CTW Reinforce",
        //         level: 1,
        //         eda: true,
                
        //         type: "WaveReinforce",
        //         params: {
        //             backgroundImage: "/media/moon.jpg",
        //             visual: {
        //                 incr: ["/media/incr/fire1.jpg", "/media/incr/fire2.jpg", "/media/incr/fire3.jpg", "/media/incr/fire4.jpg",  "/media/incr/fire5.jpg",  "/media/incr/fire6.jpg",  "/media/incr/annoy.jpg",  "/media/incr/birds.jpg",  "/media/incr/cat.jpg",  "/media/incr/dragon.jpg",  "/media/incr/lightning.jpg",  "/media/incr/steel.jpg",  "/media/incr/wave.jpg"],
        //                 decr: ["/media/decr/bird.jpg", "/media/decr/deer.jpg", "/media/decr/dolphin.jpg","/media/decr/giraffe.jpg", "/media/decr/mountains.jpg", "/media/decr/owl.jpg", "/media/decr/panorama.jpg", "/media/decr/sunset.jpg", "/media/decr/tree1.jpg", "/media/decr/trees.jpg", "/media/decr/whitehorse.jpg",],
        //             },
        //             point_mult: 24,
        //             l_incr: 2,
        //             l_decr: 1,
        //             r_incr: 2,
        //             r_decr: 1,
        
        //         },
        
        //     },
        
        //     ctw_updown: {
        //         label: "CTW Up-Down",
        //         level: 1,
        //         eda: true,
                            
        //         type: "WaveUpdown",
        //         params: {
        //             backgroundImage: "/media/moon.jpg",
        //             visual: {
        //                 incr: ["/media/incr/fire1.jpg", "/media/incr/fire2.jpg", "/media/incr/fire3.jpg", "/media/incr/fire4.jpg",  "/media/incr/fire5.jpg",  "/media/incr/fire6.jpg", ],
        //                 decr: ["/media/decr/water1.jpg", "/media/decr/water2.jpg", "/media/decr/water3.jpg","/media/decr/water4.jpg", "/media/decr/water5.jpg", "/media/decr/water6.jpg", "/media/decr/water7.jpg", "/media/decr/water8.jpg",],
        //             },
        //             duration: 300000,
        //             point_mult: 24,
        //             l_incr: 2,
        //             l_decr: 1,
        //             r_incr: 2,
        //             r_decr: 1,        
        //         },        
        //     },
        
        //     ctw_subliminal: {
        //         label: "CTW Subliminal",
        //         level: 1,
        //         eda: true,
                                
        //         type: "WavesSubliminal",
        //         params: {
        //             // backgroundImage: "/media/cobweb1.jpg",
        //             images: ["/media/spiders/pok1.jpg", "/media/spidersalpha/pok3.jpg", "/media/spidersalpha/pok4.jpg", "/media/spidersalpha/pok7.jpg", "/media/spidersalpha/pok10.jpg",  "/media/spidersalpha/pok11.jpg",  "/media/spidersalpha/pok12.jpg",  "/media/spidersalpha/pok13.jpg",  "/media/spidersalpha/pok17.jpg",  "/media/spidersalpha/pok18.jpg",],
        //             bgImages: ["/media/masks/maszk2.jpg", "/media/masks/maszk3.jpg", "/media/masks/maszk4.jpg", "/media/masks/maszk5.jpg", "/media/masks/maszk6.jpg", "/media/masks/maszk7.jpg", "/media/masks/maszk8.jpg",],
        //             point_mult: 24,
        //             l_incr: 1,
        //             l_decr: 1,
        //             r_incr: 1,
        //             r_decr: 1,
        
        //         },
        
        //     },
        
        //     ctw_control: {
        //         label: "CTW control (beginner)",
        //         level: 2,
        //         eda: true,
                                
        //         type: "Waves2Phase",
        //         params: {
        //             flower: true,
        //             backgroundImage: "/media/tree.jpg",
        //             duration: 900000,
        //             point_mult: 3,
        //             l_incr: 1,
        //             l_decr: 1,
        //             r_incr: 1,
        //             r_decr: 1
        //         },
        
        //     },
        
        //     keywords: {
        //         label: "Keywords",
        //         level: 1,
                               
        //         type: "Keywords",
        //         params: {
        //             minKeywords: 5,
        //             maxKeywords: 7
        //         },
        
        //     },
        
        //     ctw_keywords: {
        //         label: "CTW Keywords (beginner)",
        //         levelType: "CTW",
        //         level: 1,
        //         eda: true,
        
                
        //         type: "WaveKeywords",
        //         params: {
        //             backgroundImage: "/media/moon.jpg",
        //             showWave: true,
        //             duration: 3000000,
        //             point_mult: 1,
        //             l_incr: 1,
        //             l_decr: 1,
        //             r_incr: 1,
        //             r_decr: 1,
        
        //         },
        
        
        //     },
        
        //     ctw_keywords_nowave: {
        //         label: "CTW Keywords (nowave, beginner)",
        //         levelType: "CTW",
        //         level: 1,
        //         eda: true,
        
               
        //         type: "WaveKeywords",
        //         params: {
        //             backgroundImage: "/media/moon.jpg",
        //             showWave: false,
        //             duration: 3000000,
        //             point_mult: 1,
        //             l_incr: 1,
        //             l_decr: 1,
        //             r_incr: 1,
        //             r_decr: 1,
        
        //         },
        
        
        //     },
        
        //     periodic_audio: {
        //         label: "Periodic audio",
        //         levelType: "CTW",
        //         level: 1,
        //         eda: true,
        //         adjustVolume: true,
        //         adjustDifficulty: true,
        
                
        //         type: "Waves2Phase",
        //         params: {
        //             tree: true,
        //             backgroundImage: "/media/moon.jpg",
        //             duration: 900000,
        //             point_mult: 1,
        //             l_incr: 1,
        //             l_decr: 1,
        //             r_incr: 1,
        //             r_decr: 1,
        
        //             //backgroundSound: "./media/calm.mp3",
        //             periodic: 
        //                 {
        //                     sound: "./media/alert.mp3",
        //                     dt: 10000
        //                 }
        //         },
        
        //     },
        
        //     periodic_cartoon_image: {
        //         label: "Periodic cartoon image",
        //         levelType: "CTW",
        //         level: 1,
        //         eda: true,
        //         showTree: true,
        //         adjustDifficulty: true,
        
        
        
        //         type: "ImageHabituation",
        //         params: {
        //             backgroundImage: "/media/moon.jpg",
        //             images: {
        //                 bee: "/media/cartoons/bee1.png",
        //                 grasshopper: "/media/cartoons/grasshopper1.png",
        //                 rat: "/media/cartoons/rat1.png",
        //                 snake: "/media/cartoons/snake1.png",
        //                 spider: "/media/cartoons/spider1.png",
        //             },
        //             offTime: 6000,
        //             onTime: 1000,
        //             point_mult: 1,
        //             l_incr: 1,
        //             l_decr: 1,
        //             r_incr: 1,
        //             r_decr: 1,
        
        //         },
        
        //     },
        
        //     periodic_scary_image: {
        //         label: "Periodic scary image",
        //         levelType: "CTW",
        //         level: 1,
        //         eda: true,
        //         showTree: true,
        //         adjustDifficulty: true,
        
        
        //         type: "ImageHabituation",
        //         params: {
        //             backgroundImage: "/media/moon.jpg",
        //             images: {
        //                 spider1: "/media/phobia_medium/medium_spider1.png",
        //                 spider2: "/media/phobia_medium/medium_spider2.png",
        //                 snake1: "/media/phobia_medium/medium_snake1.png",
        //                 snake2: "/media/phobia_medium/medium_snake2.png",
        //                 bug: "/media/phobia_medium/medium_bug1.png",
        //                 bee: "/media/phobia_medium/medium_bee1.png",
        //             },
        //             funny: "/media/spiders/spider_hat.png",
        //             offTime: 6000,
        //             onTime: 1000,
        //             //bgImages: ["/media/masks/maszk2.jpg", "/media/masks/maszk3.jpg", "/media/masks/maszk4.jpg", "/media/masks/maszk5.jpg", "/media/masks/maszk6.jpg", "/media/masks/maszk7.jpg", "/media/masks/maszk8.jpg",],
        //             point_mult: 1,
        //             l_incr: 1,
        //             l_decr: 1,
        //             r_incr: 1,
        //             r_decr: 1,
        
        //         },
        
        //     },
        
        //     shrink: {
        //         label: "Shrink scary image",
        //         levelType: "CTW",
        //         level: 1,
        //         eda: true,
        //         showTree: true,
        //         adjustDifficulty: true,
        
        
        //         type: "ShrinkImage",
        //         params: {
        //             backgroundImage: "/media/phobia_hard/background.png",
        //             images: {
        //                 spider: ["/media/phobia_hard/hard_spider1.png","/media/phobia_hard/hard_spider1_reward.png"],
        //                 bee: ["/media/phobia_hard/hard_bee1.png","/media/phobia_hard/hard_bee1_reward.png"],
        //                 snake: ["/media/phobia_hard/hard_snake1.png","/media/phobia_hard/hard_snake1_reward.png"]
        
        //             },
            
        //             //bgImages: ["/media/masks/maszk2.jpg", "/media/masks/maszk3.jpg", "/media/masks/maszk4.jpg", "/media/masks/maszk5.jpg", "/media/masks/maszk6.jpg", "/media/masks/maszk7.jpg", "/media/masks/maszk8.jpg",],
        //             point_mult: 1,
        //             l_incr: 1,
        //             l_decr: 1,
        //             r_incr: 1,
        //             r_decr: 1,
        
        //         },
        
        //     },
        
        //     phobia_keywords: {
        //         label: "Phobia keywords",
        //         levelType: "CTW",
        //         level: 1,
        
        
        //         type: "Keywords",
        //         params: {
        //             minKeywords: 3,
        //         },
        
        //     },
        
        //     phobia_ctw_keywords: {
        //         label: "Phobia CTW Keywords",
        //         levelType: "CTW",
        //         level: 1,
        //         eda: true,
        //         adjustDifficulty: true,
        
            
        //         type: "WaveKeywords",
        //         params: {
        //             backgroundImage: "/media/moon.jpg",
        //             showWave: true,
        //             duration: 3000000,
        //             point_mult: 1,
        //             l_incr: 1,
        //             l_decr: 1,
        //             r_incr: 1,
        //             r_decr: 1,
        
        //         },
        
        
        //     },
        
        
        //     proba1: {
        //         label: "Próba 1",
        //         levelType: "CTW",
        //         level: 1,
        //         eda: true,
        //         adjustVolume: true,
        //         adjustDifficulty: true,
        
                
        //         type: "Waves2Phase",
        //         params: {
        //             tree: true,
        //             backgroundImage: "/media/moon.jpg",
        //             duration: 120000,
        //             point_mult: 1,
        //             l_incr: 1,
        //             l_decr: 1,
        //             r_incr: 1,
        //             r_decr: 1,
        
        //             //backgroundSound: "./media/calm.mp3",
        //             periodic: 
        //                 {
        //                     sound: "./media/alert.mp3",
        //                     dt: 10000
        //                 }
        //         },
        
        //     },
        
        //     proba2: {
        //         label: "Próba 2",
        //         levelType: "CTW",
        //         level: 1,
        //         eda: true,
        
        
        //         type: "WaveBasic",
        //         params: {
        //             duration: 180000,
        //             backgroundImage: "/media/00szinkombinacioJO_5.jpg",
        
        //             point_mult: 1,
        //             l_incr: 1,
        //             l_decr: 1,
        //             r_incr: 1,
        //             r_decr: 1
        //         },
        
        //     },
        
        //     proba3: {
        //         label: "Próba 3",
        //         levelType: "CTW",
        //         level: 1,
        //         eda: true,
        
        //         type: "WaveUpdown",
        //         params: {
        //             backgroundImage: "/media/moon.jpg",
        //             visual: {
        //                 incr: ["/media/incr/fire1.jpg", "/media/incr/fire2.jpg", "/media/incr/fire3.jpg", "/media/incr/fire4.jpg", "/media/incr/fire5.jpg", "/media/incr/fire6.jpg",],
        //                 decr: ["/media/decr/water1.jpg", "/media/decr/water2.jpg", "/media/decr/water3.jpg", "/media/decr/water4.jpg", "/media/decr/water5.jpg", "/media/decr/water6.jpg", "/media/decr/water7.jpg", "/media/decr/water8.jpg",],
        //             },
        //             duration: 180000,
        //             point_mult: 1,
        //             l_incr: 2,
        //             l_decr: 1,
        //             r_incr: 2,
        //             r_decr: 1,
        
        //         },
        
        //     },
        
        //     proba4: {
        //         label: "Próba 4",
        //         levelType: "CTW",
        //         level: 1,
        //         eda: true,
        
        //         type: "Waves2Phase",
        //         params: {
        //             tree: true,
        //             backgroundImage: "/media/desert4.jpg",
        //             duration: 180000,
        //             point_mult: 1,
        //             l_incr: 2,
        //             l_decr: 1,
        //             r_incr: 2,
        //             r_decr: 1
        //         },
        
        //     },
        
        //     proba5: {
        //         label: "Próba 5",
        //         levelType: "CTW",
        //         level: 1,
        //         eda: true,
        //         adjustVolume: true,
        //         adjustDifficulty: true,
        
                
        //         type: "Waves2Phase",
        //         params: {
        //             tree: true,
        //             backgroundImage: "/media/moon.jpg",
        //             duration: 120000,
        //             point_mult: 1,
        //             l_incr: 1,
        //             l_decr: 1,
        //             r_incr: 1,
        //             r_decr: 1,
        
        //             //backgroundSound: "./media/calm.mp3",
        //             periodic: 
        //                 {
        //                     sound: "./media/alert.mp3",
        //                     dt: 10000
        //                 }
        //         },
        
        //     },
        

        // }
