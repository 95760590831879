
import { calcLevelCTW } from './calc_level_ctw';


// in JS objects insertion order is preserved! so dicts act like lists!!!!

export const imagine_data = {
    imagine: {        
        label: "Imagine",
        levelType: "CTW",
        levelFn: calcLevelCTW,

        exercises: {
            story: {
                label: "Story",
                level: 1,
                eda: true,
        
                type: "Story",
                params: {
                    showWaves: false, 
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1,
        
                },
        
            },

        
            
        }
    }
}

