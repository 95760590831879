import React, { Component } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
// import CircularProgress from '@mui/material/CircularProgress';
// import Fab from '@mui/material/Fab';

import campaign_data from '../scripts/campaign_data.js';
import { MyContext } from '../MyContext';

import ScoreBox from '../ScoreBox';
// import WeeklyScores from '../WeeklyScores';
// import Campaigns from './Campaigns'

import { Link } from 'react-router-dom';
// import { withSnackbar } from 'notistack';


class Dashboard extends Component {
    static contextType = MyContext;

    constructor(props) {
        super(props)

        this.campdata = campaign_data();

        this.nextExerciseClicked = this.nextExerciseClicked.bind(this);


    }


    nextExerciseClicked(next) {
        console.log("NEXT exercise clicked", next);

        let camp = next[0];
        let ex = next[1];

        this.context.setExercise(camp, ex);
    }

    wave_svg(transform) {
        return (
            <div>
                {/* Add a wave.svg here */}
                <img
                    style={{
                        transform: transform,  // Flip the image vertically
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: '25%',
                        opacity: 0.25, /* Make the image 50% transparent */
                        // objectFit: 'cover',
                        zIndex: 1,
                    }}
                    src="/media/wave.svg"
                />

                <img
                    style={{
                        transform: transform,  // Flip the image vertically
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: '20%',
                        opacity: 0.25, /* Make the image 50% transparent */
                        // objectFit: 'cover',
                        zIndex: 1,
                    }}
                    src="/media/wave.svg"
                />

                <img
                    style={{
                        transform: transform,  // Flip the image vertically
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: '15%',
                        opacity: 0.25, /* Make the image 50% transparent */
                        // objectFit: 'cover',
                        zIndex: 1,
                    }}
                    src="/media/wave.svg"
                />


                <img
                    style={{
                        transform: transform,  // Flip the image vertically
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: '10%',
                        opacity: 0.4, /* Make the image 50% transparent */
                        // objectFit: 'cover',
                        zIndex: 1,
                    }}
                    src="/media/wave.svg"
                />
            </div>
        )
    }

    render() {

        // console.log("Dashboard render");

        this.level = JSON.parse(this.props.level);

        let n_camp = 0;
        if (this.props.campaigns) n_camp = this.props.campaigns.length;

        // console.log("CAMPS", this.props.campaigns);

        let campaignCards = [];

        if (this.context.mentorView) {
            for (let camp of this.props.campaigns) {

                if (!camp in this.campdata) {
                    console.log("Camp not found", camp);
                }

                if (!this.campdata[camp].group) continue;

                let title = this.campdata[camp].label;

                campaignCards.push(
                    <Grid key={"grid" + camp} item xs={12} sm={12} md={6} padding={2}>

                        <div style={{
                            // position: 'relative',
                            // minWidth: 500,
                            // minHeight: 300,
                        }}
                        >
                            {/* {this.wave_svg('scaleY(-1)')} */}



                            <Card key={"card" + camp}
                                sx={{
                                    width: '100%',
                                    minHeight: 300,
                                    background: 'linear-gradient(to right, #d6daffab, #24fff4ab)', // Gradient background for Card
                                    padding: '10px', // Add padding to ensure proper spacing
                                    boxSizing: 'border-box', // Include padding in width calculation
                                }}
                                elevation={5}
                            >

                                <CardContent key={"level" + camp}>
                                    <Typography variant="h4" color="text.secondary" gutterBottom>
                                        {title}
                                    </Typography>
                                </CardContent>


                                <CardContent key={"next" + camp}>
                                    <Typography variant="body2">
                                        {/* Next exercise: {next_exercise} */}
                                        {/* Button for next exercie */}
                                        <Button size="medium" variant="contained" onClick={() => { this.context.setPage("campaigns") }} >Select from library</Button>

                                    </Typography>
                                </CardContent>
                            </Card>
                        </div>
                    </Grid>
                );
            }

        } else {

            for (let camp of this.props.campaigns) {

                if (!camp in this.campdata) {
                    console.log("Camp not found", camp);
                }

                if (this.campdata[camp].group) continue;

                let wstat;
                let allstat;
                if (camp in this.props.weeklyStats) {
                    wstat = this.props.weeklyStats[camp].wstat;
                    allstat = this.props.weeklyStats[camp].allstat;
                } else {
                    continue;
                }


                let title = this.campdata[camp].label;
                let level = this.level[camp].level;
                let levelInfo = this.level[camp].levelInfo;
                let next_exercise = this.level[camp].next_exercise;

                console.log("NEXT", camp, next_exercise);

                campaignCards.push(
                    <Grid key={"grid" + camp} item xs={12} sm={12} md={6} padding={2}>

                        <div style={{
                            // position: 'relative',
                            // minWidth: 500,
                            // minHeight: 300,
                        }}
                        >
                            {/* {this.wave_svg('scaleY(-1)')} */}



                            <Card key={"card" + camp}
                                sx={{
                                    width: '100%',
                                    minHeight: 300,
                                    background: 'linear-gradient(to right, #d6daffab, #24fff4ab)', // Gradient background for Card
                                    padding: '10px', // Add padding to ensure proper spacing
                                    boxSizing: 'border-box', // Include padding in width calculation
                                }}
                                elevation={5}
                            >

                                <CardContent key={"level" + camp}>
                                    <Typography variant="h4" color="text.secondary" gutterBottom>
                                        {title}
                                    </Typography>

                                    <Typography color="text.secondary">
                                        Course progress {level}
                                    </Typography>
                                    <Typography variant="body2">
                                        {levelInfo}
                                    </Typography>
                                </CardContent>

                                {/* <CardContent key={"stats"+camp}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
                                    <Stack justifyContent="center" spacing={2} direction="column">
                                        <Typography variant="body1" color="text.secondary">
                                            This week
                                        </Typography>
                                        <ScoreBox data={wstat}></ScoreBox>
                                    </Stack>
                                    <Stack justifyContent="center" spacing={2} direction="column">
                                        <Typography variant="body1" color="text.secondary">
                                            All time
                                        </Typography>
                                        <ScoreBox data={allstat}></ScoreBox>
                                    </Stack>
                                </Box>
                            </CardContent> */}

                                <CardContent key={"stats" + camp}>
                                    {/* <Typography variant="body1" color="text.secondary">
                                            All time
                                        </Typography> */}
                                    <ScoreBox data={allstat}></ScoreBox>
                                </CardContent>


                                {next_exercise &&
                                    <CardContent key={"next" + camp}>
                                        <Typography variant="body2">
                                            {/* Next exercise: {next_exercise} */}
                                            {/* Button for next exercie */}
                                            <Button size="medium" variant="contained" onClick={() => this.nextExerciseClicked(next_exercise)} >GO!</Button>

                                        </Typography>
                                    </CardContent>
                                }

                                {!next_exercise &&
                                    <CardContent key={"next" + camp}>
                                        <Typography variant="body2">
                                            {/* Next exercise: {next_exercise} */}
                                            {/* Button for next exercie */}
                                            <Button size="medium" variant="contained" onClick={() => { this.context.setPage("campaigns") }} >Select from library</Button>

                                        </Typography>
                                    </CardContent>
                                }


                                {/* <CardActions key={"button"+camp}>
                                <Button size="medium" component={Link} to="/sessions" >Inspect individual sessions...</Button>
                            </CardActions> */}
                            </Card>
                        </div>
                    </Grid>
                );

            }
        }

        let newfeaturestext = false;
        if (this.props.newfeatures) {
            if (this.props.newfeatures > 0) {
                newfeaturestext = "Great! You acquired new objects for your scenery. Check them out!";

            }
        }

        let showAwards = false;

        return (
            <div
                style={{
                    background: 'linear-gradient(to bottom, #031b58, #66f5f6)', // Set your gradient or any background color here
                    minHeight: 'calc(100vh - 64px)', // Ensure it takes up the full height of the viewport
                    padding: 0, // Optional: reset padding
                    margin: 0, // Optional: reset margin
                }}
            >
                <Box margin={0} sx={{ maxWidth: '100%' }}>
                    <Grid container spacing={0} >
                        <Grid key="1" item xs={12} md={12} >


                            <div style={{ position: 'relative', minHeight: 150 }}>
                                {/* Video background */}
                                <video
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        zIndex: 0,
                                    }}
                                    className="videoTag"
                                    autoPlay
                                    loop
                                    muted
                                >
                                    <source src="/media/dustwaves.mp4" type="video/mp4" />
                                </video>

                                {/* {this.wave_svg('scaleY(1)')} */}


                                {/* Card content on top */}
                                <Card sx={{ minWidth: 275, minHeight: 500 }} elevation={0} style={{ backgroundColor: 'rgba(255, 255, 255, 0)', zIndex: 1, position: 'relative' }}>
                                    <CardContent>
                                        <Typography variant="h3" color="white" gutterBottom>
                                            Hello {this.props.user}!
                                        </Typography>

                                        {this.context.mentorView ? (
                                            <Typography variant="heading1" color="white" gutterBottom>
                                            You are in mentor view
                                            <br></br>
                                            <br></br>
                                            <Button size="medium" variant="contained" onClick={() => { this.context.setPage("inspectgroupsessions")}} >Group Sessions History</Button>
                                            &nbsp; &nbsp;

                                            <Button size="medium" variant="contained" onClick={() => { this.context.setPage("inspectpupil")}} >Mentee History</Button>
                                            &nbsp;                                            &nbsp;

                                            {/* <Button size="medium" variant="contained" onClick={() => { this.context.setPage("campaigns") }} >Group exercises</Button> */}

                                        </Typography>
                                        ) : (

                                        <Typography variant="body1" color="white" gutterBottom>
                                            Your adaptive difficulty score is {Math.floor(this.context.difficulty)}
                                            <br></br>
                                            <br></br>
                                            <Button size="medium" variant="contained" onClick={() => { this.context.setPage("sessions")}} >History</Button>
                                            &nbsp;                                            &nbsp;

                                            <Button size="medium" variant="contained" onClick={() => { this.context.setPage("campaigns") }} >Library</Button>

                                        </Typography>
                                        )}

                                        {newfeaturestext && showAwards && (
                                            <div>
                                                <Typography variant="body1" color="error" gutterBottom>
                                                    {newfeaturestext}
                                                </Typography>
                                            </div>
                                        )}
                                    </CardContent>

                                    {(showAwards) &&
                                        <CardContent>
                                            <Button size="medium" variant="contained" component={Link} to="/scenery" >Scenery</Button>
                                            &nbsp;
                                            <Button size="medium" variant="contained" component={Link} to="/sessions" >History</Button>
                                            &nbsp;
                                            <Button size="medium" variant="contained" onClick={() => { this.context.setPage("campaigns") }} >Library</Button>
                                        </CardContent>
                                    }
                                </Card>
                            </div>

                        </Grid>

                        {campaignCards}

                        {(showAwards) &&

                            <Grid key="awards" item xs={12}>

                                <Card sx={{ minWidth: 275 }} elevation={5}>
                                    <CardContent>
                                        <Typography variant="h5" color="text.secondary" gutterBottom>
                                            Challenges & awards
                                        </Typography>
                                    </CardContent>

                                    {this.props.mySceneryImage &&
                                        <div>

                                            <Typography color="text.secondary">
                                                Your scenery:
                                            </Typography>
                                            <Typography variant="body2">

                                            </Typography>

                                            <img style={{ width: "50%" }} src={this.props.mySceneryImage} />

                                            <CardActions>
                                                <Button size="medium" component={Link} to="/scenery" >Go to my scenery...</Button>
                                            </CardActions>
                                        </div>
                                    }

                                </Card>

                            </Grid>
                        }

                    </Grid >
                </Box >


                {/* Place a mailto link to the bottom */}
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <Typography variant="body2" color="text.secondary">
                    <a href="https://obimon.com">obimon.com</a>
                    <br></br><br></br>
                    <a href="mailto:info@obimon.com">mail: info@obimon.com</a>
                </Typography>


            </div>

        )
    }
}

export default Dashboard;