import React, { Component } from 'react';

import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, CategoryScale, Colors, Legend } from 'chart.js';

import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale, Colors, Legend);




export default class GroupResultChart extends Component { 

  constructor(props) {
    super(props);

    ChartJS.register(annotationPlugin);

    this.state = {
      drawData: false,
      drawOptions: false
    }

    this.annotations = [];

    this.colors = ['green', 'black', 'red', 'gray', 'blue', 'green', 'black', 'red', 'gray', 'blue', 'green', 'black', 'red', 'gray', 'blue'];
    this.pointStyle = ['circle', 'rect', 'triangle', 'circle', 'rect', 'triangle', 'circle', 'rect', 'triangle', 'circle', 'rect', 'triangle', 'circle', 'rect', 'triangle']

    //console.log("Wave const");

  }

  componentDidUpdate(prevProps) {
    if (this.props === prevProps) return;

    this.processRecords();

  }

  componentDidMount() {
    this.processRecords();
  }


  render() {

    if (!this.state.drawData) {
      return (
        <div>
          <p> No data</p>

        </div>
      );
    }

    return (
      <Line
        ref="chart"
        data={this.state.drawData}
        options={this.state.drawOptions}
      />

    );

  }

  setEveryKth(array, k, s) {
    for (let i = s; i < array.length; i += k) {
      array[i] = 8;
    }
  }

  processRecords() {

    let d = {
      labels: [],
      datasets: []
    }

    let t0=null;
    for (let i in this.props.group.sessions) {
      let ses = this.props.group.sessions[i];

      if(!t0) t0 = ses.data.eda[0][0];
      if(ses.ts < t0) t0 = ses.data.eda[0][0];
    }


    for (let i in this.props.group.sessions) {
      let ses = this.props.group.sessions[i];
      console.log("processRecords", ses);
      let c = this.processRecord(ses, t0);

      console.log("data", c);

      if(c.length === 0) continue;


      let radius = new Array(c.length).fill(0);
      this.setEveryKth(radius, 20, Math.floor(Math.random() * 20));

      let col = this.colors[i % this.colors.length];
      let pstyle = this.pointStyle[i % this.pointStyle.length];

      let dash = [];
      if (i > this.colors.length) {
        dash = [2, 2];
      }

      d.labels.push(ses.device);

      d.datasets.push({
        label: ses.device,
        fill: false,
        borderColor: col,
        backgroundColor: col,
        hidden: true,

        borderWidth: 4,

        // borderCapStyle: 'butt',
        pointStyle: pstyle,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 2,
        pointRadius: radius,
        pointHitRadius: 10,
        borderDash: dash,

        yAxisID: 'EDA',
        data: c
      })
    }

    let options = {
      maintainAspectRatio: false,
      responsive: true,
      animation: false
    };

    options.hover = {
      mode: 'x'
    }

    options.scales = {

      'EDA': {
        type: 'linear',
        ticks: {
          // min: mineda,
          beginAtZero: false,
          //fontColor: 'white'
        },
      },
      x: {
        type: 'linear',
        // unit: 'second'
        // ticks: {
        //   //fontColor: 'white',

        // }
      },
    }

    options.plugins = {
      // annotation: {
      //   annotations: this.annotations
      // },
      legend: {
        display: true,
        position: 'left',
        align: 'center',
        labels: {
          usePointStyle: true,
        }
      }
    }

    console.log("drawData:", d);
    console.log("drawOptions:", options);

    if(d.datasets.length === 0) return;
    this.setState(
      {
        drawData: d,
        drawOptions: options
      });
  }

  processRecord(ses, t0) {

    let c = [];

    // let t0 = 0;

    

    if (!ses.data) return [];
    if (!ses.data.eda) return [];

    console.log(ses.data.eda);
    console.log(ses.data.eda.length);

    if (ses.data.eda.length === 0) return [];

    if (ses.data.eda.length < 40) return [];

    let firstEda = ses.data.eda[0][1];

    let i = 0;
    for (let [ts, gsr] of ses.data.eda) {

      let x = (ts - t0) / 1000.0;
      // corrected ts
      // x = (i * 250) / 1000;

      gsr = gsr / firstEda * 100;

      c.push({ x: x, y: gsr })

      i += 1;
    }

    return c;


  }

}