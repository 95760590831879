

import { calcLevelSelfAwareness } from './calc_level_self_awareness';
import Typography from '@mui/material/Typography';

export const self_awareness_data = {
    self_awareness: {
        label: "Self awareness",
        levelType: "SELFAWARENESS",
        levelFn: calcLevelSelfAwareness,
        exercises: {

            s_intro: {
                section: "Introduction",
            },

            welcome: {
                label: "Welcome to the module",
                level: 1,
                eda: false,
                type: "VideoTutorial",
                params: {
                    // url: "media/hls/ven1_welcome/master.m3u8",
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven1_welcome/master.m3u8"
                },
            },

            howtodo: {
                label: "How to do this training",
                level: 2,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven2_how_to_do_this_training/master.m3u8",
                },
            },

            howtoobi: {
                label: "How to use the Obimon device - temp",
                level: 3,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven3_how_to_connect_obimon/master.m3u8",
                },
            },

            s_chill: {
                section: "Chillaxing",
            },


            intro_chillaxing: {
                label: "Introduction to Chillaxing",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v4en_introduction_and_overview/master.m3u8",
                },
            },

            chill: {
                label: "The Chill Excercise",
                level: 5,
                eda: true,
                type: "Waves",
                params: {
                    duration: 180000,
                    // backgroundImage: "/media/00szinkombinacioJO_5.jpg",
                    backgroundImage: "/media/cbtbasic/01fantasy.jpg",
                    point_mult: 1,
                },
                video_instruction: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven6_chill/master.m3u8",
                },
                instruction: {
                    en: (
                        <div>
                            <Typography variant="body1" align='justify' color="text.primary">
                                <br></br>
                                Focus on your waves. Think of this process like riding a bicycle: there's no need to overthink it — just chill and go with the flow. You'll be notified when the exercise is complete, and it will take no more than three minutes.
                                <br></br>
                            </Typography>

                        </div>
                    ),
                }
            },

            did_i_calm: {
                label: "Did I Calm my Waves?",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/XXX/master.m3u8",
                },
            },

            fight_or_flight: {
                label: "Fight or Flight",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/XXX/master.m3u8",
                },
            },

            peek: {
                label: "The Peek Excercise",
                level: 9,
                eda: true,
                type: "Waves",
                params: {
                    duration: 180000,
                    // backgroundImage: "/media/kukucska.png",
                    backgroundImage: "/media/cbtbasic/02cat.jpg",
                    point_mult: 1,
                },
                video_instruction: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven8_peek/master.m3u8",
                },
                instruction: {
                    en: (
                        <div>
                            <Typography variant="body1" align='justify' color="text.primary">
                                <br></br>
                                <b>Calm your waves with your eyes closed. </b>
                                From time-to-time open your eyes for a moment and peek for a few moments and check if you've managed to calm your waves.
                                <br></br>
                            </Typography>

                        </div>
                    ),

                }

            },

            flexibility_and_mindful_action: {
                label: "Flexibility and Mindful Action",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v41en_flexibility_and_mindful_action/master.m3u8",
                },
            },

            observe: {
                label: "Observe your Waves",
                level: 9,
                eda: true,
                type: "Waves",
                params: {
                    duration: 180000,
                    // backgroundImage: "/media/kukucska.png",
                    backgroundImage: "/media/cbtbasic/02cat.jpg",
                    point_mult: 1,
                },
                video_instruction: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/XXX/master.m3u8",
                },
                instruction: {
                    en: (
                        <div>
                            <Typography variant="body1" align='justify' color="text.primary">
                                <br></br>
                                Now, try the chill exercise again, but this time take a moment to really observe your waves. What physical sensations arise as your waves grow calmer and calmer? You may redo this task as many times as you want.
                                <br></br>
                            </Typography>

                        </div>
                    ),

                }

            },

            relax_points: {
                label: "Relax Points",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/XXX/master.m3u8",
                },
            },

            stressors: {
                label: "Stressors",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven7_stressors/master.m3u8",
                },
            },

            what_is_cbt: {
                label: "What is CBT?",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven5_what_is_cbt/master.m3u8",
                },
            },

            breathe: {
                label: "The Breathe Excercise",
                level: 9,
                eda: true,
                type: "Waves",
                params: {
                    duration: 180000,
                    // backgroundImage: "/media/kukucska.png",
                    backgroundImage: "/media/cbtbasic/02cat.jpg",
                    point_mult: 1,
                },
                video_instruction: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven10_breath/master.m3u8",
                },
                instruction: {
                    en: (
                        <div>
                            <Typography variant="body1" align='justify' color="text.primary">
                                <b>Pay attention to your breathing.</b> First, just pay attention to the rhythm, and later,
                                observe how by only paying attention to your breathing, it naturally gets deeper and slower.
                            </Typography>

                        </div>
                    ),

                }

            },

            power_of_focusing: {
                label: "The Power of Focusing",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven11_the_power_of_focusing/master.m3u8",
                },
            },

            focus: {
                label: "The Focus Exercise",
                level: 10,
                eda: true,
                type: "Waves",
                params: {
                    duration: 180000,
                    // backgroundImage: "/media/megkapaszkodos.png",
                    backgroundImage: "/media/cbtbasic/03mountains.jpg",
                    point_mult: 1,
                },
                video_instruction: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven12_focus/master.m3u8",
                },
                instruction: {
                    en: (
                        <div>
                            <br></br>
                            <Typography variant="body1" align='justify' color="text.primary">
                                <b>Choose a spot on the screen</b> and pay attention to that detail only.
                                If you find yourself distracted, drive your focus back to the chosen point.
                            </Typography>
                        </div>
                    ),
                }

            },

            groupwork: {
                label: "Groupwork",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven13_groupwork/master.m3u8",
                },
            },

            why_practice: {
                label: "Why Practice?",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven14_practice_makes_it_it_perfect/master.m3u8",
                },
            },

            why_do_reflections: {
                label: "Why do Reflections?",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven15_why_do_reflections/master.m3u8",
                },
            },

            self_reflection_template: {
                label: "Self-Reflection Template",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/XXX/master.m3u8",
                },
            },

            s_resilience: {
                section: "Resilience",
            },

            intro_resilience: {
                label: "Introduction to Resilience",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven16_introduction_and_overview/master.m3u8",
                },
            },

            creative_hopelessness: {
                label: "Creative Hopelessness",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven17_creative_hopelessness/master.m3u8",
                },
            },

            flower: {
                label: "The Flower Excercise",
                level: 100,
                eda: true,
                type: "Waves",
                params: {
                    flower: true,
                    backgroundImage: "/media/tree.jpg",
                    duration: 300000,
                    point_mult: 1,
                },
                video_instruction: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven18_flower/master.m3u8",
                },
                instruction: {
                    en: (
                        <div>
                            <br></br>
                            <Typography variant="body1" align='justify' color="text.primary">
                            If you can keep your waves calm, you will see a flower grow on the screen. 
                            The flowe will keep growing as long as you can keep your waves calm.
                            You have 5 minutes to grow the flower. 
                            </Typography>
                        </div>
                    ),
                }

            },

            why_keep_diary: {
                label: "Why Keep a Diary?",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven19_why_keep_a_diary/master.m3u8",
                },
            },

            effortful_control: {
                label: "Effortful Control",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven20_effortful_control/master.m3u8",
                },
            },

            control_balance_points: {
                label: "Control and Balance Points",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/XXX/master.m3u8",
                },
            },

            power_of_five_senses: {
                label: "The Power of Five Senses",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven21_the_five_senses/master.m3u8",
                },
            },

            touch: {
                label: "The Touch Excercise",
                level: 100,
                eda: true,
                type: "Waves",
                params: {
                    duration: 180000,
                    // backgroundImage: "/media/ujjbungyurgetos.png",
                    backgroundImage: "/media/cbtbasic/06yoga.jpg",
                    // introLabel: "Érintsd össze a hüvelyk és mutató ujjadat és körözz lassan!",
                    // introTime: 20000,
                    point_mult: 1,
                },
                video_instruction: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven22_touch/master.m3u8",
                },
                instruction: {
                    en: (
                        <div>
                            <br></br>
                            <Typography variant="body1" align='justify' color="text.primary">
                                <b>Touch two fingers</b> and try comforting yourself by moving them gently if you please.
                                Pay attention to the physical and emotional sensations.
                            </Typography>
                        </div>
                    ),
                }
            },

            self_compassion: {
                label: "Self Compassion",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven24_self_compassion/master.m3u8",
                },
            },

            hug: {
                label: "The Hug Exercise",
                level: 100,
                eda: true,
                type: "Waves",
                params: {
                    duration: 180000,
                    // backgroundImage: "/media/dudolos.png",
                    backgroundImage: "/media/cbtbasic/07hands.jpg",
                    // introLabel: "Dúdold magadban a kedvenc dalodat!",
                    // introTime: 20000,
                    point_mult: 1,
                },
                video_instruction: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven23_hug/master.m3u8",
                },
                instruction: {
                    en: (
                        <div>
                            <br></br>
                            <Typography variant="body1" align='justify' color="text.primary">
                                <b>Place your hand gently on your chest or arms </b> try comforting yourself by moving it smoothly.
                                Pay attention to the physical and emotional sensations.
                            </Typography>
                        </div>
                    ),
                }

            },

            memories_game_changers: {
                label: "Memories as Game Changers",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven25_memory/master.m3u8",
                },
            },

            hum: {
                label: "The Hum Excercise",
                level: 4,
                eda: true,
                type: "Waves",
                params: {
                    duration: 180000,
                    // backgroundImage: "/media/hintazos.png",
                    backgroundImage: "/media/cbtbasic/09guitar.jpg",
                    // introLabel: "Keress két pontot a képen és lassan hintázz köztük a szemeddel!",
                    // introTime: 20000,
                    point_mult: 1,
                },
                video_instruction: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven26_hum/master.m3u8",
                },
                instruction: {
                    en: (
                        <div>
                            <br></br>
                            <Typography variant="body1" align='justify' color="text.primary">
                            <b>Think about your favorite song</b> and try to relax while you hum it quietly, or just think about it. 
                            </Typography>
                        </div>
                    ),
                }

            },

            good_stress_bad_stress: {
                label: "Good Stress, Bad Stress",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven27_good_stress_bad_stress/master.m3u8",
                },
            },

            shift: {
                label: "The Shift Excercise",
                level: 100,
                eda: true,
                type: "Waves",
                params: {
                    duration: 180000,
                    backgroundImage: "/media/hintazos.png",
                    // introLabel: "Keress két pontot a képen és lassan hintázz köztük a szemeddel!",
                    // introTime: 20000,
                    point_mult: 1,
                },
                video_instruction: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven28_shift/master.m3u8",
                },
                instruction: {
                    en: (
                        <div>
                            <br></br>
                            <Typography variant="body1" align='justify' color="text.primary">
                                <b>Choose two spots</b> on the image and shift your attention between them continuously.
                            </Typography>
                        </div>
                    ),
                }

            },

            building_emotional_muscle: {
                label: "Building your Emotional Muscle",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven29_building_your_emotional_muscle/master.m3u8",
                },
            },

            how_to_enhance_resilience: {
                label: "How to Enhance Resilience?",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven30_how_to_enhance_resilience/master.m3u8",
                },
            },

            s_stressors: {
                section: "Stressors",
            },

            intro_stressors: {
                label: "Introduction to Stressors",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven31_introduction_and_overview/master.m3u8",
                },
            },

            optimal_arousal: {
                label: "Optimum Arousal",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/XXX/master.m3u8",
                },
            },

            time_as_stressor: {
                label: "Time as a Stressor",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v42en_time_as_a_stressor/master.m3u8",
                },
            },


            time_exercise: {
                label: "The Time Excercise",
                level: 4,
                eda: true,
                type: "Waves",
                params: {
                    duration: 40000,
                    // backgroundImage: "/media/00szinkombinacioJO_5.jpg",
                    backgroundImage: "/media/cbtbasic/10boat.jpg",
                    // introLabel: "You have 40 seconds to calm your waves.",
                    // introTime: 20000,
                    point_mult: 1,
                },
                video_instruction: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v48en_the_tree_excercise/master.m3u8",
                },
                instruction: {
                    en: (
                        <div>
                            <br></br>
                            <Typography variant="body1" align='justify' color="text.primary">
                                <b>XXXXX Now you are ready to find your inner peace. </b>
                                You've learned different relaxation techniques ready to put forward in a challenge.
                                You'll have 40 seconds to calm your waves whatever way you see fit.
                            </Typography>
                        </div>
                    ),
                }

            },

            why_stress_out: {
                label: "Why do we Stress out?",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven32_why_we_stress_out/master.m3u8",
                },
            },

            sounds: {
                label: "The Sounds Excercise",
                level: 4,
                eda: true,
                type: "Waves",
                params: {
                    duration: 193000,
                    // backgroundImage: "/media/lelegzos.png",
                    backgroundImage: "/media/cbtbasic/05bell.jpg",
                    backgroundSound: "/media/cbtbasic/sounds.mp4",
                    continueOnZero: true,
                    hideWaves: false,
                    // introLabel: "Mondd lassan magadban és közben csináld is: Beszívom a levegőt és kifújom a levegőt!",                
                    // introTime: 20000,
                    point_mult: 1,
                },
                video_instruction: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven33_sounds/master.m3u8",
                },
                instruction: {
                    en: (
                        <div>
                            <br></br>
                            <Typography variant="body1" align='justify' color="text.primary">
                                <b>Pay attention to the sounds. </b> Observe your body and your reactions.
                                Try not to engage with what you hear, just listen.
                            </Typography>
                        </div>
                    ),
                }
            },       

            intrusive_thoughts: {
                label: "Intrusive Thoughts",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven34_intrusive_thoughts/master.m3u8",
                },
            },

            thoughts: {
                label: "The Toughts Excercise",
                level: 4,
                eda: true,
                type: "Waves",
                params: {
                    duration: 193000,
                    // backgroundImage: "/media/lelegzos.png",
                    backgroundImage: "/media/cbtbasic/05bell.jpg",
                    backgroundSound: "/media/cbtbasic/sounds.mp4",
                    continueOnZero: true,
                    hideWaves: false,
                    // introLabel: "Mondd lassan magadban és közben csináld is: Beszívom a levegőt és kifújom a levegőt!",                
                    // introTime: 20000,
                    point_mult: 1,
                },
                video_instruction: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven35_thoughts/master.m3u8",
                },
                instruction: {
                    en: (
                        <div>
                            <br></br>
                            <Typography variant="body1" align='justify' color="text.primary">
                                ???
                            </Typography>
                        </div>
                    ),
                }
            },     

            updown: {
                label: "Up-down",
                level: 9,
                eda: true,
                type: "WaveUpdown",
                params: {
                    backgroundImage: "/media/moon.jpg",
                    visual: {
                        incr: ["/media/incr/fire1.jpg", "/media/incr/fire2.jpg", "/media/incr/fire3.jpg", "/media/incr/fire4.jpg", "/media/incr/fire5.jpg", "/media/incr/fire6.jpg",],
                        decr: ["/media/decr/water1.jpg", "/media/decr/water2.jpg", "/media/decr/water3.jpg", "/media/decr/water4.jpg", "/media/decr/water5.jpg", "/media/decr/water6.jpg", "/media/decr/water7.jpg", "/media/decr/water8.jpg",],
                    },
                    duration: 180000,
                    point_mult: 1,
                },
                video_instruction: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v44en_the_up_down_excercise/master.m3u8",
                },
                instruction: {
                    en: (
                        <div>
                            <br></br>
                            <Typography variant="body1" align='justify' color="text.primary">
                                ???
                            </Typography>
                        </div>
                    ),
                }
            },

            stress_tolerance: {
                label: "Stress Tolerance",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven36_stress_tolerance/master.m3u8",
                },
            },

            s_inner_peace: {
                section: "Inner Peace",
            },

            intro_inner_peace: {
                label: "Introduction to Inner Peace",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v46en_introduction_to_inner_peace/master.m3u8",
                },
            },

            life_goals: {
                label: "Life Goals and Life Values",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v47en_life_goals_and_life_values/master.m3u8",
                },
            },

            tree: {
                label: "The Tree Excercise",
                level: 4,
                eda: true,
                type: "Waves",
                params: {
                    tree: true,
                    backgroundImage: "/media/desert4.jpg",
                    duration: 300000,
                    point_mult: 1,
                },
                video_instruction: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v48en_the_tree_excercise/master.m3u8",
                },
                instruction: {
                    en: (
                        <div>
                            <br></br>
                            <Typography variant="body1" align='justify' color="text.primary">
                            If you can keep your waves calm, you will see a tree grow on the screen. 
                            The tree will keep growing as long as you can keep your waves calm.
                            You have 5 minutes to grow the tree. 
                            </Typography>
                        </div>
                    ),
                }

            },

            committed_action: {
                label: "Committed Action",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v49en_commited_action/master.m3u8",
                },
            },

            tetris: {
                label: "Tetris",
                level: 10,
                eda: true,
                type: "Tetris",
                params: {
                    point_mult: 1,
                },
                video_instruction: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v50_the_tetris_excercise/master.m3u8",
                },

            },

            power_of_habituation: {
                label: "The Power of Habituation",
                level: 4,
                eda: false,
                type: "VideoTutorial",
                params: {
                    url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v51en_the_power_of_habituation/master.m3u8",
                },
            },





            // mindfulness1: {
            //     label: "Mindfulness",
            //     level: 1004,
            //     eda: true,
            //     type: "Waves",
            //     params: {
            //         duration: 193000,
            //         backgroundImage: "/media/cbtbasic/11panda.jpg",
            //         backgroundSound: "/media/mindfulness.m4a",
            //         continueOnZero: true,
            //         hideWaves: false,
            //         point_mult: 1,
            //         // l_incr: 1,
            //         // l_decr: 1,
            //         // r_incr: 1,
            //         // r_decr: 1
            //     },

            //     instruction: {
            //         en: (
            //             <div>
            //                 <br></br>
            //                 <Typography variant="body1" align='justify' color="text.primary">
            //                     <b></b>
            //                     Mindfulness meditation is a mental training practice that teaches you to slow
            //                     down racing thoughts, let go of negativity, and calm both your mind and body.
            //                 </Typography>
            //             </div>
            //         ),
            //     }

            // },

            // mindfulness2: {
            //     label: "Body-scan technique",
            //     level: 1004,
            //     eda: true,
            //     type: "Waves",
            //     params: {
            //         duration: 560000,
            //         backgroundImage: "/media/cbtbasic/12beach.jpg",
            //         backgroundSound: "/media/bodyscan.m4a",
            //         continueOnZero: true,
            //         hideWaves: false,
            //         point_mult: 1,
            //         // l_incr: 1,
            //         // l_decr: 1,
            //         // r_incr: 1,
            //         // r_decr: 1
            //     },

            //     instruction: {
            //         en: (
            //             <div>
            //                 <br></br>
            //                 <Typography variant="body1" align='justify' color="text.primary">
            //                     <b></b>
            //                     Body scanning is a simple and effective way to relax your body and mind.
            //                 </Typography>
            //             </div>
            //         ),
            //     }

            // },

            // mindfulness3: {
            //     label: "Acceptance meditation",
            //     level: 4100,
            //     eda: true,
            //     type: "Waves",
            //     params: {
            //         duration: 327000,
            //         backgroundImage: "/media/cbtbasic/13bamboo.jpg",
            //         backgroundSound: "/media/acceptancemeditation.m4a",
            //         continueOnZero: true,
            //         hideWaves: false,
            //         point_mult: 1,
            //         // l_incr: 1,
            //         // l_decr: 1,
            //         // r_incr: 1,
            //         // r_decr: 1
            //     },

            //     instruction: {
            //         en: (
            //             <div>
            //                 <br></br>
            //                 <Typography variant="body1" align='justify' color="text.primary">
            //                     <b></b>
            //                     Acceptance meditation is a practice that helps you to accept the present moment.
            //                 </Typography>
            //             </div>
            //         ),
            //     }

            // },

            // updown: {
            //     label: "Up-down",
            //     level: 9,
            //     eda: true,
            //     type: "WaveUpdown",
            //     params: {
            //         backgroundImage: "/media/moon.jpg",
            //         visual: {
            //             incr: ["/media/incr/fire1.jpg", "/media/incr/fire2.jpg", "/media/incr/fire3.jpg", "/media/incr/fire4.jpg", "/media/incr/fire5.jpg", "/media/incr/fire6.jpg",],
            //             decr: ["/media/decr/water1.jpg", "/media/decr/water2.jpg", "/media/decr/water3.jpg", "/media/decr/water4.jpg", "/media/decr/water5.jpg", "/media/decr/water6.jpg", "/media/decr/water7.jpg", "/media/decr/water8.jpg",],
            //         },
            //         duration: 180000,
            //         point_mult: 1,
            //         l_incr: 2,
            //         l_decr: 1,
            //         r_incr: 2,
            //         r_decr: 1,

            //     },

            // },





        }
    }

}
